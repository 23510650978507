

<section class="scannerContainer"  [hidden]="showScanner"> 
    
</section>

<div bsModal #successQrCodeModalOne="bs-modal" class="modal fade"  [config]="config" [hidden]="showScanner">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body">
                <section class="bonusRedemptionOut">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="closeModalOne()">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <h1>{{ 'BONUS_REDEMPTION.BONUS_REDEMPTION_TITLE1' | translate }}</h1>
                    <div class="row">
                        <div class="col-6">
                            <div class="scanDetails">
                                <img src="../../../assets/images/scan_code.png" alt="scan code" />
                                <p>{{ 'BONUS_REDEMPTION.QR_CODE' | translate }}</p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="scanDetails">
                                <img src="../../../assets/images/mobile_scan.png" alt="mobile code" />
                                <p>{{ 'BONUS_REDEMPTION.ASSITIVE_APPLICATIONS' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <button class="orangeBtn" (click)=" onClickScanner()">{{ 'SCAN_QR_CODE_NOW' | translate
                        }}</button>
                    <div class="questionOut">
                        <h6>{{ 'BONUS_REDEMPTION.QUESTIONS' | translate }}</h6>
                        <p>{{ 'BONUS_REDEMPTION.CUSTOMER_SERVICE1' | translate }}<br /> {{
                            'BONUS_REDEMPTION.CUSTOMER_SERVICE2'
                            | translate }}</p>
                        <a class="telNum" href="tel:19001519">19001519 </a>
                    </div>
                    <p class="code">COR-C-22-21</p>
                </section>
            </div>          
        </div>
    </div>
</div>

<div [hidden]="!showScanner" >
    <div class="video-row" fxFlex="100" id="scandit-barcode-picker">
    </div>
    <button class="cameraBtn zindex-1" (click)="closeScanner()">Đóng</button>
</div>
