<!-- <app-header [hidden]="showScanner"></app-header>
  <h1 class="maintain">"Chúng tôi thành thật xin lỗi cho sự bất tiện này. Hiện tại trò chơi “vòng quay may mắn” đang được bảo trì cho đến 5h sáng mai ngày 06 tháng 07 năm 2022.</h1>

  <h1 class="maintain">Lượt chơi của bạn vẫn được lưu lại trên hệ thống. Hãy trở lại với chúng tôi vào ngày mai để tiếp tục lượt chơi và dành thêm nhiều quà tặng bạn nhé!"</h1>

<app-auth-footer [hidden]="showScanner"></app-auth-footer>
<app-nav-menu></app-nav-menu> -->


<app-header [hidden]="showScanner"></app-header>

<section [hidden]="showScanner">
    <article class="grow-spinwheel container">
        <h3>{{ 'GROW_SPINWHEEL.HEADING1' | translate }}</h3>
        <figure>
            <img class="similacLogo" src="../../../assets/images/nu-i-d-ng-t-i-tr-nh.png">
        </figure>
        <p class="descImg">{{ 'SIMILAC_SPINWHEEL.SUB_HEAD_BEFORE_IMG' | translate }} <img
                src="../../../assets/images/similac-logo-01.png">
            {{ 'SIMILAC_SPINWHEEL.SUB_HEAD_AFTER_IMG' | translate }} <img
                src="../../../assets/images/similac-logo-01.png"></p>
        <div>
            <div class="addGifts">
                <table class="table-borderless tableSpace" cellpadding="0" cellspacing="0">
                    <tr>
                        <td class="spinWheelOut">
                           
                            <img class="stopimg" src="../../../assets/images/stop.png">
                            <canvas (click)="getSegment($event)" id='canvasOne' height="270" width="270">
                                <p text-align="center" style="color: white">Sorry, your browser doesn't support canvas.
                                    Please try another.</p>
                            </canvas>
                            <button class="btn btn-success spin-btn" [disabled]="spinDisable" (click)="calculatePrize(); false;"
                                id="spin_button">Spin</button>
                        </td>
                    </tr>
                </table>
            </div>


            <div style="text-align: center; padding-top: 1rem;">
            </div>

            <div class="mt-3">
                <p class="content_01">{{ 'GROW_SPINWHEEL.SPIN_MSG1_BEFORE' | translate }}
                    <span>{{global_non_redemmed_similac?.length}}</span> {{ 'GROW_SPINWHEEL.SPIN_MSG1_AFTER' | translate }}</p>
                <a (click)="openSimilacTermsandConditions()">{{ 'GROW_SPINWHEEL.SPIN_MSG2' | translate }}</a>
            </div>
        </div>
    </article>
   

    <article class="card_section" *ngIf="global_non_redemmed_similac.length > 0">
        <h3>{{ 'GROW_SPINWHEEL.SECTION_TWO.HEADER_1' | translate }}</h3>
        <div class="card" *ngFor="let data of global_non_redemmed_similac; let i=index">
            <div class="row">
                <div class="col-3">
                   
                    
                    <img *ngIf="data?.itemcode == '2010' " src="../../../assets/images/homesimilaclist1.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2011' " src="../../../assets/images/homesimilaclist2.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2007' " src="../../../assets/images/similacnewbornonelist.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2005' "  src="../../../assets/images/similactwolist.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2003' "  src="../../../assets/images/similactotalconfortonelist.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2008' " src="../../../assets/images/similacisomillist.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2012' "  src="../../../assets/images/similacneosurelist.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2001' "  src="../../../assets/images/similacalimentumlist.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2003' "  src="../../../assets/images/similaceyeq3list.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2006' " src="../../../assets/images/similac-total-comfort-1list.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2009' " src="../../../assets/images/isomilpluslist.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2002' " src="../../../assets/images/similaceye4list.png" alt="filter product" />
                    <img *ngIf="data?.itemcode == '2004' "  src="../../../assets/images/similac-total-comfort-2list.png" alt="filter product" />
                </div>
                <div class="col-9 card-content">
                    <p class="mb0" *ngIf="data?.itemcode == '2010' || data?.itemcode == '2011' || data?.itemcode == '2007' || data?.itemcode == '2005' || data?.itemcode == '2003' || data?.itemcode == '2008' || data?.itemcode == '2012'
                    || data?.itemcode == '2001' || data?.itemcode == '2003' || data?.itemcode == '2006' || data?.itemcode == '2009' || data?.itemcode == '2002' || data?.itemcode == '2004'">{{ 'GROW_SPINWHEEL.SECTION_TWO.KEY_1' | translate }} <span>{{data?.itemcode}}</span>
                   </p>
                    
                    <p class="mb0">{{ 'GROW_SPINWHEEL.SECTION_TWO.KEY_2' | translate }} <span>{{data?.scannedDate |
                            date:'dd/MM/yyyy'}}</span></p>
                    <p class="mb0">{{ 'GROW_SPINWHEEL.SECTION_TWO.KEY_3' | translate }}
                        <span>{{data?.expiryDate | date:'dd/MM/yyyy'}}</span>
                        
                    </p>
                   
                    <p class="mb0">{{ 'GROW_SPINWHEEL.SECTION_TWO.KEY_4' | translate }}
                        <span>{{ 'GROW_SPINWHEEL.SECTION_TWO.VALUE_4' | translate }}</span>
                    </p>

                   
                </div>
            </div>
        </div>

        <a (click)="linktoUserProfile()">{{ 'GROW_SPINWHEEL.SECTION_TWO.LINK' | translate }}</a>
    </article>
    <article class="last-section">
        <figure>
            <img src="../../../assets/images/group-2.png">
        </figure>
       
        <p>{{ 'SIMILAC_SPINWHEEL.SECTION_TWO.PARA_1_BEFORE_COUNT' | translate}} {{remaining_similac_chances}} {{
            'SIMILAC_SPINWHEEL.SECTION_TWO.PARA_1_AFTER_COUNT' | translate}}</p>
        <button class="orangeBtn mb-4 w-100" (click)="onClickScanner()"> {{ 'GROW_SPINWHEEL.SECTION_TWO.SUBMIT_BTN' |
            translate }}</button>
    </article>
</section>


<div bsModal #rewardModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm" >
        <div class="modal-content videoModalContainer">
            <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close"
                        (click)="rewardModalHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="blueTitle pt-3">Xin chúc mừng!</h2>
                   
                        <p class="colorSecondaryBlack pt-2">Bạn đã quay trúng phần thưởng là một</p>
                    <p class="orangeText pt-2"> {{sttrData?.gift_name}}</p>

                    <div class="imgBlock">
                        <img class="img-fluid middleImage" [src]="sttrData?.gift_image" />
                    </div>
                   
                    <p class="mb-0 pt-2">Quà sẽ được gứi đến bạn trong khoảng thơi gian chậm nhất là 45 ngày.</p>

                    <button class="orangeBtn my-4 btn-block" (click)="rewardModalHide()"> {{ 'REWARD_MODAL.BTN_TEXT' |
                        translate }}</button>

                    <div class="blueCard mt-40">
                        <p class="topText"> {{ 'REWARD_MODAL.CARD_TEXT_TOP' | translate }}</p>
                        <p class="colorBlackBold pt-2 mb-0">{{ 'REWARD_MODAL.CARD_PARA' | translate }}</p>
                        <p class="colorBlackBold pt-0">{{ 'REWARD_MODAL.CARD_PARA1' | translate }}</p>

                        <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'MILK_CODE.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                    </div>

                    <p class="py-4">{{ 'REWARD_MODAL.CODE' | translate }}</p>
                </section>
            </div>
        </div>
    </div>
</div>


<div bsModal #milkCodeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="milkCodeHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="blueTitle pt-3">{{ 'MILK_CODE.TITLE1' | translate }}</h2>
                    <h2 class="blueTitle pt-0">{{ 'MILK_CODE.TITLE2' | translate }}</h2>
                    <p class=" mb-0 pt-0" style="font-size: 4.3vw;"> {{ 'MILK_CODE.PARA' | translate }}<br /> {{
                        'MILK_CODE.PARA2' | translate }}<br /> {{ 'MILK_CODE.PARA3' | translate }}<br /> {{
                        'MILK_CODE.PARA4' | translate }}</p>
                  
                    <button class="orangeBtn my-4" (click)="milkCodeHide()"> {{ 'MILK_CODE.BTN_TEXT' | translate
                        }}</button>
                    <a class="pt-3 mb-4 links d-block"> {{ 'MILK_CODE.LINK_TEXT' | translate }}</a>
                    <div class="blueCard mt-4">
                        <p class="topText"> {{ 'MILK_CODE.CARD_TEXT_TOP' | translate }}</p>
                        <p class="colorBlackBold pt-2 mb-0">{{ 'MILK_CODE.CARD_PARA' | translate }}</p>
                        <p class="colorBlackBold pt-0">{{ 'MILK_CODE.CARD_PARA1' | translate }}</p>
                        <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'MILK_CODE.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                    </div>
                    <p class="py-4">{{ 'MILK_CODE.CODE' | translate }}</p>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #invalidQrCodeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close"
                        (click)="invalidQrCodeHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="blueTitle pt-3">{{ 'INVALIDQR_CODE1.TITLE1' | translate }}</h2>
                    <h2 class="blueTitle pt-0">{{ 'INVALIDQR_CODE1.TITLE2' | translate }}</h2>
                  
                    <div>
                        <p class="pt-2 mb-0">{{ 'INVALIDQR_CODE1.CODE2_PARA1' | translate }} <a class="links"> {{
                                'INVALIDQR_CODE1.CODE2_LINK' | translate }}</a> {{ 'INVALIDQR_CODE1.CODE2_PARA2' |
                            translate }}</p>
                    </div>

                    <div class="blueCard mt-4">
                        <p class="topText"> {{ 'INVALIDQR_CODE1.CARD_TEXT_TOP' | translate }}</p>
                        <p class="colorBlackBold pt-2 mb-0">{{ 'INVALIDQR_CODE1.CARD_PARA' | translate }}</p>
                        <p class="colorBlackBold pt-0">{{ 'INVALIDQR_CODE1.CARD_PARA1' | translate }}</p>
                        <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'MILK_CODE.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #successQrCodeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body">
                <section class="qrCodeScanModal">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close"
                        (click)="closeSuccessModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <div class="contents">
                        <h1>{{ 'QR_CODE_SCAN_POP_UP.TITLE' | translate }}</h1>
                        <p *ngIf="this.categoryCode == 'Grow'">{{ 'QR_CODE_SCAN_POP_UP.DESC1' | translate }} {{myDate |
                            date:'dd/MM/yyyy'}}.</p>
                        <p *ngIf="this.categoryCode == 'Similac'">{{ 'QR_CODE_SCAN_POP_UP.DESC1A' | translate }}
                            {{myDate | date:'dd/MM/yyyy'}}.</p>
                        <a href="javascript:;" class="orangeBtn" (click)="growOrSimilac()">{{
                            'QR_CODE_SCAN_POP_UP.JOIN_THE_LUCKY_WHEEL' | translate }}</a>
                        <p *ngIf="this.categoryCode == 'Grow'">{{ 'QR_CODE_SCAN_POP_UP.DESC2' | translate }}
                            {{global_grow_count}} {{ 'QR_CODE_SCAN_POP_UP.DESC3' | translate }}</p>
                        <p *ngIf="this.categoryCode == 'Similac'">{{ 'QR_CODE_SCAN_POP_UP.DESC2' | translate }}
                            {{global_similac_count}} {{ 'QR_CODE_SCAN_POP_UP.DESC3A' | translate }}</p>
                        <a class="blueBtn" (click)="onClickScanner()">{{ 'QR_CODE_SCAN_POP_UP.SCAN_QR_CODE' | translate
                            }}</a>
                        <p class="code">COR-C-22-21</p>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #betterLuckNextTime="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close"
                        (click)="betterLuckModalHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="blueTitle pt-3">{{ 'BETTER_LUCK_NEXT_TIME.HEADER' | translate }}</h2>
                    <p class="pt-2"> {{ 'BETTER_LUCK_NEXT_TIME.CONTENT_SIMILAC' | translate }}</p>
                    <button class="orangeBtn my-4 btn-block" (click)="betterLuckModalHide()"> {{
                        'BETTER_LUCK_NEXT_TIME.BTN_CONTENT' | translate }}</button>

                    <div class="blueCard mt-40">
                        <p class="topText"> {{ 'BETTER_LUCK_NEXT_TIME.CARD_TEXT_TOP' | translate }}</p>
                        <p class="colorBlackBold pt-2 mb-0">{{ 'BETTER_LUCK_NEXT_TIME.CARD_PARA' | translate }}</p>
                        <p class="colorBlackBold pt-0">{{ 'BETTER_LUCK_NEXT_TIME.CARD_PARA1' | translate }}</p>

                        <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'MILK_CODE.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                    </div>

                    <p class="pt-3 codeSize">{{ 'BETTER_LUCK_NEXT_TIME.CODE' | translate }}</p>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #milkCodeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="milkCodeHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="blueTitle pt-3">{{ 'MILK_CODE.TITLE1' | translate }}</h2>
                    <h2 class="blueTitle pt-0">{{ 'MILK_CODE.TITLE2' | translate }}</h2>
                    <p class=" mb-0 pt-0" style="font-size: 4.3vw;"> {{ 'MILK_CODE.PARA' | translate }}<br /> {{
                        'MILK_CODE.PARA2' | translate }}<br /> {{ 'MILK_CODE.PARA3' | translate }}<br /> {{
                        'MILK_CODE.PARA4' | translate }}</p>
                  
                    <button class="orangeBtn my-4" (click)="milkCodeHide()"> {{ 'MILK_CODE.BTN_TEXT' | translate
                        }}</button>
                    <a class="pt-3 mb-4 links d-block"> {{ 'MILK_CODE.LINK_TEXT' | translate }}</a>
                    <div class="blueCard mt-4">
                        <p class="topText"> {{ 'MILK_CODE.CARD_TEXT_TOP' | translate }}</p>
                        <p class="colorBlackBold pt-2 mb-0">{{ 'MILK_CODE.CARD_PARA' | translate }}</p>
                        <p class="colorBlackBold pt-0">{{ 'MILK_CODE.CARD_PARA1' | translate }}</p>
                        <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'MILK_CODE.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                    </div>
                    <p class="py-4">{{ 'MILK_CODE.CODE' | translate }}</p>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #termsSimilac="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body rewardsLoginOut px-0">
                <section class="similacTermsMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close"
                        (click)="termsSimilacHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h3 class="text-uppercase textSecondaryBlue">Thể lệ chương trình</h3>

                    <div class="giftList p-0 mt-3">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="grow-tab1" 
                                    data-toggle="tab" href="#grow1" role="tab" aria-controls="home"
                                    aria-selected="true">{{ 'REWARDS_LOGIN.ABBOTT_GROW' |
                                    translate }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="similac-tab1"
                                    data-toggle="tab" href="#similac1" role="tab" aria-controls="profile"
                                    aria-selected="false">{{ 'REWARDS_LOGIN.SIMILAC'
                                    | translate }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="pediasure-tab1"
                                    data-toggle="tab" href="#pediasure1" role="tab" aria-controls="profile"
                                    aria-selected="false">PediaSure</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="ensure-tab1"
                                    data-toggle="tab" href="#ensure1" role="tab" aria-controls="profile"
                                    aria-selected="false">Ensure Gold</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="glucerna-tab1"
                                    data-toggle="tab" href="#glucerna1" role="tab" aria-controls="profile"
                                    aria-selected="false">Glucerna</a>
                            </li>
                           
                        </ul>
                        <div class="tab-content" id="myTabContent1">
                            <div id="grow1" role="tabpanel" aria-labelledby="grow-tab1" class="tab-pane fade show active" >
                                <div class="innerContent">
                                    <section *ngIf="growtermsContent" [innerHTML]="growtermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="similac1" role="tabpanel" aria-labelledby="similac-tab1" class="tab-pane fade show">
                                <div class="innerContent">
                                    <section *ngIf="termsContent" [innerHTML]="termsContent | safe: 'html'"></section>
                                </div>
                            </div>
                            <div id="pediasure1" role="tabpanel" aria-labelledby="pediasure-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="pediasureltermsContent" [innerHTML]="pediasureltermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="ensure1" role="tabpanel" aria-labelledby="ensure-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="ensuretermsContent" [innerHTML]="ensuretermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="glucerna1" role="tabpanel" aria-labelledby="glucerna-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="glucernatermsContent" [innerHTML]="glucernatermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>


<app-auth-footer [hidden]="showScanner"></app-auth-footer>
<app-nav-menu></app-nav-menu>


<div [hidden]="!showScanner" >
  <div class="video-row" fxFlex="100" id="scandit-barcode-picker">
  </div>
    <button class="cameraBtn" (click)="closeScanner()">Đóng</button>
</div>