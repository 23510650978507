import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SafePipeModule } from 'safe-pipe'
import { ReactiveFormsModule } from '@angular/forms'
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

import { ApiService } from './services/api.service'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TracebilityLandingComponent } from './pages/tracebility-landing/tracebility-landing.component';
import { NavMenuComponent } from './pages/nav-menu/nav-menu.component';
import { BreadcrumbComponent } from './pages/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './pages/header/header.component';
import { HomeComponent } from './home/home.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { ManufacturingHollandComponent } from './pages/manufacturing-holland/manufacturing-holland.component';
import { FooterComponent } from './pages/footer/footer.component';
import { ManufacturingGranadaComponent } from './pages/manufacturing-granada/manufacturing-granada.component';
import { ManufacturingUsComponent } from './pages/manufacturing-us/manufacturing-us.component';
import { ManufacturingCootehillComponent } from './pages/manufacturing-cootehill/manufacturing-cootehill.component';
import { ManufacturingSingaporeComponent } from './pages/manufacturing-singapore/manufacturing-singapore.component';
import { QcGrowComponent } from './pages/qc-grow/qc-grow.component';
import { ProductDecriptionGrowComponent } from './pages/product-decription-grow/product-decription-grow.component';
import { QcBreadcrumbComponent } from './pages/qc-breadcrumb/qc-breadcrumb.component';
import { ManuBreadcrumbComponent } from './pages/manu-breadcrumb/manu-breadcrumb.component';
import { SimilacMomProductComponent } from './pages/similac-mom-product/similac-mom-product.component';
import { GrowSimiBreadcrumbComponent } from './pages/grow-simi-breadcrumb/grow-simi-breadcrumb.component';
import { NoAnimateBreadcrumbComponent } from './pages/no-animate-breadcrumb/no-animate-breadcrumb.component';
import { HomeSimilacComponent } from './pages/home-similac/home-similac.component';
import { SignInComponent } from './member-care/sign-in/sign-in.component';
import { SignupComponent } from './pages/signup/signup.component';
import { OtpComponent } from './pages/otp/otp.component';
import { SalesforceSignupComponent } from './pages/salesforce-signup/salesforce-signup.component';
import { AdditionalSalesSignupComponent } from './pages/additional-sales-signup/additional-sales-signup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CrmSignupComponent } from './pages/crm-signup/crm-signup.component';
import { SignupSuccessComponent } from './signup-success/signup-success.component';
import { TempProfileComponent } from './pages/temp-profile/temp-profile.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { AuthFooterComponent } from './pages/auth-footer/auth-footer.component';
import { GrowSpinWheelComponent } from './pages/grow-spin-wheel/grow-spin-wheel.component';
import { SimilacSpinWheelComponent } from './pages/similac-spin-wheel/similac-spin-wheel.component';

import { RewardsComponent } from './pages/rewards/rewards.component';
import { RewardsLoginBreadcrumbComponent } from './pages/rewards-login-breadcrumb/rewards-login-breadcrumb.component';
import { ProfileHomeComponent } from './pages/profile-home/profile-home.component';
import { ProfileHomeBreadcrumbComponent } from './pages/profile-home-breadcrumb/profile-home-breadcrumb.component';
import { ProfileHomeFooterComponent } from './pages/profile-home-footer/profile-home-footer.component';
import { LibraryComponent } from './pages/library/library.component';
import { DynamicFooterComponent } from './pages/dynamic-footer/dynamic-footer.component';
import { DynamicNoAnimateBreadcrumbComponent } from './pages/dynamic-no-animate-breadcrumb/dynamic-no-animate-breadcrumb.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { MyDatePickerModule } from 'mydatepicker';
import { JwtModule } from '@auth0/angular-jwt';
import { LuckyDrawQuotaExceedComponent } from './pages/lucky-draw-quota-exceed/lucky-draw-quota-exceed.component';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { viLocale } from 'ngx-bootstrap/locale';
 defineLocale('vi', viLocale);
import { KnowledgeHubComponent } from './pages/knowledge-hub/knowledge-hub.component';
import { SearchScannerComponent } from './pages/search-scanner/search-scanner.component';
import { UserProfileDetailComponent } from './pages/user-profile-detail/user-profile-detail.component';
import { NavigationService } from './navigation.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PopupModule } from '@ciri/ngx-popup';
import { ErrorInterceptor } from './auth/error.interceptor';
import { CountdownModule } from 'ngx-countdown';
import { EnsureProductComponent } from './ensure-product/ensure-product.component';
import { EnsureQcComponent } from './ensure-qc/ensure-qc.component';
import { GlucernaProductComponent } from './glucerna-product/glucerna-product.component';
import { GlucernaQcComponent } from './glucerna-qc/glucerna-qc.component';
import { PediasureQcComponent } from './pediasure-qc/pediasure-qc.component';
import { PediasureProductComponent } from './pediasure-product/pediasure-product.component';
import { EnsureSpinWheelComponent } from './pages/ensure-spin-wheel/ensure-spin-wheel.component';
import { GlucernaSpinWheelComponent } from './pages/glucerna-spin-wheel/glucerna-spin-wheel.component';
import { TempHoldingComponent } from './pages/temp-holding/temp-holding.component';
import { PediasureSpinwheelComponent } from './pages/pediasure-spinwheel/pediasure-spinwheel.component';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { MoiIconPipe } from './pipes/moi-icon/moi-icon.pipe';

import { environment as env } from './../environments/environment';
import { AlphabetOnlyDirective } from './pages/alphabet-only.directive';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";


export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function tokenGetter() {
  return sessionStorage.getItem('authToken');
}

Bugsnag.start({
  apiKey: '152fcaf495eea711f40cced2b590bebf',
  appVersion: env.bugsnag_version,
  collectUserIp: false
})



@NgModule({
  declarations: [
    AppComponent,
    TracebilityLandingComponent,
    NavMenuComponent,
    BreadcrumbComponent,
    HeaderComponent,
    HomeComponent,
    LoaderComponent,
    ManufacturingHollandComponent,
    FooterComponent,
    ManufacturingGranadaComponent,
    ManufacturingUsComponent,
    ManufacturingCootehillComponent,
    ManufacturingSingaporeComponent,
    QcGrowComponent,
    ProductDecriptionGrowComponent,
    QcBreadcrumbComponent,
    ManuBreadcrumbComponent,
    SimilacMomProductComponent,
    GrowSimiBreadcrumbComponent,
    NoAnimateBreadcrumbComponent,
    HomeSimilacComponent,
    SignInComponent,
    SignupComponent,
    OtpComponent,
    SalesforceSignupComponent ,
    AdditionalSalesSignupComponent,
    CrmSignupComponent,
    SignupSuccessComponent,
    TempProfileComponent,
    ForgotPasswordComponent,
    AuthFooterComponent,
    GrowSpinWheelComponent,
    SimilacSpinWheelComponent,
    RewardsComponent,
    RewardsLoginBreadcrumbComponent,
    ProfileHomeComponent,
    ProfileHomeBreadcrumbComponent,
    ProfileHomeFooterComponent,
    LibraryComponent,
    DynamicFooterComponent,
    DynamicNoAnimateBreadcrumbComponent,
    UserProfileComponent,
    LuckyDrawQuotaExceedComponent,
    KnowledgeHubComponent,
    SearchScannerComponent,
    UserProfileDetailComponent,
    EnsureProductComponent,
    EnsureQcComponent,
    GlucernaProductComponent,
    GlucernaQcComponent,
    PediasureQcComponent,
    PediasureProductComponent,
    EnsureSpinWheelComponent,
    GlucernaSpinWheelComponent,
    TempHoldingComponent,
    PediasureSpinwheelComponent,
    MoiIconPipe
    ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SafePipeModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({timeOut: 2000}),
        NgSelectModule,
        ModalModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ScrollToModule.forRoot(),
        MyDatePickerModule,
        BsDatepickerModule.forRoot(),
        DatepickerModule.forRoot(),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter
            }
        }),
        ImageCropperModule,
        PopupModule,
        CountdownModule,
        CommonModule,
        RouterModule,
    ],
  providers: [ApiService, NavigationService,
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
