<app-header></app-header>
<!-- <app-grow-simi-breadcrumb></app-grow-simi-breadcrumb> -->
<section class="similacMomProductContainer">
    <section>
        <img *ngIf="(subCatCode == '2010' && milkCode == '0') || (subCatCode == '2011' && milkCode == '0')"
            class="img-fluid w-100" src="../../../assets/images/similacmombanner.png">
        <img *ngIf="(subCatCode == '2001' && milkCode == '1') ||(subCatCode == '2008' && milkCode == '1') || (subCatCode == '2009' && milkCode == '1') || (subCatCode == '2012' && milkCode == '1') || (subCatCode == '2005' && milkCode == '1') || (subCatCode == '2003' && milkCode == '1') || (subCatCode == '2007' && milkCode == '1')"
            class="img-fluid w-100" src="../../../assets/images/isomailbanner.png">
        <img *ngIf="(subCatCode == '2013' && milkCode == '1') || (subCatCode == '2006' && milkCode == '1') || (subCatCode == '2004')"
            class="img-fluid w-100" src="../../../assets/images/similactotalcomfortbanner.png">
        <img class="img-fuid w-100" *ngIf="subCatCode == '2002' && milkCode == '0'"
            src="../../../assets/images/productnonmilkcodesimilacbanner.png" />

        <!-- New similac starts here -->
        <img class="img-fuid w-100"
            *ngIf="subCatCode == '2014' || subCatCode == '2015' || subCatCode == '2016' || subCatCode == '2017' "
            src="../../../assets/images/prd-similac/prd_similac1_top_banner.png" alt="prd_similac1_top_banner" />

        <section class="similacInnerSectionOut"
            *ngIf="(subCatCode == '2010' && milkCode == '0') || (subCatCode == '2011' && milkCode == '0')">
            <section class="similacVannilaOut">
                <img class="similacMomLogo" src="../../../assets/images/logo-similac-mom.png">
                <p>{{ 'SIMILAC_MOM_PRODUCT.TOP_TITLE1' | translate }}<span><img class="iqPlusLogo"
                            src="../../../assets/images/logo-iqplus.png"></span> {{
                    'SIMILAC_MOM_PRODUCT.TOP_TITLE_AFTER_IMAGE' | translate }}</p>
                <!-- strawberryproduct -->
                <div *ngIf="subCatCode == '2010'">
                    <img class="productImage" src="../../../assets/images/product.png">
                    <p class="productDescription">{{ 'SIMILAC_MOM_PRODUCT.STRABERRY_PRODUCT.CONTENT' | translate}}</p>
                </div>
                <!-- vannila product -->
                <div *ngIf="subCatCode == '2011'">
                    <img class="productImage" src="../../../assets/images/product-vanni.png">
                    <p class="productDescription">{{ 'SIMILAC_MOM_PRODUCT.VANNILA_PRODUCT.CONTENT' | translate}}</p>
                </div>
                <p class="productDescriptionSecondary mt-4">{{ 'SIMILAC_MOM_PRODUCT.PRODUCT_DESC' | translate}}</p>
                <section class="similiacStepCircleBlockOut">
                    <div class="circleBlock">
                        <p>1</p>
                    </div>
                    <img class="img-fluid w-100" src="../../../assets/images/chart.png">

                    <div class="logoWithTitleBlock">
                        <img class="similacMomLogo" src="../../../assets/images/logo-similac-mom.png">
                        <p class="logoTitle">{{ 'SIMILAC_MOM_PRODUCT.BLOCK_ONE_SIMILAC_TITLE_LINE_ONE' | translate}}</p>
                        <p class="logoTitle"> {{ 'SIMILAC_MOM_PRODUCT.BLOCK_ONE_SIMILAC_TITLE_LINE_TWO' | translate}}
                        </p>
                    </div>
                    <img class="img-fluid w-100" src="../../../assets/images/group-18.png">

                    <p class="blockOneBottomContent">{{ 'SIMILAC_MOM_PRODUCT.BLOCK_ONE_BOTTOM_TEXT' | translate}}</p>
                    <p class="blockOneBottomContentSub">{{ 'SIMILAC_MOM_PRODUCT.BLOCK_ONE_BOTTOM_TEXT_TWO' | translate}}
                    </p>

                </section>
                <section class="similiacStepCircleBlockOut">
                    <div class="circleBlock">
                        <p>2</p>
                    </div>
                    <div class="logoWithTitleBlock">
                        <img class="similacMomLogo" src="../../../assets/images/logo-similac-mom.png">
                        <p class="logoTitle"> {{ 'SIMILAC_MOM_PRODUCT.BLOCK_TWO_SIMILAC_TITLE_LINE_ONE' | translate}}
                        </p>
                        <p class="logoTitle"> {{ 'SIMILAC_MOM_PRODUCT.BLOCK_TWO_SIMILAC_TITLE_LINE_TWO' | translate}}
                        </p>
                    </div>
                    <p class="blockTwoSecondaryTitle"> {{ 'SIMILAC_MOM_PRODUCT.BLOCK_TWO_SUB_TITLE' | translate}}</p>

                    <div class="bisqueBackroundBlock">
                        <img class="iplusLiquidLogo" src="../../../assets/images/iplusliquiddropogo.png">
                        <p class="primaryTitle"> {{
                            'SIMILAC_MOM_PRODUCT.BLOCK_TWO_EQ_PLUS_ONE.TITLE_CONTENT_BEFORE_IMAGE' | translate}} <img
                                class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"> {{
                            'SIMILAC_MOM_PRODUCT.BLOCK_TWO_EQ_PLUS_ONE.TITLE_CONTENT_AFTER_IMAGE' | translate}}</p>
                        <ul>
                            <li>
                                <div class="row align-items-center">
                                    <div class="col-2">
                                        <img class="ellipseImage" src="../../../assets/images/ellipse-525-copy-9.png">
                                    </div>
                                    <div class="col-10 text-left pl-0">
                                        <p>{{ 'SIMILAC_MOM_PRODUCT.BLOCK_TWO_EQ_PLUS_ONE.LIST_CONTENT_ONE' | translate}}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row align-items-center">
                                    <div class="col-2">
                                        <img class="ellipseImage" src="../../../assets/images/ellipse-525-copy-9.png">
                                    </div>
                                    <div class="col-10 text-left pl-0">
                                        <p>{{ 'SIMILAC_MOM_PRODUCT.BLOCK_TWO_EQ_PLUS_ONE.LIST_CONTENT_TWO' | translate}}
                                        </p>

                                    </div>
                                </div>

                            </li>
                            <li>
                                <div class="row align-items-center">
                                    <div class="col-2">
                                        <img class="ellipseImage" src="../../../assets/images/ellipse-525-copy-9.png">
                                    </div>
                                    <div class="col-10 text-left pl-0">
                                        <p>{{ 'SIMILAC_MOM_PRODUCT.BLOCK_TWO_EQ_PLUS_ONE.LIST_CONTENT_THREE' |
                                            translate}}</p>

                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="bisqueBackroundBlock">
                        <ul>
                            <li>
                                <div class="row">
                                    <div class="col-2">
                                        <img class="leftImage" src="../../../assets/images/znplus.png">
                                    </div>
                                    <div class="col-10 text-left">
                                        <p class="primaryContent">{{
                                            'SIMILAC_MOM_PRODUCT.BLOCK_TWO_BOTTOM_CONTENT.PRIMARY_CONTENT_ONE' |
                                            translate}}</p>
                                        <p class="secondaryContent">{{
                                            'SIMILAC_MOM_PRODUCT.BLOCK_TWO_BOTTOM_CONTENT.SECONDARY_CONTENT_ONE' |
                                            translate}}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-2">
                                        <img class="leftImage" src="../../../assets/images/liquidstomach.png">
                                    </div>
                                    <div class="col-10 text-left">
                                        <p class="primaryContent">{{
                                            'SIMILAC_MOM_PRODUCT.BLOCK_TWO_BOTTOM_CONTENT.PRIMARY_CONTENT_TWO' |
                                            translate}}</p>
                                        <p class="secondaryContent">{{
                                            'SIMILAC_MOM_PRODUCT.BLOCK_TWO_BOTTOM_CONTENT.SECONDARY_CONTENT_TWO' |
                                            translate}}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-2">
                                        <img class="leftImage" src="../../../assets/images/liquidstrength.png">
                                    </div>
                                    <div class="col-10 text-left">
                                        <p class="primaryContent"> {{
                                            'SIMILAC_MOM_PRODUCT.BLOCK_TWO_BOTTOM_CONTENT.PRIMARY_CONTENT_THREE' |
                                            translate}}</p>
                                        <p class="secondaryContent"> {{
                                            'SIMILAC_MOM_PRODUCT.BLOCK_TWO_BOTTOM_CONTENT.SECONDARY_CONTENT_THREE' |
                                            translate}}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                <section class="similiacStepCircleBlockOut">
                    <div class="circleBlock">
                        <p>3</p>
                    </div>

                    <p class="logowithText"><img class="thirdBlockSimilacLogo"
                            src="../../../assets/images/logo-similac-mom.png" /> {{
                        'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.TOP_TITLE_ONE' | translate}}</p>
                    <img class="img-fluid w-100 mt-3" src="../../../assets/images/how-to-use.png">
                    <p class="logowithText mt-5">Cách pha 1 ly <img class="thirdBlockSimilacLogo"
                            src="../../../assets/images/logo-similac-mom.png" /> {{
                        'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.TOP_TITLE_TWO' | translate}} </p>

                    <ul class="mt-3">
                        <li class="mt-3">
                            <div class="row align-items-center">
                                <div class="col-2">
                                    <img class="yellowLeftImages" src="../../../assets/images/yellowliquid1.png" />
                                </div>
                                <div class="col-9 text-left pr-0">
                                    <p class="goldenDropContent">{{
                                        'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.BLOCK_THREE_LIST_CONTENT_ONE_LINE_ONE'
                                        | translate}}</p>
                                    <p class="goldenDropContent">{{
                                        'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.BLOCK_THREE_LIST_CONTENT_ONE_LINE_TWO'
                                        | translate}}</p>
                                </div>
                            </div>
                        </li>
                        <li class="mt-3">
                            <div class="row align-items-center">
                                <div class="col-2">
                                    <img class="yellowLeftImages" src="../../../assets/images/yellowliquid2.png" />
                                </div>
                                <div class="col-9 text-left pr-0">
                                    <p class="goldenDropContent">{{
                                        'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.BLOCK_THREE_LIST_CONTENT_TWO' |
                                        translate}}</p>
                                </div>
                            </div>
                        </li>
                        <li class="mt-3">
                            <div class="row align-items-center">
                                <div class="col-2">
                                    <img class="yellowLeftImages" src="../../../assets/images/yellowliquid3.png" />
                                </div>
                                <div class="col-9 text-left pr-0">
                                    <p class="goldenDropContent">{{
                                        'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.BLOCK_THREE_LIST_CONTENT_THREE_LINE_ONE'
                                        | translate}}</p>
                                    <p class="goldenDropContent">{{
                                        'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.BLOCK_THREE_LIST_CONTENT_THREE_LINE_TWO'
                                        | translate}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <p class="logowithText mt-4"> {{ 'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.MIDDLE_TITLE_LINE_ONE' |
                        translate}}</p>
                    <p class="logowithText mt-0"><img class="thirdBlockSimilacLogo"
                            src="../../../assets/images/logo-similac-mom.png" /> {{
                        'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.MIDDLE_TITLE_LINE_TWO' | translate}}</p>
                </section>
            </section>
            <ul class="thirdBlockProductsSection">
                <li>
                    <div class="row">
                        <div class="col-2">
                            <img class="productLeftImages" src="../../../assets/images/biscuit.png">
                        </div>
                        <div class="col-9 pr-0">
                            <p>{{ 'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.PRODUCTS_LIST.LIST_CONTENT_ONE' | translate}}
                            </p>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="row">
                        <div class="col-2">
                            <img class="productLeftImages" src="../../../assets/images/ice.png">
                        </div>
                        <div class="col-9 pr-0">
                            <p>{{ 'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.PRODUCTS_LIST.LIST_CONTENT_TWO' | translate}}
                            </p>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="row">
                        <div class="col-2">
                            <img class="productLeftImages" src="../../../assets/images/wheat.png">
                        </div>
                        <div class="col-9 pr-0">
                            <p> {{ 'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.PRODUCTS_LIST.LIST_CONTENT_THREE' |
                                translate}}</p>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="row">
                        <div class="col-2">
                            <img class="productLeftImages" src="../../../assets/images/spoon.png">
                        </div>
                        <div class="col-9 pr-0">
                            <p>{{ 'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.PRODUCTS_LIST.LIST_CONTENT_FOUR' |
                                translate}}</p>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="row">
                        <div class="col-2">
                            <img class="productLeftImages" src="../../../assets/images/chocolate-bar.png">
                        </div>
                        <div class="col-9 pr-0">
                            <p>{{ 'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.PRODUCTS_LIST.LIST_CONTENT_FIVE' |
                                translate}}</p>
                            <ul class="childListItemsBlock">
                                <li>
                                    <div class="row">
                                        <div class="col-2">
                                            <img class="eclipseImage"
                                                src="../../../assets/images/ellipse-525-copy-9.png">
                                        </div>
                                        <div class="col-10 p-0">
                                            <p>{{
                                                'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.BOTTOM_SUB_LIST.LIST_CONTENT_ONE'
                                                | translate}} </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <img class="eclipseImage"
                                                src="../../../assets/images/ellipse-525-copy-9.png">
                                        </div>
                                        <div class="col-10 p-0">
                                            <p>{{
                                                'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.BOTTOM_SUB_LIST.LIST_CONTENT_TWO'
                                                | translate}} </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <img class="eclipseImage"
                                                src="../../../assets/images/ellipse-525-copy-9.png">
                                        </div>
                                        <div class="col-10 p-0">
                                            <p>{{
                                                'SIMILAC_MOM_PRODUCT.BLOCK_THREE_CONTENT.BOTTOM_SUB_LIST.LIST_CONTENT_THREE'
                                                | translate}} </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </section>
    </section>

    <!-- isomil section starts -->
    <section *ngIf="subCatCode == '2008' && milkCode == '1'">
        <section class="similacInnerSectionOut">
            <section class="similacVannilaOut">
                <p class="isomilTopTitle">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.ISOMIL_TOP_TITLE' | translate }}</p>

                <!-- isomil -->
                <section class="isomilProductTopImage">
                    <h2 class="mt-4">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.PRODUCT_ISOMIL' | translate }}</h2>
                    <img class="isomilProductImage" src="../../../assets/images/isomilImageNew.png" />

                    <p class="productBottomContent">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.PRODUCT_BOTTOM_CONTENT1' | translate
                        }}</p>
                    <p class="productBottomContent">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.PRODUCT_BOTTOM_CONTENT2' | translate
                        }}</p>
                </section>
                <p class="productBottomDescription mt-4"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.BOTTOM_DESC_1' | translate }}
                </p>
                <p class="productBottomDescription"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.BOTTOM_DESC_2' | translate }} </p>
                <p class="productBottomDescription"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.BOTTOM_DESC_3' | translate }} </p>
                <section class="blueBorderContentBlock">
                    <p><span> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.BLUE_BOX_BOLD' | translate }}</span>
                        {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.BLUE_BOX_CONTENT' | translate }}</p>
                    <p>{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.BLUE_BOX_CONTENT_TWO' | translate }}</p>
                </section>
                <section class="backgroundWithTopCenteredImageBlock">
                    <img class="img-fluid topCenteredImage" src="../../../assets/images/isomilteddybear.png">
                    <p class="mt-4"><span>{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.PRODUCT_ISOMIL' | translate }} </span>{{
                        'SIMILAC_MOM_PRODUCT.ISOMIL.ISOMIL_RED_BLOCK_CONTENT' | translate }} </p>
                    <p><span> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.PRODUCT_ISOMIL' | translate }} </span> {{
                        'SIMILAC_MOM_PRODUCT.ISOMIL.ISOMIL_RED_BLOCK_CONTENT2' | translate }}</p>
                </section>
                <p class="paragraphWithLeftContentBold">
                    <span class="boldLeftContent">
                        {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.TABLE_BOLD_CONTENT' | translate }}
                    </span>
                    {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.CONTENT_BEFORE_TABLE' | translate }}
                </p>
                <img class="w-100 mt-4" src="../../../assets/images/table-01-isomil-tcm-1313-148836.png">
                <h5 class="blueTitleContent mt-4"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_TITLE' | translate }} </h5>
                <p class="paragraphWithLeftContentBold">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_DESC_CONTENT' |
                    translate }}
                </p>
                <ul class="ListContainerBlockwithBlackFont">
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent">{{
                                'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_SHORT_TITLE' | translate }}</span></p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_ONE' |
                            translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_TWO' |
                            translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_THREE' |
                            translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"> {{
                            'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_FOUR' | translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold">{{
                            'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_FIVE' | translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold">{{
                            'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_SIX' | translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold">
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_SEVEN' | translate }}
                        </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"> {{
                            'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_EIGHT' | translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold">
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_NINE' | translate }}
                        </p>
                    </li>

                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent"> {{
                                'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_TEN_BOLD' | translate }}</span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_TEN' | translate }} </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent"> {{
                                'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_ELEVEN_BOLD' | translate }} </span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_ELEVEN' | translate }}
                        </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent">{{
                                'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_TWELVE_BOLD' | translate }} </span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_TWELVE' | translate }}
                        </p>

                        <p class="paragraphWithLeftContentBold"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_TWELVE_TWO'
                            | translate }}</p>
                        <p class="paragraphWithLeftContentBold"> {{
                            'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_TWELVE_THREE' | translate }}</p>
                    </li>
                    <li>
                        <!-- <img class="w-100 my-3" src="../../../assets/images/tabletwo.png"> -->
                        <img class="w-100 my-3" src="../../../assets/images/tablebottomisomil.png">
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent"> {{
                                'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_FOURTEEN_BOLD' | translate }} </span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_FOURTEEN' | translate }}
                        </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent"> {{
                                'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_FIFTEEN_BOLD' | translate }} </span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_FIFTEEN' | translate }}
                        </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent"> {{
                                'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_SIXTEEN_BOLD' | translate }} </span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_SIXTEEN' | translate }}
                        </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent"> {{
                                'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_SEVENTEEN_BOLD' | translate }} </span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.LIST_CONTENT_SEVENTEEN' | translate }}
                        </p>
                    </li>
                </ul>

            </section>
        </section>
    </section>
    <!-- isomil section ends -->

    <!-- isomil plus section  goes here-->
    <section *ngIf="subCatCode == '2009' && milkCode == '1'">
        <section class="similacInnerSectionOut">
            <section class="similacVannilaOut">
                <p class="isomilTopTitle">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.ISOMIL_TOP_TITLE' | translate }}</p>
                <!-- isomil -->
                <section class="isomilProductTopImage">
                    <h2 class="mt-4">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.ISOMIL_PLUS_CONTENT' | translate }}</h2>
                    <img class="isomilProductImage" src="../../../assets/images/isomilPlusImageNew.png" />

                    <p class="productBottomContent">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.PRODUCT_BOTTOM_CONTENT1' |
                        translate
                        }}</p>
                    <p class="productBottomContent">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.PRODUCT_BOTTOM_CONTENT2' |
                        translate
                        }}</p>
                </section>
                <p class="productBottomDescription mt-4"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.BOTTOM_DESC_1' | translate
                    }}</p>
                <p class="productBottomDescription"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.BOTTOM_DESC_2' | translate }}
                </p>
                <p class="productBottomDescription"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.BOTTOM_DESC_3' | translate }}
                </p>

                <section class="blueBorderContentBlock">
                    <p><span> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.BLUE_BOX_BOLD' | translate }}</span>
                        {{ 'SIMILAC_MOM_PRODUCT.ISOMIL.BLUE_BOX_CONTENT' | translate }}</p>
                    <p>{{ 'SIMILAC_MOM_PRODUCT.ISOMIL.BLUE_BOX_CONTENT_TWO' | translate }}</p>
                </section>

                <section class="backgroundWithTopCenteredImageBlock">
                    <img class="img-fluid topCenteredImage" src="../../../assets/images/isomilplusteddybearimage.png">

                    <p class="mt-4"><span>{{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.ISOMIL_PLUS_CONTENT' | translate }}
                        </span>{{
                        'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.ISOMIL_RED_BLOCK_CONTENT' | translate }} </p>
                    <p><span> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.ISOMIL_PLUS_CONTENT' | translate }} </span> {{
                        'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.ISOMIL_RED_BLOCK_CONTENT2' | translate }}</p>
                </section>
                <p class="paragraphWithLeftContentBold">
                    <span class="boldLeftContent">
                        {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.TABLE_BOLD_CONTENT' | translate }}
                    </span>
                    {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.CONTENT_BEFORE_TABLE' | translate }}
                </p>
                <img class="w-100 mt-4" src="../../../assets/images/table-01-isomil-tcm-1313-148836.png">
                <p class="paragraphWithLeftContentBold">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.CONTENT_AFTER_TABLE' |
                    translate }}</p>
                <h5 class="blueTitleContent mt-4"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_TITLE' | translate }} </h5>
                <p class="paragraphWithLeftContentBold mb-0">{{
                    'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_DESCRIPTION_BEFORE' |
                    translate }}
                    <span class="boldLeftContent"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.ISOMIL_PLUS_CONTENT' | translate
                        }}</span> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_DESCRIPTION_AFTER' | translate }}
                </p>
                <p class="paragraphWithLeftContentBold mt-0">{{
                    'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_DESCRIPTION_AFTER_ONE' | translate }}</p>
                <ul class="ListContainerBlockwithBlackFont">
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent">{{
                                'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_SHORT_TITLE' | translate }}</span></p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_ONE' |
                            translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_TWO' |
                            translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_THREE'
                            |
                            translate }}</p>

                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"> {{
                            'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_FOUR_BEFORE' | translate }}<span
                                class="boldLeftContent">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.ISOMIL_PLUS_CONTENT' |
                                translate
                                }}</span> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_FOUR_AFTER' | translate }}
                        </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"> {{
                            'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_FIVE_TYPE_ONE' | translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent">{{
                                'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_FIVE_BOLD' | translate }}</span> {{
                            'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_FIVE' | translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent">{{
                                'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_SIX_BOLD' | translate }}</span> {{
                            'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_SIX' | translate }}</p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold">
                            <span class="boldLeftContent">{{
                                'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_SEVEN_BOLD' | translate }}</span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_SEVEN' | translate }}
                        </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"> {{
                            'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_EIGHT_BEFORE' | translate }}<span
                                class="boldLeftContent">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.ISOMIL_PLUS_CONTENT' |
                                translate
                                }}</span> {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_EIGHT_AFTER' | translate }}
                        </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold">

                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_NINE_BEFORE' | translate }}
                            <span class="boldLeftContent">{{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.ISOMIL_PLUS_CONTENT' |
                                translate }}</span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_NINE_AFTER' | translate }}
                        </p>
                    </li>
                    <li>
                        <img class="w-100 my-3" src="../../../assets/images/tabletwo.png">

                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent"> {{
                                'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_TEN_BOLD' | translate }}</span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_TEN' | translate }} </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent"> {{
                                'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_ELEVEN_BOLD' | translate }} </span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_ELEVEN' | translate }}
                        </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent">{{
                                'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_TWELVE_BOLD' | translate }} </span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_TWELVE' | translate }}
                        </p>
                    </li>
                    <li>
                        <p class="paragraphWithLeftContentBold"><span class="boldLeftContent"> {{
                                'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_THIRTEEN_BOLD' | translate }} </span>
                            {{ 'SIMILAC_MOM_PRODUCT.ISOMIL_PLUS.LIST_CONTENT_THIRTEEN' | translate }}
                        </p>
                    </li>
                </ul>
            </section>
        </section>
    </section>
    <!-- iosmil plus section ends here -->


    <section class="milkcodeSimilac">
        <!--  Simailac Neosure starts-->
        <section class="similacNeosure" *ngIf="subCatCode == '2012' && milkCode == '1'">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="titleContainer">
                            <p>{{ 'SIMILAC_NEOSURE.TITLE' | translate }}</p>
                            <h4>Similac Neosure <span><img class="iqPlusLogo"
                                        src="../../../assets/images/logo-iqplus.png"></span></h4>
                            <figure>
                                <img class="productImage" src="../../../assets/images/similac-neosure-850-g-min.png">
                            </figure>
                            <p>{{ 'SIMILAC_NEOSURE.SUB_TITLE' | translate }}<br> <span>{{ 'SIMILAC_NEOSURE.SUB_TITLE1' |
                                    translate }}</span></p>
                        </div>
                        <div class="noteSection">
                            <p><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.STRONG' | translate }}</strong> {{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE1' | translate }}</p>
                            <p class="mt10">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE2' | translate }}</p>
                        </div>
                        <p class="text-center">{{ 'SIMILAC_NEOSURE.NEOSURE_DESC' | translate }}<span><img
                                    class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"></span>
                            {{'SIMILAC_NEOSURE.NEOSURE_DESC1' | translate }}
                        <figure>
                            <img class="vectorImage my-5" src="../../../assets/images/vector-smart-object-similac.png">
                        </figure>
                        <div class="similacBlueCard">
                            <div class="card">
                                <h3>{{ 'SIMILAC_NEOSURE.CARD.HEADING1' | translate }}</h3>
                                <p>{{ 'SIMILAC_NEOSURE.CARD.CARD1_TEXT' | translate }} <span><img class="iqPlusLogo"
                                            src="../../../assets/images/logo-iqplus.png"></span>{{
                                    'SIMILAC_NEOSURE.CARD.CARD1_TEXT2' | translate }} <strong class="textBlack">{{
                                        'SIMILAC_NEOSURE.CARD.CARD1_TEXT2_BOLD' | translate }}</strong>
                                    {{ 'SIMILAC_NEOSURE.CARD.CARD1_TEXT3' | translate }}
                                </p>
                            </div>
                            <div class="card">
                                <h3>{{'SIMILAC_NEOSURE.CARD.HEADING2' | translate }}<br> <span>{{
                                        'SIMILAC_NEOSURE.CARD.HEADING2_SUB' | translate }}</span></h3>
                                <p>{{ 'SIMILAC_NEOSURE.CARD.CARD2_TEXT1' | translate }} </p>
                                <ul>
                                    <li>Tăng cường sức để kháng tự nhiên của đường tiêu hoá</li>
                                    <li>Giúp cơ thể tạo kháng thể</li>
                                    <li>Hỗ trợ phát triển các tế bào miễn dịch</li>
                                </ul>
                            </div>
                            <div class="card">
                                <h3>{{ 'SIMILAC_NEOSURE.CARD.HEADING3' | translate }}</h3>
                                <p>{{ 'SIMILAC_NEOSURE.CARD.CARD3_TEXT1' | translate }}
                                    <span><img class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"></span>
                                    {{ 'SIMILAC_NEOSURE.CARD.CARD3_TEXT2' | translate }}
                                </p>
                            </div>
                        </div>
                        <p class="text-justify"><strong>{{'SIMILAC_NEOSURE.INGREDIENTS.STRONG' | translate
                                }}</strong>{{'SIMILAC_NEOSURE.INGREDIENTS.DESC' | translate }}</p>
                        <figure>
                            <img src="../../../assets/images/screen-shot-2020-12-23-at-12-51-49-am.png">
                        </figure>
                        <div class="userManual">
                            <h4>{{ 'SIMILAC_NEOSURE.USER_MANUAL.TITLE' | translate }}</h4>
                            <p class="text-justify">{{ 'SIMILAC_NEOSURE.USER_MANUAL.DESC1' | translate }}<span><img
                                        class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"></span>
                                {{ 'SIMILAC_NEOSURE.USER_MANUAL.DESC2' | translate }}
                                <span><img class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"></span>
                                {{ 'SIMILAC_NEOSURE.USER_MANUAL.DESC3' | translate }}
                            </p>

                            <strong>Cách pha chuẩn</strong>
                            <div class="steps">
                                <div class="container">
                                    <div class="row mt20">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object01.png">
                                        </div>
                                        <div class="col-8">
                                            <p> {{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STEP1' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object02.png">
                                        </div>
                                        <div class="col-8">
                                            <p> {{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STEP2' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center ">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object03.png">
                                        </div>
                                        <div class="col-8">
                                            <p> {{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STEP3' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object04.png">
                                        </div>
                                        <div class="col-8">
                                            <p> {{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STEP4' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object05.png">
                                        </div>
                                        <div class="col-8">
                                            <p> {{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STEP5' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object06.png">
                                        </div>
                                        <div class="col-8">
                                            <p> {{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STEP6' | translate }}
                                                <span><img class="iqPlusLogo"
                                                        src="../../../assets/images/logo-iqplus.png"></span>
                                                {{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STEP6_SUB' | translate }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object07.png">

                                        </div>
                                        <div class="col-8">
                                            <p> {{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STEP7' | translate }}
                                                <span><img class="iqPlusLogo"
                                                        src="../../../assets/images/logo-iqplus.png"></span>
                                                {{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STEP7_SUB' | translate }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object08.png">
                                        </div>
                                        <div class="col-8">
                                            <p> {{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STEP8' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object09.png">
                                        </div>
                                        <div class="col-8">
                                            <p> {{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STEP9' | translate }}</p>
                                        </div>
                                    </div>
                                    <p class="mt20"><strong>{{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STORAGE' | translate
                                            }}</strong>{{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.STORAGE_TEXT' | translate
                                        }}</p>
                                    <p class="mt20"><strong>{{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.CAUTION' | translate
                                            }}</strong>{{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.CAUTION_TEXT' | translate
                                        }}</p>
                                    <p class="mt20"><strong>{{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.NOTE' | translate
                                            }}</strong>{{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.NOTE_TEXT1' | translate }}
                                    </p>
                                    <p class="mt20">{{ 'SIMILAC_NEOSURE.USER_MANUAL.STEPS.NOTE_TEXT2' | translate }}
                                        <span><img class="iqPlusLogo"
                                                src="../../../assets/images/logo-iqplus.png"></span>{{
                                        'SIMILAC_NEOSURE.USER_MANUAL.STEPS.NOTE_TEXT3' | translate }}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <figure>
                            <img src="../../../assets/images/table-similac-milkcode.png">
                        </figure>

                    </div>
                </div>
            </div>
        </section>
        <!--  Simailac Neosure ends-->

        <!-- similac Alimentum Starts -->
        <section class="similacAlimentum" *ngIf="subCatCode == '2001' && milkCode == '1'">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="titleContainer">
                            <p>{{ 'SIMILAC_ALLIMENTUM.TITLE' | translate }}</p>
                            <h4>Similac Alimentum <span><img class="iqPlusLogo"
                                        src="../../../assets/images/logo-iqplus.png"></span></h4>
                            <figure>
                                <img class="productImage" src="../../../assets/images/packshot-alimentum.png">
                            </figure>
                            <p>{{ 'SIMILAC_ALLIMENTUM.SUB_TITLE' | translate }}<br> <span>{{
                                    'SIMILAC_ALLIMENTUM.SUB_TITLE1' |
                                    translate }}</span></p>
                            <p class="mt20">{{ 'SIMILAC_ALLIMENTUM.DESC' | translate }}</p>
                        </div>
                        <div class="noteSection">
                            <p><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.STRONG' | translate }}</strong> {{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE1' | translate }}</p>
                            <p class="mt10">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE2' | translate }}</p>
                        </div>

                        <div class="allimentumCard">
                            <div class="card">
                                <img class="topImage" src="../../../assets/images/layer-693.png">
                                <p class="whiteTxt mt-4"><strong>{{ 'SIMILAC_ALLIMENTUM.ALLIMENTUM_NOTE.STRONG' |
                                        translate
                                        }}</strong><span><img class="iqPlusLogo"
                                            src="../../../assets/images/logo-iqplus.png"></span>{{
                                    'SIMILAC_ALLIMENTUM.ALLIMENTUM_NOTE.NOTE' | translate }}</p>
                            </div>
                        </div>
                        <div class="similacBlueCard">
                            <div class="card2 ">
                                <img class="cardImage" src="../../../assets/images/vector-smart-object1_similac.png">
                                <p>Hệ dưỡng chất <span><img class="iqPlusLogo"
                                            src="../../../assets/images/logo-iqplus.png"></span> bổ sung DHA và AA. </p>
                            </div>
                            <div class="card2 ">
                                <img class="cardImage" src="../../../assets/images/vector-smart-object2_similac.png">
                                <p>Không chứa Lactose. Phù hợp cho trẻ bất dung nạp Lactose. Tham khảo thêm ý kiến của
                                    bác sỹ.</p>
                            </div>
                            <div class="card2 ">
                                <img class="cardImage" src="../../../assets/images/vector-smart-object_similac.png">
                                <p>Không chứa các thành phần biến đổi gen
                                    (Non-GMO).</p>
                            </div>
                        </div>
                        <p class="text-justify"><strong>{{'SIMILAC_ALLIMENTUM.INGREDIENTS.STRONG' | translate
                                }}</strong>{{'SIMILAC_ALLIMENTUM.INGREDIENTS.DESC' | translate }}</p>
                        <figure>
                            <img src="../../../assets/images/layer-700.png">
                        </figure>
                        <div class="userManual">
                            <h4>{{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.TITLE' | translate }}</h4>
                            <p class="text-justify">{{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.DESC1' | translate }}<strong>{{
                                    'SIMILAC_ALLIMENTUM.USER_MANUAL.STRONG' | translate }}</strong><span><img
                                        class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"></span>
                                {{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.DESC2' | translate }}
                            </p>

                            <div class="steps">
                                <div class="container">
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object_A01.png">
                                        </div>
                                        <div class="col-8 p-0">
                                            <p> {{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STEP1' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object_A02.png">
                                        </div>
                                        <div class="col-8 p-0">
                                            <p> {{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STEP2' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center ">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object_A03.png">
                                        </div>
                                        <div class="col-8 p-0">
                                            <p> {{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STEP3' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object_A04.png">
                                        </div>
                                        <div class="col-8 p-0">
                                            <p> {{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STEP4' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object_A05.png">
                                        </div>
                                        <div class="col-8 p-0">
                                            <p> {{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STEP5' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object_A06.png">
                                        </div>
                                        <div class="col-8 p-0">
                                            <p class="mb-0"> {{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STEP6' | translate
                                                }}<strong>{{
                                                    'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STEP6_STRONG' | translate
                                                    }}</strong>
                                                <span><img class="iqPlusLogo"
                                                        src="../../../assets/images/logo-iqplus.png"></span>
                                                {{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STEP6_SUB' | translate }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object_A-7.png">

                                        </div>
                                        <div class="col-8 p-0">
                                            <p> {{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STEP7' | translate }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mt20 align-items-center">
                                        <div class="col-4">
                                            <img src="../../../assets/images/vector-smart-object_A08.png">
                                        </div>
                                        <div class="col-8 p-0">
                                            <p> {{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STEP8' | translate }}</p>
                                        </div>
                                    </div>

                                    <p class="mt20"><strong>{{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STORAGE' |
                                            translate }}</strong>{{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.STORAGE_TEXT'
                                        | translate }}</p>
                                    <p class="mt20"><strong>{{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.CAUTION' |
                                            translate }}</strong>{{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.CAUTION_TEXT'
                                        | translate }}</p>
                                    <p class="mt20"><strong>{{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.NOTE' | translate
                                            }}</strong>{{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.NOTE_TEXT1' | translate
                                        }} </p>
                                    <p class="mt20">{{ 'SIMILAC_ALLIMENTUM.USER_MANUAL.STEPS.NOTE_TEXT2' | translate }}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <figure>
                            <img src="../../../assets/images/table_allimentum.png">
                        </figure>

                    </div>
                </div>
            </div>
        </section>
        <!-- similac Alimentum Ends -->


        <!-- Similac Total Comfort 1 starts -->
        <section *ngIf="subCatCode == '2013' && milkCode == '1'">
            <section class="similacTotalComfortOne">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="titleContainer">
                                <p>{{ 'SIMILAC_TOTALCOMFORT_ONE.TITLE' | translate }}</p>
                                <h4>Similac Total Comfort 1</h4>
                                <figure>
                                    <img class="productImage" src="../../../assets/images/similaconeproductimage.png">
                                </figure>
                                <p class="textDarkBlue">{{ 'SIMILAC_TOTALCOMFORT_ONE.SUB_TITLE' | translate }}</p>
                            </div>
                            <div class="noteSection">
                                <p><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE_ONE.STRONG' | translate
                                        }}</strong> {{
                                    'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE_ONE.NOTE1' | translate }}</p>
                                <p class="mt10">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE_ONE.NOTE2' | translate }}
                                </p>
                            </div>

                            <p class="text-justify"><strong>{{ 'SIMILAC_TOTALCOMFORT_ONE.PRIMARY_PARAGRAPH_BOLD' |
                                    translate }} </strong> {{ 'SIMILAC_TOTALCOMFORT_ONE.PRIMARY_PARAGRAPH_TEXT' |
                                translate
                                }}</p>
                            <section class="text-center">
                                <img class="img-fluid totalComfortCommonImage"
                                    src="../../../assets/images/totlacomforcomfortcommommiddleimage.png">
                            </section>
                            <div class="similacBlueCard">
                                <div class="card2 text-justify">
                                    <img class="similacCardImage" src="../../../assets/images/simlilachmo.png">
                                    <p><strong> {{ 'SIMILAC_TOTALCOMFORT_ONE.SIMILAC_COMMON_TITLE.STRONG' | translate
                                            }}</strong>{{ 'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_ONE' | translate
                                        }}<sup>{{ 'SIMILAC_TOTALCOMFORT_ONE.CARD_TEXT_ONE' | translate }}</sup>{{
                                        'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_BETWEEN_BEFORE' | translate }}<sup>{{
                                            'SIMILAC_TOTALCOMFORT_ONE.CARD_TEXT_ONE' | translate }}</sup>{{
                                        'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_BETWEEN_AFTER' | translate }}<strong>{{
                                            'SIMILAC_TOTALCOMFORT_ONE.SIMILAC_COMMON_TITLE.STRONG' | translate
                                            }}</strong>
                                        {{ 'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_ONE_AFTER' | translate }}
                                    </p>
                                    <small class="textBlack"><sup>{{ 'SIMILAC_TOTALCOMFORT_ONE.CARD_TEXT_ONE' |
                                            translate }}</sup>{{ 'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_ONE_SMALL_TEXT' |
                                        translate }}</small>
                                </div>
                                <div class="card2 text-justify">
                                    <img class="similacCardImage" src="../../../assets/images/totalnumber10image.png">
                                    <p>
                                        {{ 'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_TWO_BEFORE' | translate }}
                                        <span><img class="totalPlusLogo"
                                                src="../../../assets/images/totaleqplus.png"></span>
                                        {{ 'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_TWO_AFTER' | translate }}
                                    </p>
                                </div>
                                <div class="card2 text-justify">
                                    <img class="similacCardImage"
                                        src="../../../assets/images/totlaeqplustypetwoimage.png">
                                    <p> {{ 'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_THREE' | translate }}</p>
                                </div>

                                <div class="card2 text-justify">
                                    <img class="similacCardImage" src="../../../assets/images/totaltummicareimage.png">
                                    <p> {{ 'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_FOUR_BEFORE' | translate
                                        }}<strong>{{ 'SIMILAC_TOTALCOMFORT_ONE.SIMILAC_ENTITY' | translate }}</strong>
                                        {{ 'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_FOUR_AFTER' | translate }}<sup>{{
                                            'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_FOUR_SYMBOL' | translate }}</sup>{{
                                        'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_FOUR_AFTER_SYMBOL' | translate }}
                                    </p>
                                </div>
                                <div class="card2 text-justify">
                                    <img class="similacCardImage"
                                        src="../../../assets/images/totalcomfortstrengthimage.png">
                                    <p> {{ 'SIMILAC_TOTALCOMFORT_ONE.BLUE_CARD_TEXT_FIVE' | translate }} </p>
                                </div>
                            </div>
                            <p class="text-justify"><strong>{{'SIMILAC_TOTALCOMFORT_ONE.INGREDIENTS.STRONG' | translate
                                    }}</strong>{{'SIMILAC_TOTALCOMFORT_ONE.INGREDIENTS.DESC_BEFORE' | translate }}<sup
                                    class="super">{{'SIMILAC_TOTALCOMFORT_ONE.INGREDIENTS.DESC_SYMBOL' | translate
                                    }}</sup>{{'SIMILAC_TOTALCOMFORT_ONE.INGREDIENTS.DESC' | translate }}</p>
                            <small class="d-block textBlack">
                                {{'SIMILAC_TOTALCOMFORT_ONE.INGREDIENTS.DESC_SMALL_ONE' | translate }}
                            </small>
                            <small class="d-block textBlack">
                                {{'SIMILAC_TOTALCOMFORT_ONE.INGREDIENTS.DESC_SMALL_TWO' | translate }}
                            </small>

                            <figure>
                                <img class="mt-4" src="../../../assets/images/ingredient-table.png">
                            </figure>
                            <div class="userManual">
                                <h4>{{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.TITLE' | translate }}</h4>
                                <p class="text-justify">{{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.DESC1' | translate }}
                                </p>

                                <p>
                                    {{'SIMILAC_TOTALCOMFORT_ONE.INGREDIENTS.DESC_TWO' | translate }}
                                </p>
                                <div class="steps">
                                    <div class="container">
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/totalstepllistone.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STEP1' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object02.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STEP2' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center ">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object03.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STEP3' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object04.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STEP4' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object05.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STEP5' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object06.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p class="mb-0"> {{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STEP6' |
                                                    translate
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object07.png">

                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STEP7_ONE' |
                                                    translate }}
                                                    <strong>{{ 'SIMILAC_TOTALCOMFORT_ONE.SIMILAC_ENTITY' | translate
                                                        }}</strong>
                                                    {{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STEP7_TWO' |
                                                    translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object08.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STEP8' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object09.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STEP9' | translate }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="row mt20 align-items-center text-justify">
                                            <div class="col-12 pl-0 pr-0">
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STORAGE' | translate
                                                        }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.STORAGE_TEXT'
                                                    | translate }}</p>
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.CAUTION'
                                                        |
                                                        translate }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.CAUTION_TEXT'
                                                    | translate }}</p>
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.NOTE' |
                                                        translate
                                                        }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.NOTE_TEXT1' |
                                                    translate
                                                    }} </p>
                                                <figure>
                                                    <img class="mt-4 img-fuid w-100"
                                                        src="../../../assets/images/bottontabletotal1.png">

                                                    <small class="d-block mt-3">
                                                        <sup>
                                                            {{'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.TABLE_SMALL_DESC_ONE_SYMBOL'
                                                            | translate }} </sup>
                                                        {{
                                                        'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.TABLE_SMALL_DESC_ONE'
                                                        | translate }}</small>
                                                    <small class="d-block mt20">
                                                        {{
                                                        'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.TABLE_SMALL_DESC_TWO'
                                                        |
                                                        translate }}
                                                    </small>

                                                </figure>

                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.NOTE_TEXT3_BOLD' |
                                                        translate }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.NOTE_TEXT3' | translate
                                                    }} </p>
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.NOTE_TEXT4_BOLD' |
                                                        translate }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.NOTE_TEXT4' | translate
                                                    }} </p>

                                                <p class="mt20 mb-0"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.NOTE_TEXT5_BOLD' |
                                                        translate }}</strong></p>
                                                <p class="mt-0">{{
                                                    'SIMILAC_TOTALCOMFORT_ONE.USER_MANUAL.STEPS.NOTE_TEXT5' | translate
                                                    }} </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <!-- Similac Total Comfort 1 Ends -->

        <!-- Similac total comfort one plus starts -->
        <section *ngIf="subCatCode == '2006' && milkCode == '1'">
            <section class="similacTotalComfortOne">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="titleContainer">
                                <p>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.TITLE' | translate }}</p>
                                <h4>Similac Total Comfort 1+</h4>
                                <figure>
                                    <img class="productImage"
                                        src="../../../assets/images/similaconeplusproductimage.png">
                                </figure>
                                <p class="mb-0">{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.SUB_TITLE' | translate }}</p>
                                <p class="">{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.SUB_TITLE1' | translate }}</p>
                            </div>
                            <div class="noteSection">
                                <p><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE_ONE.STRONG' | translate
                                        }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE_ONE.NOTE1' | translate
                                    }}</p>
                                <p class="mt10">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE_ONE.NOTE2' | translate }}
                                </p>
                            </div>

                            <p class="text-justify"><strong>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.PRIMARY_PARAGRAPH_BOLD' |
                                    translate }} </strong> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.PRIMARY_PARAGRAPH_TEXT' |
                                translate
                                }}</p>
                            <section class="text-center">
                                <img class="img-fluid totalComfortCommonImage"
                                    src="../../../assets/images/totalcomfortoneplusmiddleimage.png">
                            </section>
                            <div class="similacBlueCard">
                                <div class="card2 text-justify">
                                    <img class="similacCardImage" src="../../../assets/images/simlilachmo.png">
                                    <p><strong> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.SIMILAC_COMMON_TITLE.STRONG' |
                                            translate }}
                                        </strong>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_TEXT_ONE_BEFORE' |
                                        translate }}<sup>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_SYMBOL' | translate
                                            }}</sup>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_TEXT_AFTER_SYMBOL' |
                                        translate }}<sup>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_SYMBOL' | translate
                                            }}</sup>
                                        {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_TEXT_ONE' | translate }}
                                        <strong>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.SIMILAC_COMMON_TITLE.STRONG' |
                                            translate }}
                                        </strong>
                                        {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_TEXT_ONE_AFTER' | translate }}
                                    </p>
                                    <small class="textBlack"><sup>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_SYMBOL' |
                                            translate }}</sup>{{
                                        'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_ONE_SMALL_TEXT' | translate
                                        }}</small>
                                </div>
                                <div class="card2 text-justify">
                                    <img class="similacCardImage" src="../../../assets/images/totalnumber10image.png">
                                    <p>
                                        {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_TEXT_TWO_BEFORE' | translate }}
                                        <span><img class="totalPlusLogo"
                                                src="../../../assets/images/totaleqplus.png"></span>
                                        {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_TEXT_TWO_AFTER' | translate }}

                                    </p>
                                </div>
                                <div class="card2 text-justify">
                                    <img class="similacCardImage"
                                        src="../../../assets/images/totlaeqplustypetwoimage.png">
                                    <p> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_TEXT_THREE' | translate }}</p>
                                </div>

                                <div class="card2 text-justify">
                                    <img class="similacCardImage" src="../../../assets/images/totaltummicareimage.png">
                                    <p> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_TEXT_FOUR_BEFORE' | translate
                                        }}<strong> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.SIMILAC_ENTITY' | translate
                                            }}</strong>
                                        {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_TEXT_FOUR_AFTER' | translate }}<sup
                                            class="">{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.INGREDIENTS.DESC_SYMBOL' |
                                            translate }}
                                        </sup>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_TEXT_FOUR_AFTER_ONE' |
                                        translate }}
                                    </p>
                                </div>
                                <div class="card2 text-justify">
                                    <img class="similacCardImage"
                                        src="../../../assets/images/totalcomfortstrengthimage.png">
                                    <p> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_TEXT_FIVE' | translate }} </p>
                                </div>
                            </div>
                            <p class="text-justify"><strong>{{'SIMILAC_TOTALCOMFORT_ONE_PLUS.INGREDIENTS.STRONG' |
                                    translate
                                    }}</strong>
                                {{'SIMILAC_TOTALCOMFORT_ONE_PLUS.INGREDIENTS.DESC_BEFORE' | translate }}<sup
                                    class="super">{{'SIMILAC_TOTALCOMFORT_ONE_PLUS.INGREDIENTS.DESC_SYMBOL' | translate
                                    }}</sup>{{'SIMILAC_TOTALCOMFORT_ONE_PLUS.INGREDIENTS.DESC' | translate }}</p>
                            <small class="d-block">
                                {{'SIMILAC_TOTALCOMFORT_ONE_PLUS.INGREDIENTS.DESC_SMALL_ONE' | translate }}
                            </small>
                            <small class="d-block">
                                {{'SIMILAC_TOTALCOMFORT_ONE_PLUS.INGREDIENTS.DESC_SMALL_TWO' | translate }}
                            </small>

                            <figure>
                                <img class="mt-4" src="../../../assets/images/ingredient-table.png">
                            </figure>
                            <div class="userManual">
                                <h4>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.TITLE' | translate }}</h4>
                                <p class="text-justify">{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.DESC1_BEFORE' |
                                    translate
                                    }}{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.DESC1' | translate }}
                                </p>

                                <p>
                                    {{'SIMILAC_TOTALCOMFORT_ONE_PLUS.INGREDIENTS.DESC_TWO' | translate }}
                                </p>
                                <div class="steps">
                                    <div class="container">
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/totalstepllistone.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STEP1' |
                                                    translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object02.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STEP2' |
                                                    translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center ">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object03.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STEP3' |
                                                    translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object04.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STEP4' |
                                                    translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object05.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STEP5' |
                                                    translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object06.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p class="mb-0"> {{
                                                    'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STEP6' |
                                                    translate
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object07.png">

                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STEP7_ONE' |
                                                    translate }}
                                                    <strong>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.SIMILAC_ENTITY' |
                                                        translate
                                                        }}</strong>
                                                    {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STEP7_TWO' |
                                                    translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object08.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STEP8' |
                                                    translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt20 align-items-center">
                                            <div class="col-4 pt-2 pr-2 pl-0 pb-2">
                                                <img src="../../../assets/images/vector-smart-object09.png">
                                            </div>
                                            <div class="col-8 p-0">
                                                <p> {{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STEP9' |
                                                    translate }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="row mt20 align-items-center text-justify">
                                            <div class="col-12 pl-0">
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STORAGE' |
                                                        translate
                                                        }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.STORAGE_TEXT'
                                                    | translate }}</p>
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.CAUTION'
                                                        |
                                                        translate }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.CAUTION_TEXT'
                                                    | translate }}</p>
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.NOTE' |
                                                        translate
                                                        }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.NOTE_TEXT1' |
                                                    translate
                                                    }} </p>
                                                <!-- <p class="mt20">{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.NOTE_TEXT2' |
                                                translate }}
                                            </p> -->
                                                <figure>
                                                    <img class="mt-4 img-fuid w-100"
                                                        src="../../../assets/images/oneplustable bottom image.png">

                                                    <small class="d-block mt-3">{{
                                                        'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.TABLE_SMALL_DESC_ONE'
                                                        |
                                                        translate }}</small>
                                                </figure>

                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.NOTE_TEXT3_BOLD'
                                                        |
                                                        translate }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.NOTE_TEXT3' |
                                                    translate }} </p>
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.NOTE_TEXT4_BOLD'
                                                        |
                                                        translate }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.NOTE_TEXT4' |
                                                    translate }} </p>

                                                <p class="mt20 mb-0"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.NOTE_TEXT5_BOLD'
                                                        |
                                                        translate }}</strong></p>
                                                <p class="mt-0">{{
                                                    'SIMILAC_TOTALCOMFORT_ONE_PLUS.USER_MANUAL.STEPS.NOTE_TEXT5' |
                                                    translate }} </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <!-- Similac total comfort one plus ends-->


        <!-- similac two plus starts  -->
        <section *ngIf="subCatCode == '2004' && milkCode == '0'">
            <section class="similacTotalComfortOne">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="titleContainer">
                                <p>{{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.TITLE' | translate }}</p>
                                <h4>Similac Total Comfort 2+</h4>
                                <figure>
                                    <img class="productImage"
                                        src="../../../assets/images/similactwopluspeoductimage.png">
                                </figure>
                                <p class="mb-0">{{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.SUB_TITLE' | translate }}</p>
                            </div>
                            <div class="noteSection">
                                <p><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE_ONE.STRONG' | translate
                                        }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE_ONE.NOTE1' | translate
                                    }}</p>
                                <p class="mt10">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE_ONE.NOTE2' | translate }}
                                </p>
                            </div>

                            <p class="text-justify"><strong>{{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.PRIMARY_PARAGRAPH_BOLD' |
                                    translate }} </strong> {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.PRIMARY_PARAGRAPH_TEXT' |
                                translate
                                }}</p>
                            <section class="text-center">
                                <img class="img-fluid totalComfortCommonImage"
                                    src="../../../assets/images/twoplusmiddleimage.png">
                            </section>
                            <div class="similacBlueCard">
                                <div class="card2 text-justify">
                                    <img class="similacCardImage" src="../../../assets/images/simlilachmo.png">
                                    <p><strong> {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.SIMILAC_COMMON_TITLE.STRONG' |
                                            translate }}
                                        </strong>
                                        {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_TEXT_ONE_BEFORE' | translate
                                        }}<sup>{{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_SYMBOL' | translate }}</sup>
                                        {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_TEXT_SYMBOL_AFTER' | translate
                                        }}<sup>{{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_SYMBOL' | translate }}</sup>
                                        {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_TEXT_ONE' | translate }}


                                        <strong>{{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.SIMILAC_COMMON_TITLE.STRONG' |
                                            translate }}
                                        </strong>
                                        {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_TEXT_ONE_AFTER' | translate }}
                                    </p>
                                    <small>
                                        <sup>{{ 'SIMILAC_TOTALCOMFORT_ONE_PLUS.BLUE_CARD_SYMBOL' |
                                            translate }}</sup>{{
                                        'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_ONE_SMALL_TEXT' | translate }}</small>
                                </div>
                                <div class="card2 text-justify">
                                    <img class="similacCardImage" src="../../../assets/images/totalnumber10image.png">
                                    <!-- <p>Không chứa Lactose. Phù hợp cho trẻ bất dung nạp Lactose. Tham khảo thêm ý kiến
                                        của
                                        bác sỹ.</p> -->
                                    <p>
                                        {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_TEXT_TWO_BEFORE' | translate }}
                                        <span><img class="totalPlusLogo"
                                                src="../../../assets/images/totaleqplus.png"></span>
                                        {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_TEXT_TWO_AFTER' | translate }}

                                    </p>
                                </div>
                                <div class="card2 text-justify">
                                    <img class="similacCardImage"
                                        src="../../../assets/images/totlaeqplustypetwoimage.png">
                                    <p> {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_TEXT_THREE' | translate }}</p>
                                </div>

                                <div class="card2 text-justify">
                                    <img class="similacCardImage" src="../../../assets/images/totaltummicareimage.png">
                                    <p> {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_TEXT_FOUR_BEFORE' | translate
                                        }}<strong> {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.SIMILAC_ENTITY' | translate
                                            }}</strong>
                                        {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_TEXT_FOUR_AFTER' | translate }}
                                        <sup>{{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_SYMBOL_PLUS' | translate
                                            }}</sup>
                                        {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_TEXT_FOUR_AFTER_SYMBOL' | translate
                                        }}
                                    </p>
                                </div>
                                <div class="card2 text-justify">
                                    <img class="similacCardImage"
                                        src="../../../assets/images/totalcomfortstrengthimage.png">
                                    <p> {{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.BLUE_CARD_TEXT_FIVE' | translate }} </p>
                                </div>
                            </div>
                            <p class="text-justify"><strong>{{'SIMILAC_TOTALCOMFORT_TWO_PLUS.INGREDIENTS.STRONG' |
                                    translate }}</strong>{{'SIMILAC_TOTALCOMFORT_TWO_PLUS.INGREDIENTS.DESC_BEFORE' |
                                translate }}<sup class="super">{{'SIMILAC_TOTALCOMFORT_TWO_PLUS.INGREDIENTS.DESC_SYMBOL'
                                    | translate }}</sup>{{'SIMILAC_TOTALCOMFORT_TWO_PLUS.INGREDIENTS.DESC' | translate
                                }}</p>
                            <small class="d-block">
                                {{'SIMILAC_TOTALCOMFORT_TWO_PLUS.INGREDIENTS.DESC_SMALL_ONE' | translate }}
                            </small>
                            <small class="d-block">
                                {{'SIMILAC_TOTALCOMFORT_TWO_PLUS.INGREDIENTS.DESC_SMALL_TWO' | translate }}
                            </small>

                            <figure>
                                <img class="mt-4" src="../../../assets/images/ingredient-table.png">
                            </figure>
                            <div class="userManual">
                                <h4>{{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.TITLE' | translate }}</h4>
                                <p class="text-justify"><strong>{{
                                        'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.DESC1_BOLD' | translate }}</strong>{{
                                    'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.DESC1' | translate }}
                                </p>

                                <p>
                                    {{'SIMILAC_TOTALCOMFORT_TWO_PLUS.INGREDIENTS.DESC_TWO' | translate }}
                                </p>
                                <div class="steps">
                                    <div class="container">
                                        <div class="row mt20 align-items-center text-justify">
                                            <div class="col-12 pl-0">
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.STORAGE' |
                                                        translate
                                                        }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.STORAGE_TEXT'
                                                    | translate }}</p>
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.CAUTION'
                                                        |
                                                        translate }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.CAUTION_TEXT'
                                                    | translate }}</p>
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.NOTE' |
                                                        translate
                                                        }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.NOTE_TEXT1' |
                                                    translate
                                                    }} </p>
                                                <!-- <p class="mt20">{{ 'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.NOTE_TEXT2' |
                                                translate }}
                                            </p> -->
                                                <figure>
                                                    <img class="mt-4 img-fuid w-100"
                                                        src="../../../assets/images/bottomtabletwoplus.png">
                                                </figure>

                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.NOTE_TEXT3_BOLD'
                                                        |
                                                        translate }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.NOTE_TEXT3' |
                                                    translate }} </p>
                                                <p class="mt20"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.NOTE_TEXT4_BOLD'
                                                        |
                                                        translate }}</strong>{{
                                                    'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.NOTE_TEXT4' |
                                                    translate }} </p>

                                                <p class="mt20 mb-0"><strong>{{
                                                        'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.NOTE_TEXT5_BOLD'
                                                        |
                                                        translate }}</strong></p>
                                                <p class="mt-0">{{
                                                    'SIMILAC_TOTALCOMFORT_TWO_PLUS.USER_MANUAL.STEPS.NOTE_TEXT5' |
                                                    translate }} </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <!-- similac two plus ends here -->
    </section>


    <!--Product-Non-milkcode-Similac-EyeQ4-expand Starts-->
    <section class="similacInnerSectionOut" *ngIf="subCatCode == '2002' && milkCode == '0'">
        <section class="similacVannilaOut">
            <img class="similacMomLogo" src="../../../assets/images/nonmilkcodesimilaceyeplus4.png" />
            <p>{{ 'PRODUCT_NON_MILK_CODE.TOP_TITLE1' | translate }}</p>
            <p class="mt-0">{{ 'PRODUCT_NON_MILK_CODE.TOP_TITLE2' | translate }}</p>
            <p class="mt-0">{{ 'PRODUCT_NON_MILK_CODE.TOP_TITLE3' | translate }}</p>
            <p class="mt-0">{{ 'PRODUCT_NON_MILK_CODE.TOP_TITLE4' | translate }}</p>
            <img class="nonMilkCodeProductImage" src="../../../assets/images/nonmilkcode4productimage.png" />
            <p class="productDescriptionSecondary mt-4">{{ 'PRODUCT_NON_MILK_CODE.PRODUCT_DESC' | translate }}</p>
            <section class="similiacStepCircleBlockOut">
                <div class="circleBlock">
                    <p>1</p>
                </div>
                <h4 class="titleTopBold"> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_TOP_TITLE_ONE_LEFT' | translate }} <img
                        src="../../../assets/images/logo-iqplus.png" class="iqPlusLogo" /> {{
                    'PRODUCT_NON_MILK_CODE.BLOCK_ONE_TOP_TITLE_ONE' | translate }}</h4>
                <h5 class="titleTopBold"> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_TOP_TITLE_TWO' | translate }}</h5>
                <h5 class="titleTopBold"> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_TOP_TITLE_THREE' | translate }}</h5>
                <div class="bisqueBackroundBlock orangeBlock">
                    <img class="topCenteredImage" src="../../../assets/images/layer-36-copy.png">
                    <p class="cardTextWhite mt-4">{{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_CARD_TEXT_LINE_ONE' | translate }}
                    </p>
                    <p class="cardTextWhite">{{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_CARD_TEXT_LINE_TWO' | translate }}</p>
                    <p class="cardTextWhite">{{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_CARD_TEXT_LINE_THREE' | translate }}
                        <sup class="boxWithNumbers">
                            {{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_SMALL_TEXT' | translate }}
                        </sup>
                    </p>
                </div>
                <img class="img-fluid w-100 my-4" src="../../../assets/images/dhawa.png" />
                <p class="textBlack">{{ 'PRODUCT_NON_MILK_CODE.DHAWA_BOTTOM_TEXT' | translate }}</p>
                <img class="img-fluid w-100 my-4" src="../../../assets/images/group-30.png" />
                <p class="descriptionBottomTitle">{{ 'PRODUCT_NON_MILK_CODE.OMEGA_BOTTOM_DESC' | translate }}</p>
                <p class="descriptionBottomTitle mt-0">{{ 'PRODUCT_NON_MILK_CODE.OMEGA_BOTTOM_DESC_TWO' | translate }}
                </p>
                <p class="textBlack text-left">{{ 'PRODUCT_NON_MILK_CODE.DESC_CONTENT_ONE_BEFORE' | translate }}
                    <sup>{{ 'PRODUCT_NON_MILK_CODE.DESC_CONTENT_NUMBER_ONE' | translate }}</sup>
                    {{ 'PRODUCT_NON_MILK_CODE.DESC_CONTENT_ONE_AFTER' | translate }}
                    <sup> {{ 'PRODUCT_NON_MILK_CODE.DESC_CONTENT_NUMBER_TWO' | translate }}</sup>
                    {{ 'PRODUCT_NON_MILK_CODE.DESC_CONTENT_TWO' | translate }}
                </p>
                <div class="bisqueBackroundBlock">
                    <div class="row align-items-center">
                        <div class="col-2">
                            <img class="ellipseImageTwo" src="../../../assets/images/ellipse-525-copy-9.png">
                        </div>
                        <div class="col-10 text-left pl-0">
                            <p class="textBlack">{{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_LIST_CONTENT_ONE' | translate}}
                            </p>
                        </div>
                        <div class="col-2">
                            <img class="ellipseImageTwo" src="../../../assets/images/ellipse-525-copy-9.png">
                        </div>
                        <div class="col-10 text-left pl-0">
                            <p class="textBlack">{{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_LIST_CONTENT_TWO' | translate}}
                            </p>
                        </div>
                        <div class="col-2">
                            <img class="ellipseImageTwo" src="../../../assets/images/ellipse-525-copy-9.png">
                        </div>
                        <div class="col-10 text-left pl-0">
                            <p class="textBlack">{{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_LIST_CONTENT_THREE' | translate}}
                            </p>
                        </div>
                        <div class="col-2">
                            <img class="ellipseImageTwo" src="../../../assets/images/ellipse-525-copy-9.png">
                        </div>
                        <div class="col-10 text-left pl-0">
                            <p class="textBlack">{{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_LIST_CONTENT_FOUR' | translate}}
                            </p>
                        </div>
                        <div class="col-2">
                            <img class="ellipseImageTwo" src="../../../assets/images/ellipse-525-copy-9.png">
                        </div>
                        <div class="col-10 text-left pl-0">
                            <p class="textBlack">{{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_LIST_CONTENT_FIVE' | translate}}
                            </p>
                        </div>
                    </div>
                </div>
                <img class="img-fluid w-100 my-4" src="../../../assets/images/lutien.png" />
                <p class="textBlack text-left"> Similac <img src="../../../assets/images/logo-iqplus.png"
                        class="iqPlusLogo" />{{ 'PRODUCT_NON_MILK_CODE.LUITEN_CONTENT_BOTTOM' | translate}} <small> {{
                        'PRODUCT_NON_MILK_CODE.NUMBERS_CONTENT' | translate}} </small></p>
                <img class="img-fluid learningMemoryImg my-4" src="../../../assets/images/learningmemory.png" />
                <div class="bisqueBackroundBlock mt-3">
                    <p class="textBlack"> {{ 'PRODUCT_NON_MILK_CODE.CARD_TWO_TOP_TEXT' | translate}}</p>
                    <div class="row align-items-center">
                        <div class="col-2">
                            <img class="leftImage" src="../../../assets/images/flash.png">
                        </div>
                        <div class="col-10">
                            <p class="textBlack text-left"> {{ 'PRODUCT_NON_MILK_CODE.CARD_TWO_LIST_TEXT_ONE' |
                                translate}} <small> {{
                                    'PRODUCT_NON_MILK_CODE.CARD_TWO_NUMBERS' | translate}} </small> </p>
                        </div>
                        <div class="col-2">
                            <img class="leftImage" src="../../../assets/images/002-light-bulb.png">
                        </div>
                        <div class="col-10">
                            <p class="textBlack text-left"> {{ 'PRODUCT_NON_MILK_CODE.CARD_TWO_LIST_TEXT_TWO' |
                                translate}} <small> {{
                                    'PRODUCT_NON_MILK_CODE.CARD_TWO_NUMBER_TYPE_TWO' | translate}} </small> </p>
                        </div>
                        <div class="col-2">
                            <img class="leftImage" src="../../../assets/images/003-graduate-cap.png">
                        </div>
                        <div class="col-10">
                            <p class="textBlack text-left"> {{ 'PRODUCT_NON_MILK_CODE.CARD_TWO_LIST_TEXT_THREE' |
                                translate}} <small> {{
                                    'PRODUCT_NON_MILK_CODE.CARD_TWO_NUMBER_TYPE_THREE' | translate}} </small> </p>
                        </div>
                    </div>
                </div>
                <img class="img-fluid w-100 my-4" src="../../../assets/images/vitaminimage.png">
                <p class="textBlack text-left">{{ 'PRODUCT_NON_MILK_CODE.VITAMIN_TEXT' | translate}}</p>
                <div class="row align-items-center">
                    <div class="col-6 pr-0">
                        <div class="bisqueBackroundBlock text-left">
                            <small class="textBlack text-left">
                                {{ 'PRODUCT_NON_MILK_CODE.VITAMIN_TEXT_LEFT_ONE_BEFORE' | translate }}
                                <sup> {{ 'PRODUCT_NON_MILK_CODE.VITAMIN_TEXT_NUMBER_ONE' | translate }} </sup>
                                {{ 'PRODUCT_NON_MILK_CODE.VITAMIN_TEXT_LEFT_ONE_AFTER' | translate }}
                                <sup>
                                    {{ 'PRODUCT_NON_MILK_CODE.VITAMIN_TEXT_NUMBER_TWO' | translate }}
                                </sup>

                            </small>
                        </div>
                    </div>
                    <div class="col-6">
                        <img class="img-fluid mt-5" src="../../../assets/images/group-22.png" />
                    </div>
                </div>
                <img class="img-fluid w-100 my-4" src="../../../assets/images/productdhaimage.png" />
                <img class="img-fluid w-100 my-4" src="../../../assets/images/hmo.png" />
                <p class="text-left textBlack">{{ 'PRODUCT_NON_MILK_CODE.HMO_BOTTOM_TEXT_ONE_LEFT' | translate }} <img
                        src="../../../assets/images/logo-iqplus.png" class="iqPlusLogo" />{{
                    'PRODUCT_NON_MILK_CODE.HMO_BOTTOM_TEXT_ONE' | translate }}</p>
                <div class="text-left">
                    <small class="text-left textBlack"> {{ 'PRODUCT_NON_MILK_CODE.HMO_BOTTOM_TEXT_SMALL' | translate
                        }}</small>
                </div>
                <p class="text-left textBlack"> {{ 'PRODUCT_NON_MILK_CODE.HMO_BOTTOM_TEXT_THREE' | translate }}</p>
                <img class="img-fluid my-3" src="../../../assets/images/productchartimage.png" />
                <div class="bisqueBackroundBlock text-center mt-3">
                    <p class="textBlack mb=0 bolText">{{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_BOTTOM_CARD_TEXT' | translate
                        }}</p>
                    <p class="textBlack mt-0 bolText">{{ 'PRODUCT_NON_MILK_CODE.BLOCK_ONE_BOTTOM_CARD_TEXT_TWO' |
                        translate }}
                    </p>
                    <h4 class="textBlack mt-2 mb-0 text-uppercase boldBlackText">{{
                        'PRODUCT_NON_MILK_CODE.BLOCK_ONE_BOTTOM_CARD_TEXT_MIDDLE' | translate }}</h4>
                    <p class="textBlack mt-0 bolText text-uppercase">{{
                        'PRODUCT_NON_MILK_CODE.BLOCK_ONE_BOTTOM_CARD_TEXT_THREE' | translate }} <small> {{
                            'PRODUCT_NON_MILK_CODE.BLOCK_ONE_BOTTOM_CARD_TEXT_THREE_NUMBER' | translate}} </small></p>
                </div>
            </section>
            <section class="similiacStepCircleBlockOut">
                <div class="circleBlock">
                    <p>2</p>
                </div>
                <h5 class="titleTopBold"> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_TWO_TOP_TEXT_ONE_LEFT' | translate }} <img
                        src="../../../assets/images/logo-iqplus.png" class="iqPlusLogo" /> {{
                    'PRODUCT_NON_MILK_CODE.BLOCK_TWO_TOP_TEXT_ONE' | translate }}</h5>
                <h5 class="titleTopBold"> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_TWO_TOP_TEXT_TWO' | translate }}</h5>

                <p class="textBlack text-justify"> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_TWO_TOP_CONTENT' | translate }}</p>
                <div class="row justify-content-center mt-3">
                    <div class="col-4">
                        <img class="img-fluid mt-2" src="../../../assets/images/nucleotides.png" />
                    </div>
                    <div class="col-4">
                        <img class="img-fluid" src="../../../assets/images/hmosuclogo.png" />
                    </div>
                </div>
                <img class="img-fluid my-4" src="../../../assets/images/hmonucleotides.png" />
                <p class="textBlack text-left"> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_TWO_LIST_HEADING_ONE' | translate }}</p>
                <div class="row align-items-center text-left">
                    <div class="col-2 pr-0">
                        <img class="ellipseImageThree" src="../../../assets/images/ellipse-525-copy-9.png">
                    </div>
                    <div class="col-10 pl-0">
                        <p class="textBlack">2’FL HMO <small>[22] </small>và FOS hỗ trợ các vi khuẩn có lợi trong hệ
                            tiêu hoá của trẻ phát triển giúp tăng cường sức đề kháng.</p>
                    </div>
                    <div class="col-2 pr-0">
                        <img class="ellipseImageThree" src="../../../assets/images/ellipse-525-copy-9.png">
                    </div>
                    <div class="col-10 pl-0">
                        <p class="textBlack">Nucleotides hỗ trợ khả năng miễn dịch mạnh mẽ hơn
                            <small>(*),[23],[24]</small>
                        </p>
                    </div>
                    <div class="col">
                        <small>*So với nhóm đối chứng không có HMO hoặc không được tăng cường Nucleotides</small>
                    </div>
                </div>
            </section>
            <section class="similiacStepCircleBlockOut">
                <div class="circleBlock">
                    <p>3</p>
                </div>
                <h5 class="titleTopBold"> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_THREE_TOP_TITLE_ONE_LEFT' | translate }} <img
                        src="../../../assets/images/logo-iqplus.png" class="iqPlusLogo" /> {{
                    'PRODUCT_NON_MILK_CODE.BLOCK_THREE_TOP_TITLE_ONE' | translate }}</h5>
                <h5 class="titleTopBold"> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_THREE_TOP_TITLE_TWO' | translate }}</h5>
                <div class="row">
                    <div class="col-3">
                        <img class="img-fluid mt-3" src="../../../assets/images/leverheartimage.png" />
                    </div>
                    <div class="col-9 text-left">
                        <small class="textBlack"> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_THREE_LEVER_TEXT' | translate }}
                            <small>
                                {{ 'PRODUCT_NON_MILK_CODE.BLOCK_THREE_LEVER_TEXT_NUMBER' | translate }}</small></small>
                    </div>
                </div>
                <img class="img-fluid my-4" src="../../../assets/images/prebioticimage.png" />
                <ul class="listImagesBlock">
                    <li>
                        <img class="img-fluid" src="../../../assets/images/hmotwofl.png" />
                    </li>
                    <li>
                        <img class="img-fluid" src="../../../assets/images/no-palm-oil.png" />

                    </li>
                    <li>
                        <img class="img-fluid" src="../../../assets/images/nucleotidestype2.png" />
                    </li>
                    <li>
                        <img class="img-fluid" src="../../../assets/images/prebioticstype2.png" />
                    </li>
                </ul>
                <p class="textBlack text-left"> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_TWO_LIST_HEADING_ONE' | translate }}</p>
                <div class="row align-items-center text-left">
                    <div class="col-2 pr-0">
                        <img class="ellipseImageThree" src="../../../assets/images/ellipse-525-copy-9.png">
                    </div>
                    <div class="col-10 pl-0">
                        <p class="textBlack">
                            {{ 'PRODUCT_NON_MILK_CODE.BLOCK_THREE_LIST_CONTENT_ONE' | translate }} </p>
                    </div>

                    <div class="col-2 pr-0">
                        <img class="ellipseImageThree" src="../../../assets/images/ellipse-525-copy-9.png">
                    </div>
                    <div class="col-10 pl-0">
                        <p class="textBlack">
                            {{ 'PRODUCT_NON_MILK_CODE.BLOCK_THREE_LIST_CONTENT_TWO' | translate }} <small> {{
                                'PRODUCT_NON_MILK_CODE.BLOCK_THREE_LIST_CONTENT_TWO_NUMBER' | translate }}</small>
                        </p>
                        <small> {{ 'PRODUCT_NON_MILK_CODE.BLOCK_THREE_LIST_CONTENT_SMALL' | translate }} </small>
                    </div>
                </div>
                <img class="img-fluid my-4" src="../../../assets/images/support-fact-3.png" />
                <div class="shapeCurveBlock">
                    <h5 class="titleTopBold mt-5">{{ 'PRODUCT_NON_MILK_CODE.BLOCK_THREE_SHAPE_CONTENT_LEFT' | translate
                        }}<img src="../../../assets/images/logo-iqplus.png" class="iqPlusLogo" /> {{
                        'PRODUCT_NON_MILK_CODE.BLOCK_THREE_SHAPE_CONTENT' | translate }}
                    </h5>
                    <h5 class="titleTopBold">{{
                        'PRODUCT_NON_MILK_CODE.BLOCK_THREE_SHAPE_CONTENT_TWO' | translate }}
                    </h5>
                    <h5 class="titleTopBold">{{
                        'PRODUCT_NON_MILK_CODE.BLOCK_THREE_SHAPE_CONTENT_THREE' | translate }}
                    </h5>
                    <h5 class="titleTopBold">{{
                        'PRODUCT_NON_MILK_CODE.BLOCK_THREE_SHAPE_CONTENT_FOUR' | translate }}
                    </h5>
                    <h5 class="titleTopBold">{{
                        'PRODUCT_NON_MILK_CODE.BLOCK_THREE_SHAPE_CONTENT_FIVE' | translate }}
                    </h5>
                    <img class="img-fluid support4image pb-4" src="../../../assets/images/support-fact-4.png">
                </div>

                <p class="descriptionBottomTitle mt-4" data-toggle="collapse" data-target="#collapseExample"
                    aria-expanded="false" aria-controls="collapseExample" (click)="showArrowIcons()">{{
                    'PRODUCT_NON_MILK_CODE.BLOCK_THREE_BOTTOM_CONTENT' | translate }}
                    <img class="img-fluid arrowupImg" src="../../../assets/images/move-to-next.png"
                        data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                        aria-controls="collapseExample" *ngIf="showArrowFlag" />
                    <img class="img-fluid arrowDownImg" src="../../../assets/images/move-to-next.png"
                        data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                        aria-controls="collapseExample" *ngIf="!showArrowFlag" />
                </p>
                <div class="collapse" id="collapseExample">

                    <p class="textBlack text-left">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_1' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_2' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_3' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_4' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_5' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_6' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_7' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_8' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_10' | translate }}
                    </p>

                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_11' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_12' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_13' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_14' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_15' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_16' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_17' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_18' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_19' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_20' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_21' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_22' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_23' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_24' | translate }}
                    </p>
                    <p class="textBlack text-left mb-0 mt-0">
                        {{ 'PRODUCT_NON_MILK_CODE.BOTTOM_LIST_25' | translate }}
                    </p>
                </div>
            </section>
        </section>
    </section>
    <!--Product-Non-milkcode-Similac-EyeQ4-expand Ends-->


    <!-- Product-Non-Milkcode-Similac-Newborn starts -->
    <section class="similacInnerSectionOut p-0" *ngIf="subCatCode == '2007' && milkCode == '1'">
        <section class="similacInnerSectionOut">
            <section class="similacVannilaOut">
                <section class="similiacStepCircleBlockOut">
                    <img class="img-fluid" src="../../../assets/images/do-you-know.png" />

                    <section class="bisqueBackroundBlock secondaryBisqueBackground">
                        <img class="img-fluid centeredImageBig" src="../../../assets/images/babydrinkmilk.png" />
                        <p class="textBlack text-justify"> {{ 'NEW_BORN_EYEQ1.CARD_TITLE_BEFORE' | translate }} <span
                                class="boldText"> {{ 'NEW_BORN_EYEQ1.CARD_TITLE_MIDDLE' | translate }} </span> {{
                            'NEW_BORN_EYEQ1.CARD_TITLE_AFTER' | translate }}</p>
                    </section>
                    <small class="d-block text-left textBlack mt-3"> {{ 'NEW_BORN_EYEQ1.CARD_BOTTOM_SMALL_TEXT_ONE' |
                        translate
                        }}</small>
                    <small class="d-block text-left textBlack mt-3"> {{ 'NEW_BORN_EYEQ1.CARD_BOTTOM_SMALL_TEXT_TWO' |
                        translate
                        }}</small>
                </section>
            </section>
        </section>

        <img class="img-fluid" src="../../../assets/images/eyeqoneproductimage.png" />

        <section class="blueBackgroundBlockOut">

            <section class="whiteCardBlock">
                <p class="m-0 text-center"> <span class="boldWhiteText">{{ 'NEW_BORN_EYEQ1.CARD_TWO.TEXT_ONE_LEFT' |
                        translate }} </span>{{ 'NEW_BORN_EYEQ1.CARD_TWO.TEXT_ONE' | translate }}</p>
                <!-- <p class="m-0 text-center"> {{ 'NEW_BORN_EYEQ1.CARD_TWO.TEXT_ONE_BOTTOM' | translate }} </p> -->

                <p class="m-0 text-center mt-2">{{ 'NEW_BORN_EYEQ1.CARD_TWO.TEXT_TWO' | translate }}</p>
            </section>

            <p class="textWhite mb-0 text-center mt-3"> {{ 'NEW_BORN_EYEQ1.DESC_1_BEFORE_IMAGE' | translate }} <img
                    class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png">
                {{'NEW_BORN_EYEQ1.DESC_1_BEFORE_BRACKETS' | translate }}<sup>{{'NEW_BORN_EYEQ1.BRACKETS' | translate
                    }}</sup>{{'NEW_BORN_EYEQ1.DESC_1_AFTER_IMAGE' | translate }}</p>

            <p class="textWhite mb-0 text-center mt-3"> <span class="boldWhiteText">{{ 'NEW_BORN_EYEQ1.DESC_2_BOLD' |
                    translate }} </span> {{
                'NEW_BORN_EYEQ1.DESC_2_BEFORE_IMAGE' | translate }} <img class="iqPlusLogo"
                    src="../../../assets/images/logo-iqplus.png"> {{ 'NEW_BORN_EYEQ1.DESC_2_AFTER_IMAGE' | translate }}
            </p>

            <img class="img-fluid middleImage" src="../../../assets/images/newborneeqonelogo.png" />


            <div class="row mt-3 justify-content-between align-items-center">
                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newborneyeqpluslogo.png" />
                </div>
                <div class="col-9 text-justify">
                    <p class="listText"> {{ 'NEW_BORN_EYEQ1.LIST_DESC_ONE_BEFORE_IMAGE' | translate }} <img
                            class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"> {{
                        'NEW_BORN_EYEQ1.LIST_DESC_ONE_AFTER_IMAGE'
                        | translate }} </p>
                </div>
            </div>
            <div class="row mt-3 justify-content-between align-items-center">

                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newborneyeqhmo.png" />
                </div>
                <div class="col-9 text-justify">
                    <ul class="listBlockOut">
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ1.LIST_DESC_TWO_BEFORE_BRACKETS' | translate
                                }}<sup>{{'NEW_BORN_EYEQ1.BRACKETS' | translate }}</sup> {{
                                'NEW_BORN_EYEQ1.LIST_DESC_TWO_LINE_ONE' | translate }} </p>
                        </li>
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ1.LIST_DESC_TWO_LINE_TWO' | translate }} </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row mt-3 justify-content-between align-items-center">
                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newbornhumans.png" />
                </div>
                <div class="col-9 text-justify">
                    <ul class="listBlockOut">
                        <li>
                            <p class="listText mt-3">{{ 'NEW_BORN_EYEQ1.LIST_DESC_THREE_LINE_ONE' | translate }} </p>
                        </li>
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ1.LIST_DESC_THREE_LINE_TWO' | translate }} </p>
                        </li>
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ1.LIST_DESC_THREE_LINE_THREE' | translate }} </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row mt-3 justify-content-between align-items-center mb-4">
                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newborneyeqonestomach.png" />
                </div>
                <div class="col-9 text-justify">
                    <p class="listText">{{ 'NEW_BORN_EYEQ1.LIST_DESC_FOUR_BEFORE_BRACKETS' | translate
                        }}<sup>{{'NEW_BORN_EYEQ1.BRACKETS' | translate }}</sup> {{ 'NEW_BORN_EYEQ1.LIST_DESC_FOUR' |
                        translate }} </p>
                </div>
            </div>
            <div class="text-left">
                <small class="text-left mt-3"> <sup>{{'NEW_BORN_EYEQ1.BRACKETS' | translate }}</sup> {{
                    'NEW_BORN_EYEQ1.SMALL_DESC_ONE' | translate }}</small>
            </div>

        </section>

        <section class="similacInnerSectionOut">
            <section class="similacVannilaOut">
                <section class="similiacStepCircleBlockOut m-0">

                    <p class="textBlack text-justify"><span class="boldText"> {{ 'NEW_BORN_EYEQ1.PARA_DESC_BOLD_ONE' |
                            translate }}</span> {{ 'NEW_BORN_EYEQ1.PARA_TEXT_ONE' | translate }} <span class="boldText">
                            {{ 'NEW_BORN_EYEQ1.PARA_DESC_BOLD_TWO' | translate }}</span>{{
                        'NEW_BORN_EYEQ1.PARA_DESC_C_BRACKET' | translate }}<sup>{{ 'NEW_BORN_EYEQ1.PARA_DESC_TWO_SUPER'
                            | translate }}</sup>{{ 'NEW_BORN_EYEQ1.PARA_DESC_TWO' | translate }}<span class="boldText">
                            {{ 'NEW_BORN_EYEQ1.PARA_DESC_BOLD_THREE' | translate }}</span> {{
                        'NEW_BORN_EYEQ1.PARA_DESC_THREE' | translate }}
                        <span class="boldText"> {{ 'NEW_BORN_EYEQ1.PARA_DESC_BOLD_FOUR' | translate }}</span>
                        {{ 'NEW_BORN_EYEQ1.PARA_DESC_FOUR' | translate }}
                        <span class="boldText">
                            {{ 'NEW_BORN_EYEQ1.PARA_DESC_BOLD_FIVE' | translate }}
                        </span>
                        {{ 'NEW_BORN_EYEQ1.PARA_DESC_FIVE' | translate }}
                        <span class="boldText">
                            {{ 'NEW_BORN_EYEQ1.PARA_DESC_BOLD_SIX' | translate }}
                        </span>
                        {{ 'NEW_BORN_EYEQ1.PARA_DESC_SIX' | translate }}
                    </p>

                    <small class="d-block textBlack text-left mt-3"> {{ 'NEW_BORN_EYEQ1.PARA_SMALL_DESC_ONE' | translate
                        }}</small>
                    <small class="d-block textBlack text-left"> {{ 'NEW_BORN_EYEQ1.PARA_SMALL_DESC_TWO' | translate
                        }}</small>

                    <img class="img-fluid my-3" src="../../../assets/images/newborneyeqplustable.png" />

                    <h4 class="titleTopBold mt-3"> {{ 'NEW_BORN_EYEQ1.TITLE_TYPE_ONE' | translate }}</h4>

                    <p class="textBlack text-justify"> {{ 'NEW_BORN_EYEQ1.PARA_TWO_TEXT_BEFORE_IMAGE' | translate }}
                        <img class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"> {{
                        'NEW_BORN_EYEQ1.PARA_TWO_TEXT_AFTER_IMAGE' | translate }}
                    </p>

                    <p class="textBlack text-justify"><span class="boldText">{{ 'NEW_BORN_EYEQ1.PARA_TWO_SUB_TEXT_BOLD'
                            | translate
                            }}</span> {{ 'NEW_BORN_EYEQ1.PARA_TWO_SUB_TEXT_BEFORE_IMAGE' | translate }} <img
                            class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png">
                        {{ 'NEW_BORN_EYEQ1.PARA_TWO_SUB_TEXT_AFTER_IMAGE' | translate }}
                    </p>

                    <div class="row align-items-center mt-3">
                        <div class="col-3">
                            <img class="newbBornLeftBlockImages"
                                src="../../../assets/images/newbornlistyellowone.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack"> {{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT1' | translate }}</p>
                        </div>
                    </div>
                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornliststyletwo.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT2' | translate }}</p>
                        </div>
                    </div>

                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages"
                                src="../../../assets/images/newbornliststylethree.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack"> {{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT3' | translate }}</p>
                        </div>
                    </div>
                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages"
                                src="../../../assets/images/newbornliststylefour.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT4' | translate }}</p>
                        </div>
                    </div>
                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornlistfive.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT5' | translate }}</p>
                        </div>

                    </div>
                    <div class="row align-items-center mt-3">


                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornlistsix.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT6' | translate }}</p>
                        </div>
                    </div>
                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornlistseven.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT7_BEFORE' | translate }}<span
                                    class="boldText"> {{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT7_MIDDLE' | translate }} <img
                                        class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"></span>
                                {{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT7_AFTER' | translate }}</p>
                        </div>
                    </div>

                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornlisteight.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT8' | translate }}</p>
                        </div>

                    </div>

                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornlistnine.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT9' | translate }}</p>
                        </div>
                    </div>

                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT10_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT10' | translate }} </p>

                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT11_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT11' | translate }} </p>

                    <img class="img-fluid my-3" src="../../../assets/images/newborneyeonetabletype2.png" />
                    <div class="text-left">

                        <small class="textBlack mt-2 text-left">{{ 'NEW_BORN_EYEQ1.TABLE_TWO_TYPE' | translate
                            }}</small>

                    </div>
                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT12_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT12' | translate }} </p>
                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT13_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT13' | translate }} </p>
                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT14_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ1.BOTTOM_LIST_TEXT14' | translate }} </p>
                </section>

            </section>
        </section>

    </section>
    <!-- Product-Non-Milkcode-Similac-Newborn Ends -->



    <!-- Product-Non-Milkcode-Similac-EyeQ2 starts -->

    <section class="similacInnerSectionOut p-0" *ngIf="subCatCode == '2005' && milkCode == '1'">
        <section class="similacInnerSectionOut">
            <section class="similacVannilaOut">
                <section class="similiacStepCircleBlockOut">
                    <img class="img-fluid" src="../../../assets/images/do-you-know.png" />

                    <section class="bisqueBackroundBlock secondaryBisqueBackground">
                        <img class="img-fluid centeredImageBig" src="../../../assets/images/babydrinkmilk.png" />
                        <p class="textBlack text-justify"> {{ 'NEW_BORN_EYEQ2.CARD_TITLE_BEFORE' | translate }} <span
                                class="boldText"> {{ 'NEW_BORN_EYEQ2.CARD_TITLE_MIDDLE' | translate }} </span> {{
                            'NEW_BORN_EYEQ2.CARD_TITLE_AFTER' | translate }}</p>
                    </section>
                    <small class="d-block text-left textBlack mt-3"> {{ 'NEW_BORN_EYEQ2.CARD_BOTTOM_SMALL_TEXT_ONE' |
                        translate
                        }}</small>
                    <small class="d-block text-left textBlack mt-3"> {{ 'NEW_BORN_EYEQ2.CARD_BOTTOM_SMALL_TEXT_TWO' |
                        translate
                        }}</small>
                </section>
            </section>
        </section>

        <img class="img-fluid" src="../../../assets/images/eyeqtwoproductimage.png" />

        <section class="blueBackgroundBlockOut">

            <section class="whiteCardBlock">
                <p class="m-0 text-center"> <span class="boldWhiteText">{{ 'NEW_BORN_EYEQ2.CARD_TWO.TEXT_ONE_LEFT' |
                        translate }} </span>{{ 'NEW_BORN_EYEQ2.CARD_TWO.TEXT_ONE' | translate }}</p>
                <!-- <p class="m-0 text-center"> {{ 'NEW_BORN_EYEQ2.CARD_TWO.TEXT_ONE_BOTTOM' | translate }} </p> -->

                <p class="m-0 text-center mt-2">{{ 'NEW_BORN_EYEQ2.CARD_TWO.TEXT_TWO' | translate }}</p>
            </section>

            <p class="textWhite mb-0 text-center mt-3"> {{ 'NEW_BORN_EYEQ2.DESC_1_BEFORE_IMAGE' | translate }}
                {{ 'NEW_BORN_EYEQ2.DESC_1_AFTER_IMAGE_ONE' | translate }}<sup>{{ 'NEW_BORN_EYEQ2.BRACKETS' | translate
                    }}</sup> {{ 'NEW_BORN_EYEQ2.DESC_1_AFTER_IMAGE' | translate }}</p>

            <p class="textWhite mb-0 text-center mt-3"> <span class="boldWhiteText">{{ 'NEW_BORN_EYEQ2.DESC_2_BOLD' |
                    translate }} </span> {{
                'NEW_BORN_EYEQ2.DESC_2_BEFORE_IMAGE' | translate }}
                <img class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png" />
                {{ 'NEW_BORN_EYEQ2.DESC_2_AFTER_IMAGE' | translate
                }}
            </p>

            <img class="img-fluid middleImage" src="../../../assets/images/eyeqtwologo.png" />


            <div class="row mt-3 justify-content-between align-items-center">
                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newborneyeqpluslogo.png" />
                </div>
                <div class="col-9 text-justify">
                    <p class="listText"> {{ 'NEW_BORN_EYEQ2.LIST_DESC_ONE_BEFORE_IMAGE' | translate }} <img
                            class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"> {{
                        'NEW_BORN_EYEQ2.LIST_DESC_ONE_AFTER_IMAGE'
                        | translate }} </p>
                </div>
            </div>
            <div class="row mt-3 justify-content-between align-items-center">

                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newborneyeqhmo.png" />
                </div>
                <div class="col-9 text-justify">
                    <ul class="listBlockOut">
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ2.LIST_DESC_TWO_LINE_ONE_BEFORE' | translate }}<sup>{{
                                    'NEW_BORN_EYEQ2.BRACKETS' | translate }}</sup> {{
                                'NEW_BORN_EYEQ2.LIST_DESC_TWO_LINE_ONE_AFTER' | translate }} </p>
                        </li>
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ2.LIST_DESC_TWO_LINE_TWO' | translate }} </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row mt-3 justify-content-between align-items-center">
                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newbornhumans.png" />
                </div>
                <div class="col-9 text-justify">
                    <ul class="listBlockOut">
                        <li>
                            <p class="listText mt-3">{{ 'NEW_BORN_EYEQ2.LIST_DESC_THREE_LINE_ONE' | translate }} </p>
                        </li>
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ2.LIST_DESC_THREE_LINE_TWO' | translate }} </p>
                        </li>
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ2.LIST_DESC_THREE_LINE_THREE' | translate }} </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row mt-3 justify-content-between align-items-center mb-4">
                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newborneyeqonestomach.png" />
                </div>
                <div class="col-9 text-justify">
                    <p class="listText">{{ 'NEW_BORN_EYEQ2.LIST_DESC_FOUR_BEFORE' | translate }}<sup>{{
                            'NEW_BORN_EYEQ2.BRACKETS' | translate }}</sup>
                        {{ 'NEW_BORN_EYEQ2.LIST_DESC_FOUR_AFTER' | translate }}
                    </p>
                </div>
            </div>
            <div class="text-left">
                <small class="text-left mt-3"> <sup>{{ 'NEW_BORN_EYEQ2.BRACKETS' | translate }}</sup> {{
                    'NEW_BORN_EYEQ2.SMALL_DESC_ONE' | translate }}</small>
            </div>

        </section>

        <section class="similacInnerSectionOut">
            <section class="similacVannilaOut">
                <section class="similiacStepCircleBlockOut m-0">

                    <p class="textBlack text-justify"><span class="boldText"> {{ 'NEW_BORN_EYEQ2.PARA_DESC_BOLD_ONE' |
                            translate }}</span> {{ 'NEW_BORN_EYEQ2.PARA_TEXT_ONE' | translate }}
                        <span class="boldText">{{ 'NEW_BORN_EYEQ2.PARA_DESC_BOLD_TWO' | translate }}</span>
                        {{ 'NEW_BORN_EYEQ2.PARA_DESC_C_BRACKET' | translate }}<sup>{{
                            'NEW_BORN_EYEQ2.PARA_DESC_TW0_SUPER' | translate }}</sup>{{'NEW_BORN_EYEQ2.PARA_DESC_TWO' |
                        translate }}
                        <span class="boldText"> {{ 'NEW_BORN_EYEQ2.PARA_DESC_BOLD_THREE' | translate }} </span> {{
                        'NEW_BORN_EYEQ2.PARA_DESC_THREE' | translate }}
                        <span class="boldText"> {{ 'NEW_BORN_EYEQ2.PARA_DESC_BOLD_FOUR' | translate }}</span>
                        {{ 'NEW_BORN_EYEQ2.PARA_DESC_FOUR' | translate }}
                        <span class="boldText">
                            {{ 'NEW_BORN_EYEQ2.PARA_DESC_BOLD_FIVE' | translate }}
                        </span>
                        {{ 'NEW_BORN_EYEQ2.PARA_DESC_FIVE' | translate }}
                        <span class="boldText">
                            {{ 'NEW_BORN_EYEQ2.PARA_DESC_BOLD_SIX' | translate }}
                        </span>
                        {{ 'NEW_BORN_EYEQ2.PARA_DESC_SIX' | translate }}
                    </p>

                    <small class="d-block textBlack text-left mt-3"> {{ 'NEW_BORN_EYEQ2.PARA_SMALL_DESC_ONE' | translate
                        }}</small>
                    <small class="d-block textBlack text-left"> {{ 'NEW_BORN_EYEQ2.PARA_SMALL_DESC_TWO' | translate
                        }}</small>

                    <img class="img-fluid my-3" src="../../../assets/images/newborneyeq2table.png" />

                    <h4 class="titleTopBold mt-3"> {{ 'NEW_BORN_EYEQ2.TITLE_TYPE_ONE' | translate }}</h4>

                    <p class="textBlack text-justify"> {{ 'NEW_BORN_EYEQ2.PARA_TWO_TEXT_BEFORE_IMAGE' | translate }}
                        <img class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"> {{
                        'NEW_BORN_EYEQ2.PARA_TWO_TEXT_AFTER_IMAGE' | translate }}
                    </p>

                    <p class="textBlack text-justify"><span class="boldText">{{ 'NEW_BORN_EYEQ2.PARA_TWO_SUB_TEXT_BOLD'
                            | translate
                            }}</span> {{ 'NEW_BORN_EYEQ2.PARA_TWO_SUB_TEXT_BEFORE_IMAGE' | translate }} <img
                            class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"> {{
                        'NEW_BORN_EYEQ2.PARA_TWO_SUB_TEXT_AFTER_IMAGE' | translate }} </p>

                    <div class="row align-items-center mt-3">
                        <div class="col-3">
                            <img class="newbBornLeftBlockImages"
                                src="../../../assets/images/newbornlistyellowone.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack"> {{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT1' | translate }}</p>
                        </div>
                    </div>
                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornliststyletwo.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT2' | translate }}</p>
                        </div>
                    </div>

                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages"
                                src="../../../assets/images/newbornliststylethree.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack"> {{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT3' | translate }}</p>
                        </div>
                    </div>
                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages"
                                src="../../../assets/images/newbornliststylefour.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT4' | translate }}</p>
                        </div>
                    </div>
                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornlistfive.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT5' | translate }}</p>
                        </div>

                    </div>
                    <div class="row align-items-center mt-3">


                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornlistsix.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT6' | translate }}</p>
                        </div>
                    </div>
                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornlistseven.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT7_BEFORE' | translate }}<span
                                    class="boldText"> {{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT7_MIDDLE' | translate }} <img
                                        class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"></span>
                                {{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT7_AFTER' | translate }}</p>
                        </div>
                    </div>

                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornlisteight.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT8' | translate }}</p>
                        </div>

                    </div>

                    <div class="row align-items-center mt-3">

                        <div class="col-3">
                            <img class="newbBornLeftBlockImages" src="../../../assets/images/newbornlistnine.png" />
                        </div>
                        <div class="col-9 text-left">
                            <p class="textBlack">{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT9' | translate }}</p>
                        </div>
                    </div>

                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT10_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT10' | translate }} </p>

                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT11_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT11' | translate }} </p>

                    <img class="img-fluid my-3" src="../../../assets/images/newborneyeqtwotabletype2.png" />
                    <div class="text-left">
                        <small class="textBlack mt-2 text-left">{{ 'NEW_BORN_EYEQ2.TABLE_TWO_TYPE' | translate
                            }}</small>
                    </div>
                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT12_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT12' | translate }} </p>
                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT13_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT13' | translate }} </p>
                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT14_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ2.BOTTOM_LIST_TEXT14' | translate }} </p>
                </section>

            </section>
        </section>

    </section>

    <!-- Product-Non-Milkcode-Similac-EyeQ2 ends -->


    <!-- Product-Non-Milkcode-Similac-EyeQ3 starts -->
    <section class="similacInnerSectionOut p-0" *ngIf="subCatCode == '2003' && milkCode == '1'">
        <section class="similacInnerSectionOut">
            <section class="similacVannilaOut">
                <section class="similiacStepCircleBlockOut">
                    <img class="img-fluid" src="../../../assets/images/do-you-know.png" />

                    <section class="bisqueBackroundBlock secondaryBisqueBackground">
                        <img class="img-fluid centeredImageBig" src="../../../assets/images/babydrinkmilk.png" />
                        <p class="textBlack text-justify"> {{ 'NEW_BORN_EYEQ3.CARD_TITLE_BEFORE' | translate }} <span
                                class="boldText"> {{ 'NEW_BORN_EYEQ3.CARD_TITLE_MIDDLE' | translate }} </span> {{
                            'NEW_BORN_EYEQ3.CARD_TITLE_AFTER' | translate }}</p>
                    </section>
                    <small class="d-block text-left textBlack mt-3"> {{ 'NEW_BORN_EYEQ3.CARD_BOTTOM_SMALL_TEXT_ONE' |
                        translate
                        }}</small>
                    <small class="d-block text-left textBlack mt-3"> {{ 'NEW_BORN_EYEQ3.CARD_BOTTOM_SMALL_TEXT_TWO' |
                        translate
                        }}</small>
                </section>
            </section>
        </section>

        <img class="img-fluid" src="../../../assets/images/eyeq3productimage.png" />

        <section class="blueBackgroundBlockOut">

            <section class="whiteCardBlock">
                <p class="m-0 text-center"> <span class="boldWhiteText">{{ 'NEW_BORN_EYEQ3.CARD_TWO.TEXT_ONE_LEFT' |
                        translate }} </span>{{ 'NEW_BORN_EYEQ3.CARD_TWO.TEXT_ONE' | translate }}</p>
                <!-- <p class="m-0 text-center"> {{ 'NEW_BORN_EYEQ3.CARD_TWO.TEXT_ONE_BOTTOM' | translate }} </p> -->

                <p class="m-0 text-center mt-2">{{ 'NEW_BORN_EYEQ3.CARD_TWO.TEXT_TWO' | translate }}</p>
            </section>

            <p class="textWhite mb-0 text-center mt-3"> {{ 'NEW_BORN_EYEQ3.DESC_1_BEFORE_IMAGE' | translate }}
                <img class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"> {{
                'NEW_BORN_EYEQ3.DESC_1_AFTER_IMAGE_ONE' | translate }}
                <sup>{{ 'NEW_BORN_EYEQ3.BRACKETS' | translate }}</sup>
                {{ 'NEW_BORN_EYEQ3.DESC_1_AFTER_IMAGE' | translate }}
            </p>

            <p class="textWhite mb-0 text-center mt-3"> <span class="boldWhiteText">{{ 'NEW_BORN_EYEQ3.DESC_2_BOLD' |
                    translate }} </span> {{
                'NEW_BORN_EYEQ3.DESC_2_BEFORE_IMAGE' | translate }}
                <img class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png">
                {{ 'NEW_BORN_EYEQ3.DESC_2_AFTER_IMAGE' | translate }}
            </p>

            <img class="img-fluid middleImage" src="../../../assets/images/eyeqthreelogo.png" />


            <div class="row mt-3 justify-content-between align-items-center">
                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newborneyeqpluslogo.png" />
                </div>
                <div class="col-9 text-justify">
                    <p class="listText"> {{ 'NEW_BORN_EYEQ3.LIST_DESC_ONE_BEFORE_IMAGE' | translate }} <img
                            class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"> {{
                        'NEW_BORN_EYEQ3.LIST_DESC_ONE_AFTER_IMAGE'
                        | translate }} </p>
                </div>
            </div>
            <div class="row mt-3 justify-content-between align-items-center">

                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newborneyeqhmo.png" />
                </div>
                <div class="col-9 text-justify">
                    <ul class="listBlockOut">
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ3.LIST_DESC_TWO_LINE_ONE_BEFORE' | translate }}<sup>{{
                                    'NEW_BORN_EYEQ3.BRACKETS' | translate }}</sup>
                                {{ 'NEW_BORN_EYEQ3.LIST_DESC_TWO_LINE_ONE_AFTER' | translate }}</p>
                        </li>
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ3.LIST_DESC_TWO_LINE_TWO' | translate }} </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row mt-3 justify-content-between align-items-center">
                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newbornhumans.png" />
                </div>
                <div class="col-9 text-justify">
                    <ul class="listBlockOut">
                        <li>
                            <p class="listText mt-3">{{ 'NEW_BORN_EYEQ3.LIST_DESC_THREE_LINE_ONE' | translate }} </p>
                        </li>
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ3.LIST_DESC_THREE_LINE_TWO' | translate }} </p>
                        </li>
                        <li>
                            <p class="listText">{{ 'NEW_BORN_EYEQ3.LIST_DESC_THREE_LINE_THREE' | translate }} </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row mt-3 justify-content-between align-items-center mb-4">
                <div class="col-3">
                    <img class="listImagesLeft" src="../../../assets/images/newborneyeqonestomach.png" />
                </div>
                <div class="col-9 text-justify">
                    <p class="listText">{{ 'NEW_BORN_EYEQ3.LIST_DESC_FOUR_BEFORE' | translate }}<sup>{{
                            'NEW_BORN_EYEQ3.BRACKETS' | translate }}</sup>{{ 'NEW_BORN_EYEQ3.LIST_DESC_FOUR_AFTER' |
                        translate }} </p>
                </div>
            </div>
            <div class="text-left">
                <small class="text-left mt-3"> <sup>{{ 'NEW_BORN_EYEQ3.BRACKETS' | translate }}</sup>{{
                    'NEW_BORN_EYEQ3.SMALL_DESC_ONE' | translate }}</small>
            </div>

        </section>

        <section class="similacInnerSectionOut">
            <section class="similacVannilaOut">
                <section class="similiacStepCircleBlockOut m-0">

                    <p class="textBlack text-justify"><span class="boldText"> {{ 'NEW_BORN_EYEQ3.PARA_DESC_BOLD_ONE' |
                            translate }}</span>{{ 'NEW_BORN_EYEQ3.PARA_TEXT_ONE' | translate }}<span class="boldText">
                            {{ 'NEW_BORN_EYEQ3.PARA_DESC_BOLD_TWO' | translate
                            }}</span>{{'NEW_BORN_EYEQ3.PARA_DESC_C_BRACKET' | translate
                        }}<sup>{{'NEW_BORN_EYEQ3.PARA_DESC_TWO_SUPER' | translate }}</sup>{{
                        'NEW_BORN_EYEQ3.PARA_DESC_TWO'
                        | translate }}
                        <span class="boldText"> {{ 'NEW_BORN_EYEQ3.PARA_DESC_BOLD_THREE' | translate }} </span> {{
                        'NEW_BORN_EYEQ3.PARA_DESC_THREE' | translate }}
                        <span class="boldText"> {{ 'NEW_BORN_EYEQ3.PARA_DESC_BOLD_FOUR' | translate }}</span>
                        {{ 'NEW_BORN_EYEQ3.PARA_DESC_FOUR' | translate }}
                        <span class="boldText">
                            {{ 'NEW_BORN_EYEQ3.PARA_DESC_BOLD_FIVE' | translate }}
                        </span>
                        {{ 'NEW_BORN_EYEQ3.PARA_DESC_FIVE' | translate }}
                        <span class="boldText">
                            {{ 'NEW_BORN_EYEQ3.PARA_DESC_BOLD_SIX' | translate }}
                        </span>
                        {{ 'NEW_BORN_EYEQ3.PARA_DESC_SIX' | translate }}
                    </p>

                    <small class="d-block textBlack text-left mt-3"> {{ 'NEW_BORN_EYEQ3.PARA_SMALL_DESC_ONE' | translate
                        }}</small>
                    <small class="d-block textBlack text-left"> {{ 'NEW_BORN_EYEQ3.PARA_SMALL_DESC_TWO' | translate
                        }}</small>

                    <img class="img-fluid my-3" src="../../../assets/images/newborneyeqtablethree.png" />

                    <h4 class="titleTopBold mt-3"> {{ 'NEW_BORN_EYEQ3.TITLE_TYPE_ONE' | translate }}</h4>

                    <p class="textBlack text-justify"> {{ 'NEW_BORN_EYEQ3.PARA_TWO_TEXT_BEFORE_IMAGE_ONE' | translate }}
                        <img class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png"> {{
                        'NEW_BORN_EYEQ3.PARA_TWO_TEXT_AFTER_IMAGE_ONE' | translate }} <img class="iqPlusLogo"
                            src="../../../assets/images/logo-iqplus.png">
                        {{ 'NEW_BORN_EYEQ3.PARA_TWO_TEXT_BEFORE_IMAGE_TWO' | translate }}
                        <img class="iqPlusLogo" src="../../../assets/images/logo-iqplus.png">
                        {{ 'NEW_BORN_EYEQ3.PARA_TWO_TEXT_AFTER_IMAGE_TWO' | translate }}
                    </p>


                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT1_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT1' | translate }} </p>

                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT2_BOLD'
                            | translate }}</span> {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT2' | translate }} </p>

                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT3_BOLD'
                            | translate }}</span>
                        {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT3_BEFORE_IMAGE' | translate }}<img class="iqPlusLogo"
                            src="../../../assets/images/logo-iqplus.png">

                        {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT3_AFTER_IMAGE' | translate }} </p>

                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT4_BOLD'
                            | translate }}</span>
                        {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT4_BEFORE_IMAGE' | translate }}<img class="iqPlusLogo"
                            src="../../../assets/images/logo-iqplus.png">

                        {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT4_AFTER_IMAGE' | translate }} </p>

                    <img class="img-fluid my-3" src="../../../assets/images/newbornthreetabletye2.png" />

                    <div class="text-left">
                        <small class="textBlack text-left d-block"> {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_SMALL' | translate
                            }}</small>

                    </div>

                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT5_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT5' | translate }} </p>


                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT6_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT6' | translate }} </p>

                    <p class="textBlack text-left"> <span class="boldText"> {{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT7_BOLD'
                            | translate }}</span>{{ 'NEW_BORN_EYEQ3.BOTTOM_LIST_TEXT7' | translate }} </p>

                </section>

            </section>
        </section>

    </section>

    <!-- Product-Non-Milkcode-Similac-EyeQ3 ends -->

    <!-- product - similac1 starts here -->
    <section class="productSimilac1Container" *ngIf="subCatCode == '2014'">
        <div class="goldenBg">
            <div class="similacImgOut">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/similac.png" alt="similac" />
            </div>
            <p class="aboutPrd">Sản phẩm dinh dưỡng công thức <br> cho trẻ 0–6 tháng tuổi: Similac 1</p>
            <div class="similac1ContentOut">
                <h1>Similac 1</h1>
                <h5>SẢN PHẨM DINH DƯỠNG CÔNG THỨC CHO TRẺ 0–6 THÁNG TUỔI​</h5>
                <p class="font-italic">
                    <strong>(*) Các thông tin là những thông tin trên nhãn đã được cơ quan y tế có thẩm quyền phê
                        duyệt.</strong>
                </p>
                <p>
                    <strong>CHÚ Ý:</strong>
                    Sữa mẹ là thức ăn tốt nhất cho sức khỏe và sự phát triển toàn diện của trẻ nhỏ. Các yếu tố chống
                    nhiễm khuẩn, đặc biệt là
                    kháng thể chỉ có trong sữa mẹ có tác dụng giúp trẻ phòng, chống bệnh tiêu chảy, nhiễm khuẩn đường hô
                    hấp và một số bệnh
                    nhiễm khuẩn khác.
                </p>
                <p>Chỉ sử dụng sản phẩm này theo chỉ dẫn của bác sĩ. Pha chế theo đúng hướng dẫn. Cho trẻ ăn bằng cốc,
                    thìa hợp vệ sinh.</p>

                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/gangliosides.png" alt="gangliosides" /></i>
                    Hệ dưỡng chất prodi-g<sup>TM</sup> giúp phát triển não bộ
                </div>
                <div class="goldenStrip">
                    <span class="text-uppercase">Bổ sung gangliosides</span>
                </div>
                <p class="secondaryForeColor">
                    <strong>Similac<sup>®</sup> 1</strong>
                    chứa hệ dưỡng chất đặc biệt <strong>Prodi-G<sup>TM</sup></strong> nay được bổ sung thêm
                    <strong>gangliosides</strong>
                    - là một thành phần chất béo quan trọng của màng tế bào thần kinh trong não bộ giúp hỗ trợ phát
                    triển nhận thức ở trẻ.
                </p>
                <div class="goldenStrip text-uppercase">
                    bộ 3 dưỡng chất vàng
                </div>
                <p class="secondaryForeColor">Phối hợp khoa học bộ 3 dưỡng chất vàng cho não bộ gồm
                    <strong>lutein, vitamin E tự nhiên, DHA</strong>, cùng với AA, omega 3, omega 6, taurin và cholin
                    giúp trẻ phát triển
                    tốt não bộ.
                </p>
                <div class="goldenStrip text-uppercase">
                    tăng cường hấp thu
                </div>
                <p class="secondaryForeColor">
                    Hệ chất béo đặc biệt không chứa dầu cọ<sup>‡</sup> giúp tăng cường hấp thu các dưỡng chất quan
                    trọng cho não bộ (DHA và AA).
                </p>
                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/5_hmos.png" alt="5_hmos" /></i>
                    Giúp Tăng cường sức đề kháng
                </div>
                <div class="goldenStrip">
                    <span class="text-uppercase">similac<sup>®</sup> 1 nay có 5 hmo</span>s
                </div>
                <p class="noMarginBottom secondaryForeColor">Lớp bảo vệ kép bao gồm:</p>
                <p class="bullet secondaryForeColor noMarginBottom">
                    <strong>HMOs</strong> (Oligosaccharides của sữa mẹ) nuôi dưỡng các vi khuẩn có lợi trong đường tiêu
                    hoá, ngăn chặn các
                    vi sinh vật gây bệnh bám vào tế bào niêm mạc, thúc đẩy hệ miễn dịch trưởng thành, giúp tăng cường
                    sức đề kháng. Bổ sung
                    <strong>HMOs</strong> giúp giảm nguy cơ nhiễm khuẩn ở trẻ nhỏ. <strong>Similac<sup>®</sup>
                        1</strong> nay chứa phức hợp 5
                    loại <strong>HMOs</strong>
                </p>
                <p class="bullet secondaryForeColor">
                    Phối hợp đặc biệt <strong>nucleotides và probiotics (lợi khuẩn BB-12<sup>®*</sup>)</strong> giúp trẻ
                    tăng cường sức đề
                    kháng.
                </p>
                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/probiotics.png" alt="probiotics" /></i>
                    Probiotics giúp Tiêu hóa tốt
                </div>
                <div class="goldenStrip text-uppercase">
                    Bổ sung probiotics
                </div>
                <p class="bullet secondaryForeColor noMarginBottom">
                    Phối hợp khoa học <strong>Probiotics (<span class="font-italic">Bifidobacterium
                            lactis</span></strong> BB-12<sup>®*</sup>,
                    là các vi khuẩn có lợi trong đường tiêu hoá) và <strong>HMOs</strong> (Oligosaccharides của sữa mẹ,
                    nuôi dưỡng các vi khuẩn có
                    lợi) giúp hệ tiêu hoá khoẻ mạnh, hỗ trợ tiêu hoá tốt.
                </p>
                <p class="bullet secondaryForeColor">
                    Hệ chất béo đặc biệt không chứa dầu cọ<sup>‡</sup> giúp trẻ hấp thu canxi tốt hơn, ít rối loạn tiêu
                    hoá.
                </p>
                <div class="row">
                    <div class="col-8">
                        <div class="bt mb10"></div>
                        <strong class="secondaryForeColor">
                            Similac<sup>®</sup> là công thức đầu tiên trên thế giới bổ sung HMOs.<sup>#</sup>
                        </strong>
                        <p class="secondaryForeColor noMarginBottom font-size-xs">
                            <sup>#</sup> Theo công bố của Mintel Consulting dựa trên cơ sở
                            dữ liệu lớn nhất thế giới về giới thiệu sản phẩm mới.
                        </p>
                        <p class="secondaryForeColor font-size-xs">* BB-12: thương hiệu của Chr. Hansen</p>
                    </div>
                    <div class="col-4">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/prodi.png" alt="prodi" />
                    </div>
                </div>
            </div>
        </div>
        <div class="blueBg">
            <div class="ingredientBox">
                <div class="goldenStrip">
                    THÀNH PHẦN
                </div>
                <div class="innerContent">
                    <p class="noMarginBottom">
                        <strong>THÀNH PHẦN:</strong> Sữa không béo, lactose,
                        <strong>DẦU THỰC VẬT</strong> (<sup>‡</sup>chứa: dầu hướng dương giàu oleic, dầu đậu nành, dầu
                        dừa), đạm whey cô đặc,
                        bột bơ sữa, <strong>KHOÁNG CHẤT</strong> (kali citrat, canxi carbonat, magiê clorid, kali
                        hydroxit, sắt sulfat,
                        natri clorid, kẽm sulfat, đồng sulfat, canxi clorid, tricanxi phosphat, mangan sulfat, kali
                        iodid, natri selenat),
                        đạm whey thủy phân, 5 HMOs (2’-fucosyllactose, lacto-N-tetraose, 3- fucosyllactose,
                        6’-sialyllactose, 3’-sialyllactose),
                        acid arachidonic (AA), <strong>VITAMIN</strong> (acid ascorbic, RRR-α- tocopheryl
                        acetat<sup>◊</sup>, niacinamid,
                        canxi d-pantothenat, retinyl palmitat, thiamin hydroclorid, pyridoxin hydroclorid, riboflavin,
                        acid folic, phylloquinon,
                        d-biotin, cholecalciferol, cyanocobalamin), chất nhũ hóa lecithin đậu nành, acid docosahexaenoic
                        (DHA), cholin bitartrat,
                        <strong>NUCLEOTIDES</strong> (cytidin 5’-monophosphat, dinatri guanosin 5’- monophosphat,
                        dinatri uridin 5’-monophosphat,
                        adenosin 5’-monophosphat), cholin clorid, taurin, L-tryptophan, myo-inositol, ascorbyl palmitat,
                        <span class="font-italic">Bifidobacterium lactis</span> BB- 12<sup>®*</sup>, hỗn hợp tocopherol,
                        L- carnitin,
                        <strong>CAROTENOID</strong> (lutein, β- caroten)
                    </p>
                    <p class="noMarginBottom"><sup>◊</sup> Vitamin E tự nhiên</p>
                    <p>* BB-12: thương hiệu của Chr. Hansen</p>
                    <div class="tableOut">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/nutrition_facts.png"
                            alt="nutrition_facts" />
                    </div>
                </div>
                <div class="goldenStrip">
                    Sử dụng và bảo quản
                </div>
                <div class="innerContent">
                    <p><strong>hướng dẫn pha chế và sử dụng:</strong> Sử dụng theo hướng dẫn của nhân viên y tế. Khi
                        pha, cần vệ sinh sạch sẽ, pha và bảo quản đúng cách. Không tuân thủ các hướng dẫn này có thể sẽ
                        gây ảnh hưởng không tốt cho sức khoẻ của bé. Các loại sản phẩm dinh dưỡng công thức đều không
                        tuyệt đối vô trùng, do đó khi dùng cho trẻ sinh non hoặc trẻ có vấn đề về miễn dịch cần phải
                        theo sự hướng dẫn và theo dõi của bác sĩ. Nước dùng để pha phải được đun sôi 5 phút, để nguội và
                        pha cẩn thận theo bảng hướng dẫn kèm theo. Chỉ dùng muỗng có sẵn trong hộp để lường
                        <strong>Similac<sup>®</sup> 1.</strong> Nếu pha hơn một lần dùng thì lượng pha dư phải giữ lạnh
                        ở nhiệt độ 2–40C và dùng trong vòng 24 giờ. Chỉ cho bé dùng tối đa trong vòng 1 giờ, sau đó phải
                        đổ bò phần còn thừa.
                    </p>
                    <p><strong>cách pha chuẩn:</strong> Mỗi muỗng gạt ngang bột <strong>Similac<sup>®</sup> 1</strong>
                        pha với 60 ml (2 fl oz) nước hoặc 124,6 g bột pha với nước thành 1 lít.</p>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb1.png"
                                alt="thumb1" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Rửa sạch tất cả các dụng cụ pha, cốc, thìa bằng xà phòng.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb2.png"
                                alt="thumb2" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Rửa lại dưới vòi nước chảy và đun sôi trong 5 phút.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb3.png"
                                alt="thumb3" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Chuẩn bị sạch sẽ nơi pha.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb4.png"
                                alt="thumb4" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Đun sôi nước trong 5 phút bằng một nồi riêng rồi để nguội.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb5.png"
                                alt="thumb5" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Cho chính xác lượng nước ấm cần dùng vào cốc đã được tiệt trùng.
                            </p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb6.png"
                                alt="thumb6" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Lường bằng muỗng có sẵn trong hộp, dùng dao sạch gạt ngang.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb7.png"
                                alt="thumb7" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Mỗi muỗng <strong>Similac<sup>®</sup> 1</strong> đã lường pha với
                                60 ml nước ấm.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb8.png"
                                alt="thumb8" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Khuấy cho tan hoàn toàn. Thử nhiệt độ rồi cho bé dùng.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb9.png"
                                alt="thumb9" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Đổ bỏ phần còn thừa nếu bé dùng kéo dài hơn 1 giờ.</p>
                        </div>
                    </div>
                    <p><strong>BẢO QUẢN:</strong> Bảo quản hộp chưa mở nắp ở nhiệt độ phòng. Hộp đã mở nắp nên sử dụng
                        trong vòng 3 tuần. Đậy nắp và bảo quản nơi khô mát (không cất trong tủ lạnh).</p>
                    <p><strong>CẨN TRỌNG:</strong> Không dùng lò vi sóng để pha hay hâm nóng vì có thể gây bỏng.</p>
                    <p>© 2021 Abbott <strong>Kiểm tra ngày sản xuất (MFG) và hạn sử dụng (EXP) ở đáy hộp.</strong></p>
                    <p><strong>Số lần đề nghị sử dụng:</strong></p>
                    <div class="tableOut">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/number_of_uses.png"
                            alt="number_of_uses" />
                    </div>
                    <p>Nên hỏi bác sĩ về lượng dùng phù hợp cho bé. Đây là lượng dùng đề nghị, mỗi bé có thể cần lượng
                        dùng khác nhau. Không dùng cho trẻ bị bệnh Galactosemia.</p>
                    <p><strong>List No.: Y571 501-996-000​</strong></p>
                    <p><strong>SĐK: 9464/2021/ĐKSP</strong></p>
                    <p><strong>Sản xuất tại:</strong> Abbott Ireland, Cootehill, Co. Cavan, Ireland</p>
                    <p>A subsidiary of Abbott Laboratories, North Chicago, IL 60064, USA</p>
                    <p><strong>Đăng ký bởi:</strong> VPĐD Abbott Laboratories GmbH, Tầng 7-8, tháp A, toà nhà Handi
                        Resco, 521 Kim Mã, Ba Đình, Hà Nội.</p>
                    <p><strong>Nhập khẩu và phân phối bởi:</strong></p>
                    <p>Công ty TNHH Dinh Dưỡng 3A (Việt Nam), Centec Tower, 72-74 Nguyễn Thị Minh Khai, phường Võ Thị
                        Sáu, quận 3, thành phố Hồ Chí Minh</p>
                </div>
            </div>
        </div>

    </section>
    <!-- product - similac1 ends here -->

    <!-- product - similac2 starts here -->
    <section class="productSimilac1Container" *ngIf="subCatCode == '2015'">
        <div class="mainInner">
            <div class="lightBlueBg">
                <span class="mediumSandalForeColor">giai đoạn vàng</span> để phát triển trí não tối ưu, bắt đầu từ việc
                xây dựng <span class="mediumSandalForeColor">Một hệ miễn dịch khoẻ mạnh</span>
            </div>
            <div class="blueHeaderImageOut">
                <div class="blueHeaderBg">
                    Khả năng miễn dịch bẩm sinh & đáp ứng ngày một mạnh mẽ hơn
                </div>
                <div class="imgOut">
                    <img class="img-fuid w-100" src="../../../assets/images/prd-similac/img1.png" alt="img1" />
                </div>
            </div>
            <div class="blueHeaderImageOut">
                <div class="blueHeaderBg">
                    Từ 2-3 tuổi, trọng lượng não bộ của trẻ đã đạt tới <span class="whiteTxt">70-80%</span> trọng lượng
                    não người trưởng thành
                </div>
                <div class="imgOut">
                    <img class="img-fuid w-100" src="../../../assets/images/prd-similac/img2.png" alt="img2" />
                </div>
            </div>
        </div>
        <div class="goldenBg goldenBgWithCurve">
            <div class="similacImgOut">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/similac2.png" alt="similac2" />
            </div>
            <p class="aboutPrd">Sản phẩm dinh dưỡng công thức <br> cho trẻ 6–12 tháng tuổi: Similac 2</p>
            <div class="similac1ContentOut">
                <h1>Similac 2</h1>
                <h5>SẢN PHẨM DINH DƯỠNG CÔNG THỨC CHO TRẺ 6–12 THÁNG TUỔI​​</h5>
                <p class="font-italic"><strong>(*) Các thông tin là những thông tin trên nhãn đã được cơ quan y tế có
                        thẩm quyền phê duyệt.</strong></p>
                <p><strong>CHÚ Ý:</strong> Sữa mẹ là thức ăn tốt nhất cho sức khỏe và sự phát triển toàn diện của trẻ
                    nhỏ. Các yếu tố chống nhiễm khuẩn, đặc biệt là kháng thể chỉ có trong sữa mẹ có tác dụng giúp trẻ
                    phòng, chống bệnh tiêu chảy, nhiễm khuẩn đường hô hấp và một số bệnh nhiễm khuẩn khác.</p>
                <p>Chỉ sử dụng sản phẩm này theo chỉ dẫn của bác sĩ. Pha chế theo đúng hướng dẫn. Cho trẻ ăn bằng cốc,
                    thìa hợp vệ sinh.</p>

                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/gangliosides.png" alt="gangliosides" /></i>
                    Hệ dưỡng chất prodi-g<sup>TM</sup> giúp phát triển não bộ
                </div>
                <div class="goldenStrip">
                    <span class="text-uppercase">Bổ sung gangliosides</span>
                </div>
                <p class="secondaryForeColor"><strong>Similac<sup>®</sup> 2</strong> chứa hệ dưỡng chất đặc biệt
                    <strong>Prodi-G<sup>TM</sup></strong> nay được bổ sung thêm <strong>gangliosides</strong> - là một
                    thành phần chất béo quan trọng của màng tế bào thần kinh trong não bộ giúp hỗ trợ phát triển nhận
                    thức ở trẻ.
                </p>
                <div class="goldenStrip text-uppercase">
                    bộ 3 dưỡng chất vàng
                </div>
                <p class="secondaryForeColor">Phối hợp khoa học bộ 3 dưỡng chất vàng cho não bộ gồm <strong>lutein,
                        vitamin E tự nhiên, DHA</strong>, cùng với AA, omega 3, omega 6, taurin và cholin giúp trẻ phát
                    triển tốt não bộ.</p>
                <div class="goldenStrip text-uppercase">
                    tăng cường hấp thu
                </div>
                <p class="secondaryForeColor">Hệ chất béo đặc biệt không chứa dầu cọ<sup>‡</sup> giúp tăng cường hấp thu
                    các dưỡng chất quan trọng cho não bộ (DHA và AA).</p>

                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/5_hmos.png" alt="5_hmos" /></i>
                    Giúp Tăng cường sức đề kháng
                </div>
                <div class="goldenStrip">
                    <span class="text-uppercase">Similac<sup>®</sup> 2 nay có 5 hmo</span>s
                </div>
                <p class="noMarginBottom secondaryForeColor">Lớp bảo vệ kép bao gồm:</p>
                <p class="bullet secondaryForeColor noMarginBottom"><strong>HMOs</strong> (Oligosaccharides của sữa mẹ)
                    nuôi dưỡng các vi khuẩn có lợi trong đường tiêu hoá, ngăn chặn các vi sinh vật gây bệnh bám vào tế
                    bào niêm mạc, thúc đẩy hệ miễn dịch trưởng thành, giúp tăng cường sức đề kháng. Bổ sung
                    <strong>HMOs</strong> giúp giảm nguy cơ nhiễm khuẩn ở trẻ nhỏ. <strong>Similac<sup>®</sup>
                        2</strong> nay chứa phức hợp 5 loại <strong>HMOs</strong>
                </p>
                <p class="bullet secondaryForeColor">Phối hợp đặc biệt <strong>nucleotides và probiotics (lợi khuẩn
                        BB-12<sup>®*</sup>)</strong> giúp trẻ tăng cường sức đề kháng.</p>
                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/probiotics.png" alt="probiotics" /></i>
                    Probiotics giúp Tiêu hóa tốt
                </div>
                <div class="goldenStrip text-uppercase">
                    Bổ sung probiotics
                </div>
                <p class="bullet secondaryForeColor noMarginBottom">Phối hợp khoa học <strong>Probiotics (<span
                            class="font-italic">Bifidobacterium lactis</span></strong> BB-12<sup>®*</sup>, là các vi
                    khuẩn có lợi trong đường tiêu hoá) và <strong>HMOs</strong> (Oligosaccharides của sữa mẹ, nuôi dưỡng
                    các vi khuẩn có lợi) giúp hệ tiêu hoá khoẻ mạnh, hỗ trợ tiêu hoá tốt.</p><br>
                <p class="bullet secondaryForeColor">Hệ chất béo đặc biệt không chứa dầu cọ<sup>‡</sup> giúp trẻ hấp thu
                    canxi tốt hơn, ít rối loạn tiêu hoá.</p>
                <div class="row">
                    <div class="col-8">
                        <div class="bt mb10"></div>
                        <strong class="secondaryForeColor">Similac<sup>®</sup> là công thức đầu tiên trên thế giới bổ
                            sung HMOs.<sup>#</sup></strong>
                        <p class="secondaryForeColor noMarginBottom font-size-xs"><sup>#</sup> Theo công bố của Mintel
                            Consulting dựa trên cơ sở dữ liệu lớn nhất thế giới về giới thiệu sản phẩm mới.</p>
                        <p class="secondaryForeColor font-size-xs">* BB-12: thương hiệu của Chr. Hansen</p>
                    </div>
                    <div class="col-4">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/prodi.png" alt="prodi" />
                    </div>
                </div>
            </div>
        </div>
        <div class="blueBg">
            <div class="ingredientBox">
                <div class="goldenStrip">
                    THÀNH PHẦN
                </div>
                <div class="innerContent">
                    <p class="noMarginBottom">
                        <strong>THÀNH PHẦN:</strong> Sữa không béo, lactose, <strong>DẦU THỰC VẬT</strong>
                        (<sup>‡</sup>chứa: dầu hướng dương giàu oleic, dầu đậu nành, dầu dừa), bột bơ sữa,
                        <strong>KHOÁNG CHẤT</strong> (kali citrat, canxi carbonat, natri clorid, magiê clorid, sắt
                        sulfat, kẽm sulfat,
                        mangan sulfat, canxi clorid, kali hydroxit, kali phosphat, đồng sulfat, natri selenat, kali
                        iodid), 5 HMOs
                        (2’-fucosyllactose, lacto-N-tetraose, 3- fucosyllactose, 6’-sialyllactose, 3’-sialyllactose),
                        acid arachidonic (AA),
                        <strong>VITAMIN</strong> (acid ascorbic, RRR-α- tocopheryl acetat<sup>◊</sup>, niacinamid, canxi
                        d-pantothenat,
                        retinyl palmitat, thiamin hydroclorid, pyridoxin hydroclorid, riboflavin, acid folic,
                        phylloquinon, d-biotin,
                        cholecalciferol, cyanocobalamin), chất nhũ hóa lecithin đậu nành, acid docosahexaenoic (DHA),
                        cholin bitartrat,
                        myo-inositol, NUCLEOTIDES (cytidin 5’-monophosphat, dinatri uridin 5’-monophosphat, adenosin
                        5’-monophosphat,
                        dinatri guanosin 5’- monophosphat), taurin, cholin clorid, <span
                            class="font-italic">Bifidobacterium lactis</span>
                        BB- 12<sup>®*</sup>, ascorbyl palmitat, hỗn hợp tocopherol, <strong>CAROTENOID</strong> (lutein,
                        β- caroten)

                    <p class="noMarginBottom"><sup>◊</sup> Vitamin E tự nhiên</p>
                    <p>* BB-12: thương hiệu của Chr. Hansen</p>
                    <div class="tableOut">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/nutrition_facts2.png"
                            alt="nutrition_facts" />
                    </div>
                </div>
                <div class="goldenStrip">
                    Sử dụng và bảo quản
                </div>
                <div class="innerContent">
                    <p><strong class="text-uppercase">hướng dẫn pha chế và sử dụng:</strong> Sử dụng theo hướng dẫn của
                        nhân viên y tế. Khi pha, cần vệ sinh sạch sẽ, pha và bảo quản đúng cách. Không tuân thủ các
                        hướng dẫn này có thể sẽ gây ảnh hưởng không tốt cho sức khoẻ của bé. Các loại sản phẩm dinh
                        dưỡng công thức đều không tuyệt đối vô trùng, do đó khi dùng cho trẻ sinh non hoặc trẻ có vấn đề
                        về miễn dịch cần phải theo sự hướng dẫn và theo dõi của bác sĩ. Nước dùng để pha phải được đun
                        sôi 5 phút, để nguội và pha cẩn thận theo bảng hướng dẫn kèm theo. Chỉ dùng muỗng có sẵn trong
                        hộp để lường <strong>Similac<sup>®</sup> 2.</strong> Nếu pha hơn một lần dùng thì lượng pha dư
                        phải giữ lạnh ở nhiệt độ 2–40<sup>o</sup>C và dùng trong vòng 24 giờ. Chỉ cho bé dùng tối đa
                        trong vòng 1 giờ, sau đó phải đổ bò phần còn thừa.</p>
                    <p><strong class="text-uppercase">cách pha chuẩn:</strong> Mỗi muỗng gạt ngang bột
                        <strong>Similac<sup>®</sup> 2</strong> pha với 60 ml (2 fl oz) nước hoặc 152,0 g bột pha với
                        nước thành 1 lít.
                    </p>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb1.png"
                                alt="thumb1" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Rửa sạch tất cả các dụng cụ pha, cốc, thìa bằng xà phòng.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb2.png"
                                alt="thumb2" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Rửa lại dưới vòi nước chảy và đun sôi trong 5 phút.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb3.png"
                                alt="thumb3" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Chuẩn bị sạch sẽ nơi pha.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb4.png"
                                alt="thumb4" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Đun sôi nước trong 5 phút bằng một nồi riêng rồi để nguội.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb5.png"
                                alt="thumb5" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Cho chính xác lượng nước ấm cần dùng vào cốc đã được tiệt trùng.
                            </p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb6.png"
                                alt="thumb6" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Lường bằng muỗng có sẵn trong hộp, dùng dao sạch gạt ngang.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb7.png"
                                alt="thumb7" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Mỗi muỗng <strong>Similac<sup>®</sup> 2</strong> đã lường pha với
                                60 ml nước ấm.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb8.png"
                                alt="thumb8" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Khuấy cho tan hoàn toàn. Thử nhiệt độ rồi cho bé dùng.</p>
                        </div>
                    </div>
                    <div class="row mb20 align-items-center">
                        <div class="col-4">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/thumb9.png"
                                alt="thumb9" />
                        </div>
                        <div class="col-8">
                            <p class="noMarginBottom">Đổ bỏ phần còn thừa nếu bé dùng kéo dài hơn 1 giờ.</p>
                        </div>
                    </div>
                    <p><strong>BẢO QUẢN:</strong> Bảo quản hộp chưa mở nắp ở nhiệt độ phòng. Hộp đã mở nắp nên sử dụng
                        trong vòng 3 tuần. Đậy nắp và bảo quản nơi khô mát (không cất trong tủ lạnh).</p>
                    <p><strong>CẨN TRỌNG:</strong> Không dùng lò vi sóng để pha hay hâm nóng vì có thể gây bỏng.</p>
                    <p>© 2021 Abbott<br> <strong>Kiểm tra ngày sản xuất (MFG) và hạn sử dụng (EXP) ở đáy hộp.</strong>
                    </p>
                    <p><strong class="text-uppercase">Số lần đề nghị sử dụng:</strong></p>
                    <div class="tableOut">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/number_of_uses2.png"
                            alt="number_of_uses" />
                    </div>
                    <p>
                        Nếu bác sĩ có hướng dẫn về lượng dùng phù hợp cho trẻ, cần theo đúng hướng dẫn.
                        <br><sup>†</sup>Bé ở độ tuổi này nên dùng bổ sung các loại thức ăn khác.
                    </p>
                    <p><strong>List No.: Y571 501-996-000​</strong></p>
                    <p><strong>SĐK: 9453/2021/ĐKSP</strong></p>
                    <p><strong>Sản xuất tại:</strong> Abbott Ireland, Cootehill, Co. Cavan, Ireland</p>
                    <p>A subsidiary of Abbott Laboratories, North Chicago, IL 60064, USA</p>
                    <p><strong>Đăng ký bởi:</strong> VPĐD Abbott Laboratories GmbH, Tầng 7-8, tháp A, toà nhà Handi
                        Resco, 521 Kim Mã, Ba Đình, Hà Nội.</p>
                    <p><strong>Nhập khẩu và phân phối bởi:</strong></p>
                    <p>Công ty TNHH Dinh Dưỡng 3A (Việt Nam), Centec Tower, 72-74 Nguyễn Thị Minh Khai, phường Võ Thị
                        Sáu, quận 3, thành phố Hồ Chí Minh</p>
                </div>
            </div>
        </div>
    </section>
    <!-- product - similac2 ends here -->

    <!-- product - similac3 starts here -->
    <section class="productSimilac1Container" *ngIf="subCatCode == '2016'">
        <div class="mainInner">
            <div class="lightBlueBg">
                <span class="mediumSandalForeColor">giai đoạn vàng</span> để phát triển trí não tối ưu, bắt đầu từ việc
                xây dựng <span class="mediumSandalForeColor">Một hệ miễn dịch khoẻ mạnh</span>
            </div>
            <div class="blueHeaderImageOut">
                <div class="blueHeaderBg">
                    Khả năng miễn dịch bẩm sinh & đáp ứng ngày một mạnh mẽ hơn
                </div>
                <div class="imgOut">
                    <img class="img-fuid w-100" src="../../../assets/images/prd-similac/img1.png" alt="img1" />
                </div>
            </div>
            <div class="blueHeaderImageOut">
                <div class="blueHeaderBg">
                    Từ 2-3 tuổi, trọng lượng não bộ của trẻ đã đạt tới <span class="whiteTxt">70-80%</span> trọng lượng
                    não người trưởng thành
                </div>
                <div class="imgOut">
                    <img class="img-fuid w-100" src="../../../assets/images/prd-similac/img2.png" alt="img2" />
                </div>
            </div>
        </div>
        <div class="goldenBg goldenBgWithCurve">
            <div class="similacImgOut">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/similac3.png" alt="similac3" />
            </div>
            <p class="aboutPrd">Sản phẩm dinh dưỡng công thức <br>cho trẻ 1-2 tuổi: Similac 3</p>
            <div class="similac1ContentOut">
                <h1>Similac 3</h1>
                <h5>SẢN PHẨM DINH DƯỠNG CÔNG THỨC CHO TRẺ 1–2 TUỔI​​​</h5>
                <p class="font-italic"><strong>(*) Các thông tin là những thông tin trên nhãn đã được cơ quan y tế có
                        thẩm quyền phê duyệt.</strong></p>
                <p><strong>CHÚ Ý:</strong> Sữa mẹ là thức ăn tốt nhất cho sức khỏe và sự phát triển toàn diện của trẻ
                    nhỏ. Các yếu tố chống nhiễm khuẩn, đặc biệt là kháng thể chỉ có trong sữa mẹ có tác dụng giúp trẻ
                    phòng, chống bệnh tiêu chảy, nhiễm khuẩn đường hô hấp và một số bệnh nhiễm khuẩn khác.</p>
                <p>Chỉ sử dụng sản phẩm này theo chỉ dẫn của bác sĩ. Pha chế theo đúng hướng dẫn. Cho trẻ ăn bằng cốc,
                    thìa hợp vệ sinh.</p>

                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/gangliosides.png" alt="gangliosides" /></i>
                    Hệ dưỡng chất prodi-g<sup>TM</sup> giúp phát triển não bộ
                </div>
                <div class="goldenStrip">
                    <span class="text-uppercase">Bổ sung gangliosides</span>
                </div>
                <p class="secondaryForeColor"><strong>Similac<sup>®</sup> 3</strong> chứa hệ dưỡng chất đặc biệt
                    <strong>Prodi-G<sup>TM</sup></strong> nay được bổ sung thêm <strong>gangliosides</strong> - là một
                    thành phần chất béo quan trọng của màng tế bào thần kinh trong não bộ giúp hỗ trợ phát triển nhận
                    thức ở trẻ.
                </p>
                <div class="goldenStrip">
                    <span class="text-uppercase">bộ 3 dưỡng chất vàng</span>
                </div>
                <p class="secondaryForeColor">Phối hợp khoa học bộ 3 dưỡng chất vàng cho não bộ gồm <strong>lutein,
                        vitamin E tự nhiên, DHA</strong>, cùng với AA, omega 3, omega 6, taurin và cholin giúp trẻ phát
                    triển tốt não bộ.</p>
                <div class="goldenStrip">
                    <span class="text-uppercase">tăng cường hấp thu</span>
                </div>
                <p class="secondaryForeColor">Hệ chất béo đặc biệt không chứa dầu cọ<sup>‡</sup> giúp tăng cường hấp thu
                    các dưỡng chất quan trọng cho não bộ (DHA và AA).</p>

                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/5_hmos.png" alt="5_hmos" /></i>
                    Giúp Tăng cường sức đề kháng
                </div>
                <div class="goldenStrip">
                    <span class="text-uppercase">similac<sup>®</sup> 3 nay có 5 hmo</span>s
                </div>
                <p class="noMarginBottom secondaryForeColor">Lớp bảo vệ kép bao gồm:</p>
                <p class="bullet secondaryForeColor noMarginBottom"><strong>HMOs</strong> (Oligosaccharides của sữa mẹ)
                    nuôi dưỡng các vi khuẩn có lợi trong đường tiêu hoá, ngăn chặn các vi sinh vật gây bệnh bám vào tế
                    bào niêm mạc, thúc đẩy hệ miễn dịch trưởng thành, giúp tăng cường sức đề kháng. Bổ sung
                    <strong>HMOs</strong> giúp giảm nguy cơ nhiễm khuẩn ở trẻ nhỏ. <strong>Similac<sup>®</sup>
                        3</strong> nay chứa phức hợp 5 loại <strong>HMOs</strong>
                </p>
                <p class="bullet secondaryForeColor">Phối hợp đặc biệt <strong>nucleotides và probiotics (lợi khuẩn
                        BB-12<sup>®*</sup>)</strong> giúp trẻ tăng cường sức đề kháng.</p>
                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/probiotics.png" alt="probiotics" /></i>
                    Probiotics giúp Tiêu hóa tốt
                </div>
                <div class="goldenStrip text-uppercase">
                    Bổ sung probiotics
                </div>
                <p class="bullet secondaryForeColor noMarginBottom">Phối hợp khoa học <strong>Probiotics (<span
                            class="font-italic">Bifidobacterium lactis</span></strong> BB-12<sup>®*</sup>, là các vi
                    khuẩn có lợi trong đường tiêu hoá) và <strong>HMOs</strong> (Oligosaccharides của sữa mẹ, nuôi dưỡng
                    các vi khuẩn có lợi) giúp hệ tiêu hoá khoẻ mạnh, hỗ trợ tiêu hoá tốt.</p><br>
                <p class="bullet secondaryForeColor">Hệ chất béo đặc biệt không chứa dầu cọ<sup>‡</sup> giúp trẻ hấp thu
                    canxi tốt hơn, ít rối loạn tiêu hoá.</p>
                <div class="row">
                    <div class="col-8">
                        <div class="bt mb10"></div>
                        <strong class="secondaryForeColor">Similac<sup>®</sup> là công thức đầu tiên trên thế giới bổ
                            sung HMOs.<sup>#</sup></strong>
                        <p class="secondaryForeColor noMarginBottom font-size-xs"><sup>#</sup> Theo công bố của Mintel
                            Consulting dựa trên cơ sở dữ liệu lớn nhất thế giới về giới thiệu sản phẩm mới.</p>
                        <p class="secondaryForeColor font-size-xs">* BB-12: thương hiệu của Chr. Hansen</p>
                    </div>
                    <div class="col-4">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/prodi.png" alt="prodi" />
                    </div>
                </div>
            </div>
        </div>
        <div class="blueBg">
            <div class="ingredientBox">
                <div class="goldenStrip">
                    THÀNH PHẦN
                </div>
                <div class="innerContent">
                    <p class="noMarginBottom"><strong>THÀNH PHẦN:</strong> Sữa không béo, lactose, <strong>DẦU THỰC
                            VẬT</strong> (<sup>‡</sup>chứa: dầu hướng dương giàu oleic, dầu đậu nành, dầu dừa), bột bơ
                        sữa, <strong>KHOÁNG CHẤT</strong> (canxi carbonat, kali citrat, natri clorid, tricanxi phosphat,
                        magiê clorid, kali hydroxit, sắt sulfat, kẽm sulfat, mangan sulfat, canxi clorid, đồng sulfat,
                        natri selenat, kali iodid), 5 HMOs
                        (2’-fucosyllactose, lacto-N-tetraose, 3- fucosyllactose, 6’-sialyllactose, 3’-sialyllactose),
                        <strong>VITAMIN</strong> (acid ascorbic, RRR-α- tocopheryl acetat<sup>◊</sup>, canxi
                        d-pantothenat, retinyl palmitat, thiamin hydroclorid, pyridoxin hydroclorid, riboflavin, acid
                        folic, phylloquinon, d-biotin, cholecalciferol, cyanocobalamin), chất nhũ hóa lecithin đậu nành,
                        myo-inositol, acid docosahexaenoic (DHA), hương vani tổng hợp, cholin clorid, taurin, cholin
                        bitartrat, <span class="font-italic">Bifidobacterium lactis</span> BB-12<sup>®*</sup>, acid
                        arachidonic (AA), <strong>NUCLEOTIDES</strong> (cytidin 5’-monophosphat, dinatri uridin
                        5’-monophosphat, adenosin 5’-monophosphat, dinatri guanosin 5’- monophosphat), ascorbyl
                        palmitat, hỗn hợp tocopherol, <strong>CAROTENOID</strong> (lutein, β- caroten)
                    </p>
                    <p class="noMarginBottom"><sup>◊</sup> Vitamin E tự nhiên</p>
                    <p>* BB-12: thương hiệu của Chr. Hansen</p>
                    <div class="tableOut">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/nutrition_facts3.png"
                            alt="nutrition_facts" />
                    </div>
                </div>
                <div class="goldenStrip">
                    Sử dụng và bảo quản
                </div>
                <div class="innerContent">
                    <p><strong class="text-uppercase">hướng dẫn pha chế và sử dụng:</strong> <strong>Similac<sup>®</sup>
                            3</strong> khi pha với nước sẽ được một thức uống dinh dưỡng để hoàn thiện chế độ ăn hàng
                        ngày của trẻ. Rửa tay và dụng cụ thật sạch trước khi pha. Vệ sinh tốt, sử dụng và bảo quản đúng
                        cách rất quan trọng khi bạn chuẩn bị pha <strong>Similac<sup>®</sup> 3</strong> cho trẻ. Đun kỹ
                        nước cho sôi, sau đó để nguội. Lường bằng muỗng đính kèm trong hộp sau đó dùng dao sạch gạt
                        ngang. 1 muỗng <strong>Similac<sup>®</sup> 3</strong> được pha với 60 ml (2 fl oz) nước đun sôi
                        để nguội. Khuấy đều cho hoà tan hoàn toàn. Nếu pha nhiều hơn một lần dùng thì lượng pha dư phải
                        được bảo quản trong tủ lạnh ở 2–40<sup>o</sup>C và dùng trong vòng 24 giờ. Chỉ cho bé dùng tối
                        đa trong vòng 1 giờ, sau đó phải đổ bò phần còn thừa.</p>
                    <p><strong class="text-uppercase">cách pha chuẩn:</strong> Mỗi muỗng gạt ngang bột
                        <strong>Similac<sup>®</sup> 3</strong> pha với 60 ml (2 fl oz) nước hoặc 152,0 g bột pha với
                        nước thành 1 lít.
                    </p>
                    <p><strong class="text-uppercase">BẢO QUẢN:</strong> Bảo quản hộp chưa mở nắp ở nhiệt độ phòng. Hộp
                        đã mở nắp nên sử dụng trong vòng 3 tuần. Đậy nắp và bảo quản nơi khô mát (không cất trong tủ
                        lạnh) </p>
                    <p><strong class="text-uppercase">CẨN TRỌNG:</strong> Không dùng lò vi sóng để pha hay hâm nóng vì
                        có thể gây bỏng.</p>
                    <p><strong class="text-uppercase">LUU Ý:</strong> <strong>Similac<sup>®</sup> 3</strong> đã pha có
                        thể uống bằng ly, hoặc trộn với ngũ cốc hay các thức ăn khác.</p>
                    <p>© 2021 Abbott <br><strong>Kiểm tra ngày sản xuất (MFG) và hạn sử dụng (EXP) ở đáy hộp.</strong>
                    </p>

                    <p><strong class="text-uppercase">Số lần đề nghị sử dụng:</strong></p>
                    <div class="tableOut">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/number_of_uses3.png"
                            alt="number_of_uses" />
                    </div>
                    <p>Nếu bác sĩ có hướng dẫn về lượng dùng phù hợp cho trẻ, cần theo đúng hướng dẫn.</p>
                    <p><strong>List No.:Y573 501-964-000​</strong></p>
                    <p><strong>SĐK: 9461/2021/ĐKSP</strong></p>
                    <p><strong>Sản xuất tại:</strong> Abbott Ireland, Cootehill, Co. Cavan, Ireland</p>
                    <p>A subsidiary of Abbott Laboratories, North Chicago, IL 60064, USA</p>
                    <p><strong>Đăng ký bởi:</strong> VPĐD Abbott Laboratories GmbH, Tầng 7-8, tháp A, toà nhà Handi
                        Resco, 521 Kim Mã, Ba Đình, Hà Nội.</p>
                    <p><strong>Nhập khẩu và phân phối bởi:</strong></p>
                    <p>Công ty TNHH Dinh Dưỡng 3A (Việt Nam), Centec Tower, 72-74 Nguyễn Thị Minh Khai, phường Võ Thị
                        Sáu, quận 3, thành phố Hồ Chí Minh</p>
                </div>
            </div>
        </div>
    </section>
    <!-- product - similac3 ends here -->

    <!-- product - similac4-01 starts here -->
    <section class="productSimilac1Container" *ngIf="subCatCode == '2017'">
        <div class="goldenBg withBottomCurve">
            <div class="transparentBg">
                <p class="title">
                    <span>Công<br>thức</span>đầu tiên và duy nhất<br> trên thế giới với
                </p>
                <p class="small">
                    <i class="flag"><img class="img-fuid w-100" src="../../../assets/images/prd-similac/flag.png"
                            alt="flag" /></i>
                    <span class="text-uppercase">với 5 HMOS</span> <span class="text-uppercase"> và
                        Gangliosides<sup>(*)</sup>.</span>
                </p>
            </div>
            <div class="similacImgOut">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/similac4.png" alt="similac4" />
            </div>
            <p class="aboutPrd">Thực phẩm bổ sung cho trẻ 2–6 tuổi: Similac 4</p>
            <div class="transparentBg">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/sim1.png" alt="similac4" />
                <!-- <h1>Công thức <br>             
                với sức mạnh dinh dưỡng <i class="fiveG"><img class="img-fuid w-100"
                src="../../../assets/images/prd-similac/5g.png" alt="5g" /></i><sup>(1)</sup></h1> -->
            </div>
            <div class="similac1ContentOut">
                <div class="listOut">
                    <h5>Công thức ưu việt của Similac<sup>®</sup> 4**, kết hợp bộ 5 dưỡng chất vàng:</h5>
                    <ul>
                        <li>
                            - 5 HMOs - lần đầu tiên tại Việt Nam<sup>**</sup>
                        </li>
                        <li>
                            - Gangliosides
                        </li>
                        <li>
                            - Probiotic BB-12<sup>®</sup>
                        </li>
                        <li>
                            - Nucleotides
                        </li>
                        <li>
                            - Bộ ba sức mạnh: DHA + Lutein + Vitamin E tự nhiên
                        </li>
                    </ul>
                </div>
                <p class="survey">* Dựa trên khảo sát của Mintel từ cơ sở dữ liệu GNPD, thực hiện vào ngày 29 tháng 9
                    năm 2021 trên toàn cầu trong ngành hàng sữa công thức
                    <br>** So với các công thức Similac 4 trước đây
                    <br /> (1) 5G (5 Great nutrients): 5 dưỡng chất quan trọng - 5 HMO<sub>s</sub>, Gangliosides, DHA,
                    Lutein, Vitamin E tự nhiên.
                </p>
                <div class="blueStrip mb10">
                    <i class="custom"><img src="../../../assets/images/prd-similac/3img.png" alt="5g" /></i>
                    <h1>Sức mạnh miễn dịch <small>với 3 lớp bảo vệ</small></h1>
                    <span>Với 5HMOs, probiotic BB-12<sup>®</sup> & nucleotides<sup>2-4</sup></span>
                </div>
                <ul class="lists">
                    <li>
                        (2) Jungersen, et al. Microorganisms. 2014;2:92-110
                    </li>
                    <li>
                        (3) Triantis et al. 2018. Front. Pediatr.
                    </li>
                    <li>
                        (4) Pickering et al. 1998. Pediatrics.
                    </li>
                </ul>
                <div class="blueStrip mb10">
                    <i><img src="../../../assets/images/prd-similac/5g1.png" alt="5g" /></i>
                    <h1>IQ cao hơn</h1>
                    <span class="noMargin">với gangliosides<sup>(5)</sup></span>
                </div>
                <ul class="lists">
                    <li>
                        (5) Gurnida, D. A. et al. Early Hum. Dev. 88, 595—601 (2012) so với nhóm không bổ sung
                        Gangliosides
                    </li>
                </ul>
                <div class="blueStrip mb10">
                    <i><img src="../../../assets/images/prd-similac/probiotics_golden.png" alt="5g" /></i>
                    <h1>Tiêu hoá tốt hơn</h1>
                    <span>Với 5 HMOs<sup>(6)</sup>, probiotic BB-12<sup>®(7)</sup></span>
                </div>
                <ul class="lists">
                    <li>
                        (6) McGuire et al. (2017); McJarrow et al. (2019)
                    </li>
                    <li>
                        (7) Chouraqui et al_2004 Acidified milk formula supplemented with bifidobacterium lactis -
                        impact on infant diarrhea in residential care settings
                    </li>
                </ul>
            </div>
        </div>
        <div class="mainInner">
            <div class="lightBlueBg">
                <span class="mediumSandalForeColor">giai đoạn vàng</span> để phát triển trí não tối ưu, bắt đầu từ việc
                xây dựng <span class="mediumSandalForeColor">Một hệ miễn dịch khoẻ mạnh</span>
            </div>
            <div class="blueHeaderImageOut">
                <div class="blueHeaderBg">
                    Thiếu cơ hội khám phá môi trường bên ngoài trong thời gian dài ảnh hưởng tiêu cực đến sự phát triển
                    hệ miễn dịch và trí não của bé
                </div>
                <div class="imgOut">
                    <img class="img-fuid w-100" src="../../../assets/images/prd-similac/img3.png" alt="img3" />
                </div>
            </div>
            <div class="blueHeaderImageOut">
                <div class="blueHeaderBg">
                    Để ứng biến trước tương lai đầy biến động của kỷ nguyên số, 5G và trí tuệ nhân tạo, NÃO BỘ trẻ cần
                    xử lý thông tin nhanh hơn
                </div>
            </div>
            <div class="brainsProcess">
                <div class="row">
                    <div class="col-12">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/brain.png" alt="brain" />
                    </div>
                </div>
            </div>
        </div>
        <div class="yellowBg">
            <div class="header5g">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/header_5g.png" alt="header 5G" />
            </div>
            <div class="similac4Img">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/similac4_5g.png"
                    alt="similac4 5G" />
            </div>
            <div class="contents">
                <p>Thế giới tương lai của con đang thay đổi từng ngày <br>
                    Nhiều biến động…<br>
                    Phức tạp hơn…<br>
                    Khó lường trước…<br>
                    Làm thế nào để con tự tin đón đầu những đổi thay của tương lai?</p>

                <p><strong class="secondaryForeColor">Thế hệ tài trí tương lai, nhanh trí tựa “5G”</strong><br>
                    Khi thách thức tấn công hiện tại với tốc độ 4G<br>
                    Con hãy tự tin đón đầu với tốc độ 5G <br>
                    cùng nền tảng vững vàng mẹ trang bị</p>
            </div>
            <div class="transparentBg">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/transparent_bg.png"
                    alt="transparent" />
            </div>
            <div class="yellowBorderWhiteBg">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/similac4_1.png" alt="similac 4" />
            </div>
            <p class="font-size-xs secondaryForeColor text-center">(1) 5G (5 Great nutrients): 5 dưỡng chất quan trọng -
                5HMOs, Gangliosides, DHA, Lutein, Vitamin E tự nhiên.</p>
            <p class="text-center">Công thức đột phá của Similac 4 cung cấp khả năng bảo vệ vượt trội đến từ</p>
            <div class="triangleOut">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/triangle1.PNG" alt="triangle" />
            </div>
            <p class="font-size-xs secondaryForeColor">
                + So với nhóm đối chứng không có HMO hoặc không được tăng cường Nucleotides<br>
                <sup>*</sup> So với các công thức Similac 4 trước đây<br>
                (2) McGuire et al. (2017); McJarrow et al. (2019)<br>
                (3) Đề cập đến lượng lợi khuẩn đã được thêm vào công thức.<br>
                (4) Pickering, L.K., D.M. Granoff, J.R. Erickson, M.L. Masor, C.T. Cordle, J.P. Schaller, T.R. Winship,
                C.L. Paule, and M.D. Hilty, Modulation of the immune system by human milk and infant formula containning
                nucleotides. Pediatrics, 1998. 101(2): p. 242-249.<br>
                (5) Buck, R.H., D.L. Thomas, T.R. Winship, C.T. Cordle, M.J. Kuchan, G.E. Baggs, J.P. Schaller, and J.G.
                Wheeler, Effect of dietary ribonucleotides on infant immune status. Part 2: Immune cell development.
                Pediatr Res, 2004. 56(6): p.891-900.
            </p>
            <div class="verticalYellowTiles">
                <div class="row mb20 align-items-center">
                    <div class="col-12">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/kid_shield.png"
                            alt="kid_shield" />
                    </div>
                </div>
            </div>
            <p class="font-size-xs secondaryForeColor">
                *5G (5 Great Nutrients) 5 dưỡng chất quan trọng: 5HMOs, Gangliosides, DHA, Lutein, Vitamin E tự
                nhiên<br>
                (2) Triantis et al. 2018. Front. Pediatr. <br>
                (3) Pickering et al. 1998. Pediatrics<br>
                (4) Jungersen, et al. Microorganisms. 2014;2:92-110
            </p>
            <div class="yellowBorderWhiteBg">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/similac4_2.png" alt="similac 4" />
            </div>
            <div class="verticalYellowTiles">
                <div class="row align-items-center">
                    <div class="col-12">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/brain_growth.png"
                            alt="brain growth" />
                    </div>
                </div>
            </div>
            <p class="font-size-xs secondaryForeColor mt20">
                ^So với nhóm chứng không đươc bổ sung DHA và Lutein<br>
                #Dựa trên các nghiên cứu của Lutein và các nghiên cứu về DHA (các nghiên cứu về DHA, được tóm tắt trong
                một đánh giá về nghiên cứu thử nghiệm lâm sàng ngẫu nhiên ở trẻ sơ sinh về thị giác và nhận thức -
                Hoffman và cộng sự 2009)<br>
                (1) 5G (5 Great Nutrients) 5 dưỡng chất quan trọng: 5HMOs, Gangliosides, DHA, Lutein, Vitamin E tự
                nhiên<br>
                (2) Gurnida, D. A. et al. Early Hum. Dev. 88, 595–601 (2012) so với nhóm không bổ sung Gangliosides<br>
                <span>(3) Berger, PL et al. PLOS ONE \</span> <a class="secondaryForeColor"
                    href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0228323" target="_blank">
                    https://journals.plos.org/<br />plosone/article?id=10.1371/journal.pone.0228323</a>
            </p>

            <div class="imgWrap pr mb60">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/chart1.png" alt="chart" />
                <p class="dynamic">Nhóm sử dụng công thức bổ sung <span>Gangliosides</span> có chỉ số IQ cao
                    hơn<sup>(+,2)</sup></p>
            </div>
            <div class="imgWrap">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/chart2.png" alt="chart" />
            </div>
            <p class="font-size-xs secondaryForeColor mt20">
                + Kết quả từ nghiên cứu Gangliosides.<br>
                (2) Gurnida, D. A. et. al.. Early Hum. Dev. 88, 595-601(2012) so với nhóm không bổ sung Gangliosides<br>
                (3) Renzi, L.Mand B.R. Hammond, Ophthalmic and Physiological Optics, 2010. 30(4): p. 351-357.
            </p>
            <div class="imgWrap">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/similac4_3.png" alt="similac4_3" />
            </div>
            <div class="imgWrap">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/triangle2.png" alt="triangle" />
            </div>
            <p class="font-size-xs secondaryForeColor mt20 text-center">
                *So với các công thức Similac 4 trước đây<br>
                (2) McGuire et al. (2017); McJarrow et al. (2019)<br>
                (3) Uauy R. Textbook of gastroenterology and nutrition in infants, 2nd ed. Dietary nucleotides and
                requirements in early life. 1989
            </p>
            <div class="imgWrap">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/img7.png" alt="img" />
            </div>
            <p class="font-size-xs secondaryForeColor mt20 text-center">
                (4) Alarcon et al. Gastrointestinal tolerance of a new infant milk formula in healthy babies: An
                international study conducted in 17 countries Nutrition 2002;18:484-489.16. | Borschel MW, et al. Open
                Nutr J.2012;6.
            </p>
            <div class="transparentBg noTransparentBg noMargin pTop">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/similac_moi.png"
                    alt="similac_4_moi" />
            </div>
            <p class="font-size-xs secondaryForeColor text-center">
                (6) 5G (5 Great Nutrients) 5 dưỡng chất quan trọng: 5HMOs, Gangliosides, DHA, Lutein, Vitamin E tự nhiên
            </p>
            <div class="imgWrap">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/img8.png" alt="img" />
            </div>
            <div class="imgWrap">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/img9.png" alt="img" />
            </div>
        </div>
        <div class="blueGradientBg">
            <div class="imgWrap">
                <img class="img-fuid w-100" src="../../../assets/images/prd-similac/sim.png" alt="sim" />
            </div>
            <h5>*Thông tin dưới đây là thông tin trên nhãn sản phẩm đã được cơ quan quản lý có thẩm quyền tiếp nhận</h5>
            <div class="yellowGradientBg">
                <div class="topCurve">
                    <!-- <div class="topDropShapeSimilac4"></div> -->
                </div>
                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/gangliosides.png" alt="gangliosides" /></i>
                    Hệ dưỡng chất prodi-g<sup>TM</sup> <br /> giúp phát triển não bộ
                </div>
                <div class="goldenStrip flag mt40">
                    <span class="text-uppercase">Bổ sung gangliosides</span>
                </div>
                <p class="secondaryForeColor mt20 mb20"><strong>Similac<sup>®</sup> 4</strong> chứa hệ dưỡng chất đặc
                    biệt <br /> <strong>Prodi-G<sup>TM</sup></strong> nay được bổ sung thêm<strong>
                        gangliosides</strong> - là một thành phần chất béo quan trọng của màng tế bào thần kinh trong
                    não bộ giúp hỗ trợ phát triển nhận thức ở trẻ.</p>
                <div class="goldenStrip text-uppercase">
                    bộ 3 dưỡng chất vàng
                </div>
                <p class="secondaryForeColor mt20 mb20">Phối hợp khoa học bộ 3 dưỡng chất vàng cho não bộ gồm
                    <strong>lutein, vitamin E tự nhiên, DHA</strong>, cùng với AA, omega 3, omega 6, taurin và cholin
                    giúp trẻ phát triển tốt não bộ.
                </p>
                <div class="goldenStrip text-uppercase">
                    tăng cường hấp thu
                </div>
                <p class="secondaryForeColor mt20 mb20">Hệ chất béo đặc biệt không chứa dầu cọ<sup>‡</sup> giúp tăng
                    cường hấp thu các dưỡng chất quan trọng cho não bộ (DHA và AA).</p>
                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/5_hmos.png" alt="5 hmos" /></i>
                    Giúp Tăng cường sức đề kháng
                </div>
                <div class="goldenStrip flag mt40">
                    <span class="text-uppercase">similac<sup>®</sup> 4 nay có 5 hmo</span>s
                </div>
                <p class="secondaryForeColor mt20 mb20">
                    Lớp bảo vệ kép bao gồm:<br>
                    <strong>• HMOs</strong> (Oligosaccharides của sữa mẹ) nuôi dưỡng các vi khuẩn có lợi trong đường
                    tiêu hoá, ngăn chặn các vi sinh vật gây bệnh bám vào tế bào niêm mạc, thúc đẩy hệ miễn dịch trưởng
                    thành, giúp tăng cường sức đề kháng. Bổ sung <strong>HMOs</strong> giúp giảm nguy cơ nhiễm khuẩn ở
                    trẻ nhỏ. <strong>Similac<sup>®</sup> 4</strong> nay chứa phức hợp 5 loại <strong>HMOs</strong><br>
                    • Phối hợp đặc biệt <strong>nucleotides và probiotics (lợi khuẩn BB-12<sup>®*</sup>)</strong> giúp
                    trẻ tăng cường sức đề kháng.
                </p>
                <div class="blueStrip">
                    <i><img src="../../../assets/images/prd-similac/probiotics.png" alt="probiotics" /></i>
                    Probiotics giúp Tiêu hóa tốt
                </div>
                <div class="goldenStrip flag mt40 text-uppercase">
                    Bổ sung probiotics
                </div>
                <p class="secondaryForeColor mt20 mb20">
                    • Phối hợp khoa học <strong>Probiotics (Bifidobacterium lactis</strong> BB-12<sup>®*</sup>, là các
                    vi khuẩn có lợi trong đường tiêu hoá) và HMOs (Oligosaccharides của sữa mẹ, nuôi dưỡng các vi khuẩn
                    có lợi) giúp hệ tiêu hoá khoẻ mạnh, hỗ trợ tiêu hoá tốt.<br>
                    • Hệ chất béo đặc biệt không chứa dầu cọ<sup>‡</sup> giúp trẻ hấp thu canxi tốt hơn, ít rối loạn
                    tiêu hoá.
                </p>
                <div class="row">
                    <div class="col-8">
                        <div class="bt mb10"></div>
                        <strong class="secondaryForeColor">Similac<sup>®</sup> là công thức đầu tiên trên thế giới bổ
                            sung HMOs.<sup>#</sup></strong>
                        <p class="secondaryForeColor noMarginBottom font-size-xs"><sup>#</sup> Theo công bố của Mintel
                            Consulting dựa trên cơ sở dữ liệu lớn nhất thế giới về giới thiệu sản phẩm mới.</p>
                        <p class="secondaryForeColor font-size-xs">* BB-12: thương hiệu của Chr. Hansen</p>
                    </div>
                    <div class="col-4">
                        <img class="img-fuid w-100" src="../../../assets/images/prd-similac/prodi.png" alt="prodi" />
                    </div>
                </div>
            </div>
            <div class="plainBlueBg">
                <div class="ingredientBox">
                    <div class="goldenStrip">
                        THÀNH PHẦN
                    </div>
                    <div class="innerContent">
                        <p class="noMarginBottom"><strong>THÀNH PHẦN:</strong> Sữa không béo, lactose, <strong>DẦU THỰC
                                VẬT</strong> (<sup>‡</sup>chứa: dầu hướng dương giàu oleic, dầu đậu nành, dầu dừa), đạm
                            whey cô đặc, bột bơ sữa, <strong>KHOÁNG CHẤT</strong> (kali citrat, canxi carbonat, magiê
                            clorid, kali hydroxit, sắt sulfat, natri clorid, kẽm sulfat, đồng sulfat, canxi clorid,
                            tricanxi phosphat, mangan sulfat, kali iodid, natri selenat), đạm whey thủy phân, 5 HMOs
                            (2’-fucosyllactose, lacto-N-tetraose, 3- fucosyllactose, 6’-sialyllactose,
                            3’-sialyllactose), acid arachidonic (AA), <strong>VITAMIN</strong> (acid ascorbic, RRR-α-
                            tocopheryl acetat<sup>◊</sup>, niacinamid, canxi d-pantothenat, retinyl palmitat, thiamin
                            hydroclorid, pyridoxin hydroclorid, riboflavin, acid folic, phylloquinon, d-biotin,
                            cholecalciferol, cyanocobalamin), chất nhũ hóa lecithin đậu nành, acid docosahexaenoic
                            (DHA), cholin bitartrat, <strong>NUCLEOTIDES</strong> (cytidin 5’-monophosphat, dinatri
                            guanosin 5’- monophosphat, dinatri uridin 5’-monophosphat, adenosin 5’-monophosphat), cholin
                            clorid, taurin, L-tryptophan, myo-inositol, ascorbyl palmitat, <span
                                class="font-italic">Bifidobacterium lactis BB- 12</span><sup>®*</sup>, hỗn hợp
                            tocopherol, L- carnitin, <strong>CAROTENOID</strong> (lutein, β- caroten)</p>
                        <p class="noMarginBottom"><sup>◊</sup> Vitamin E tự nhiên</p>
                        <p>* BB-12: thương hiệu của Chr. Hansen</p>
                        <div class="tableOut">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/S4_science_facts.png"
                                alt="S4_science_facts" />
                        </div>
                    </div>
                    <div class="goldenStrip">
                        Sử dụng và bảo quản
                    </div>
                    <div class="innerContent">
                        <p><strong class="text-uppercase">hướng dẫn pha chế và sử dụng:</strong>
                            <strong>Similac<sup>®</sup> 4</strong> khi pha với nước sẽ được một thức uống dinh dưỡng để
                            hoàn thiện chế độ ăn hàng ngày của trẻ. Rửa tay và dụng cụ thật sạch trước khi pha. Vệ sinh
                            tốt, sử dụng và bảo quản đúng cách rất quan trọng khi bạn chuẩn bị pha
                            <strong>Similac<sup>®</sup> 4</strong> cho trẻ. Đun kỹ nước cho sôi, sau đó để nguội. Lường
                            bằng muỗng đính kèm trong hộp sau đó dùng dao sạch gạt ngang. 1 muỗng
                            <strong>Similac<sup>®</sup> 4</strong> được pha với 60 ml (2 fl oz) nước đun sôi để nguội.
                            Khuấy đều cho hoà tan hoàn toàn. Nếu pha nhiều hơn một lần dùng thì lượng pha dư phải được
                            bảo quản trong tủ lạnh ở 2–40<sup>o</sup>C và dùng trong vòng 24 giờ. Chỉ cho bé dùng tối đa
                            trong vòng 1 giờ, sau đó phải đổ bò phần còn thừa.
                        </p>
                        <p><strong class="text-uppercase">cách pha chuẩn:</strong> Mỗi muỗng gạt ngang bột
                            <strong>Similac<sup>®</sup> 4</strong> pha với 60 ml (2 fl oz) nước hoặc 152,0 g bột pha với
                            nước thành 1 lít.
                        </p>
                        <p><strong class="text-uppercase">BẢO QUẢN:</strong> Bảo quản hộp chưa mở nắp ở nhiệt độ phòng.
                            Hộp đã mở nắp nên sử dụng trong vòng 3 tuần. Đậy nắp và bảo quản nơi khô mát (không cất
                            trong tủ lạnh) </p>
                        <p><strong class="text-uppercase">CẨN TRỌNG:</strong> Không dùng lò vi sóng để pha hay hâm nóng
                            vì có thể gây bỏng.</p>
                        <p><strong class="text-uppercase">LUU Ý:</strong> <strong>Similac<sup>®</sup> 4</strong> đã pha
                            có thể uống bằng ly, hoặc trộn với ngũ cốc hay các thức ăn khác.</p>
                        <p>© 2021 Abbott <br><strong>Kiểm tra ngày sản xuất (MFG) và hạn sử dụng (EXP) ở đáy
                                hộp.</strong></p>

                        <p><strong class="text-uppercase">Số lần đề nghị sử dụng:</strong></p>
                        <div class="tableOut">
                            <img class="img-fuid w-100" src="../../../assets/images/prd-similac/science_table.png"
                                alt="science table" />
                        </div>
                        <p>Nếu bác sĩ có hướng dẫn về lượng dùng phù hợp cho trẻ, cần theo đúng hướng dẫn.</p>
                        <p><strong>List No.:Y574 501-966-000​</strong></p>
                        <p><strong>TCCS: 01/ABBOTT/2021</strong></p>
                        <p><strong>Sản xuất tại:</strong> Abbott Ireland, Cootehill, Co. Cavan, Ireland</p>
                        <p>A subsidiary of Abbott Laboratories, North Chicago, IL 60064, USA</p>
                        <p><strong>Công bố bởi:</strong> VPĐD Abbott Laboratories GmbH, Tầng 7-8, tháp A, toà nhà Handi
                            Resco, 521 Kim Mã, Ba Đình, Hà Nội.</p>
                        <p><strong>Nhập khẩu và phân phối bởi:</strong></p>
                        <p>Công ty TNHH Dinh Dưỡng 3A (Việt Nam), Centec Tower, 72-74 Nguyễn Thị Minh Khai, phường Võ
                            Thị Sáu, quận 3, thành phố Hồ Chí Minh</p>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <!-- product - similac4-01 ends here -->

   <!--  similac-01-start-->
<section class="similac-vigor similac-1" *ngIf="subCatCode == '2018'">
    <img
      class="img-fluid hero-banner w-100"
      src="../../../assets/images/similac-vigor/similac-1/hero-banner-similac-1.png"
    />
    <div class="similac-product">
      <div class="similac-inner-div pt20">
        <img
          class="img-fluid product-img w-100"
          src="../../../assets/images/similac-vigor/similac-1/product-img-similac-1.png"
        />
        <img
          class="img-fluid w-100"
          src="../../../assets/images/similac-vigor/similac-1/product-img-bg.png"
        />
        <div class="content-wrapper">
          <div class="text-content">
            <h3 class="pt30">
              Sản phẩm dinh dưỡng công thức cho trẻ 0-6 tháng tuổi Similac Total
              Protection 1
            </h3>
            <div class="m20 mb10 bordered-box">
              <p class="mb-3">
                <span>CHÚ Ý:</span> Sữa mẹ là thức ăn tốt nhất cho sức khỏe và sự
                phát triển toàn diện của trẻ nhỏ. Các yếu tố chống nhiễm khuẩn,
                đặc biệt là kháng thể chỉ có trong sữa mẹ có tác dụng giúp trẻ
                phòng, chống bệnh tiêu chảy, nhiễm khuẩn đường hô hấp và một số
                bệnh nhiễm khuẩn khác.
              </p>
              <p>
                Chỉ sử dụng sản phẩm này theo chỉ dẫn của bác sĩ. Pha chế theo
                đúng hướng dẫn. Cho trẻ ăn bằng cốc, thìa hợp vệ sinh.
              </p>
            </div>
  
            <p class="text-sm mb-20 w-100">
              *Thông tin dưới đây là thông tin trên nhãn đã được các cơ quan thẩm
              quyền phê duyệt
            </p>
  
            <p class="mt20">
              <span>CÔNG DỤNG:</span> Similac<sup>®</sup> Total Protection 1 dùng
              bổ sung dinh dưỡng hoặc thay thế bữa ăn cho trẻ 0 - 6 tháng tuổi bị
              thiếu hoặc mất sữa mẹ, cung cấp các dưỡng chất giúp phát triển tốt
              não bộ, tăng cường sức đề kháng và hệ tiêu hóa khỏe mạnh.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="similac-total-protection mt20">
      <img
        class="img-fluid tt-pro"
        src="../../../assets/images/similac-vigor/similac-1/similac-total-protection.png"
      />
      <div class="total-protection-details mt10">
        <img
          class="img-fluid w-100"
          src="../../../assets/images/similac-vigor/similac-1/similac-total-protection-details.png"
        />
      </div>
  
      <div class="total-protection-text">
        <p class="mt20 pb20">
          <span>THÀNH PHẦN:</span> Sữa không béo, lactose,
          <span>DẦU THỰC VẬT</span> (^chứa: dầu hướng dương giàu oleic, dầu đậu
          nành, dầu dừa), phức hợp oligosaccharid (<span>5 loại HMO:</span>
          2'-fucosyllactose, lacto-N-tetraose, 3-fucosyllactose, 6'-sialyllactose,
          3'-sialyllactose), <span>KHOÁNG CHẤT</span> (tricanxi phosphat, canxi
          carbonat, kali citrat, natri clorid, kali hydroxid, sắt sulfat, kẽm
          sulfat, canxi clorid, magiê clorid, đồng sulfat, mangan sulfat, kali
          iodid, natri selenat), <span>VITAMIN</span> (acid ascorbic, ascorbyl
          palmitat, niacinamid, RRR- -tocopheryl acetat , canxi d-pantothenat,
          retinyl palmitat, thiamin hydroclorid, pyridoxin hydroclorid,
          riboflavin, acid folic, phylloquinon, d-biotin, cholecalciferol,
          cyanocobalamin), chất nhũ hóa lecithin đậu nành, myo-lnositol,
          <span>acid docosahexaenoic (DHA)</span>, hương vani tổng hợp, cholin
          clorid, cholin bitartrat, taurin, <span>NUCLEOTID</span> (cytidin
          5'-monophosphat, dinatri uridin 5'-monophosphat, adenosin
          5'-monophosphat, dinatri guanosin 5'-monophosphat), acid arachidonic
          (AA), <span>Bifidobacterium lactis </span>(BB-12<sup>®</sup>) , hỗn hợp
          tocopherol, L-carnitin, <span>CAROTENOID</span> (lutein, β-caroten).<br />
          <br />
          <span>Vitamin E tự nhiên.</span><br />
          BB-12: thương hiệu của Chr. Hansen<br />
          <br />
  
          <span>BẢO QUẢN:</span> Bảo quản hộp chưa mở nắp ở nhiệt độ phòng. Hộp đã
          mở nắp nên sử dụng trong vòng 3 tuần. Đậy nắp kín và bảo quản nơi khô
          mát (không để trong tủ lạnh). <br />
  
          <span>CẨN TRỌNG:</span> Không được dùng lò vi sóng để pha hay hâm nóng
          vì có thể gây bỏng. <br />
  
          <span>CÁCH PHA CHUẨN:</span> Mỗi muỗng gạt ngang bột
          <span>Similac<sup>®</sup> Total Protection 1</span> pha với 60 ml (2 fl
          oz) nước hoặc 126,3 g bột pha với nước thành 1 lít. Đóng gói trong môi
          trường khí trơ.
        </p>
        <img
          class="img-fluid w-100"
          src="../../../assets/images/similac-vigor/similac-1/similac-1-contents-chart.png"
        />
        <p class="mt-2">
          <span>Sản xuất tại:</span> Abbot Ireland, Cootehill, Co. Cavan, Ireland
          A subsidiary of Abbott Laboratories, North Chicago, IL 60064, USA<br /> <span>Xuất
          xứ:</span> Ai-len<br />
          <span>Đăng ký bởi:</span> VPĐD Abbott Laboratories GmbH, Tầng 7-8, tháp
          A, tòa nhà Handi Resco, 521 Kim Mã, Ba Đình, Hà Nội.
        </p>
        <div class="d-flex align-items-center">
          <div>
            <img
              class="img-fluid w-100"
              src="../../../assets/images/similac-vigor/similac-1/Similac-3A-icon.png"
            />
          </div>
          <div>
            <p class="ml10">
              <span>Nhập khẩu và phân phối bởi:</span> Công ty TNHH Dinh Dưỡng 3A
              (Việt Nam), Centec Tower, 72-74 Nguyễn Thị Minh Khai, phường Võ Thị
              Sáu, quận 3, thành phố Hồ Chí Minh.
            </p>
          </div>
        </div>
  
        <h4 class="mt20 text-left">HƯỚNG DẪN PHA CHẾ VÀ SỬ DỤNG:</h4>
        <p>
          Sử dụng theo hướng dẫn của nhân viên y tế. Khi pha, cần vệ sinh sạch sẽ,
          pha và bảo quản đúng cách. Không tuân thủ các hướng dẫn này có thể sẽ
          gây ảnh hưởng không tốt cho sức khoẻ của bé. Các loại sản phẩm dinh
          dưỡng công thức đều không tuyệt đối vô trùng, do đó khi dùng cho trẻ
          sinh non hoặc trẻ có vấn đề về miễn dịch cần phải theo sự hướng dẫn và
          theo dõi của bác sĩ. Nước dùng để pha phải được đun sôi 5 phút, để nguội
          và phạ cẩn thận theo bảng hướng dẫn kèm theo. Chỉ dùng muỗng có sẵn
          trong hộp để lường <span>Similac<sup>®</sup> Total Protection 1.</span> Nếu pha hơn một lần dùng
          thì lượng pha dư phải giữ lạnh ở nhiệt độ 2-4°C và dùng trong vòng 24
          giờ. Chỉ cho bé dùng tối đa trong vòng 1 giờ, sau đó phải đổ bỏ phần còn
          thừa. <br />Không dùng cho trẻ bị bệnh galactosemia.<br />
        </p>
        <img
          class="img-fluid w-100 mt20"
          src="../../../assets/images/similac-vigor/similac-1/similac-1-how-to-use.png"
        />
        <img
          class="img-fluid w-100 mt20"
          src="../../../assets/images/similac-vigor/similac-1/similac1-so-lan-de-nghi.png"
        />
        <p class="mt20">
          Nên hỏi bác sĩ về lượng dùng phù hợp cho bé. Đây là lượng dùng đề nghị,
          mỗi bé có thể cần lượng dùng khác nhau.
        </p>
      </div>
    </div>
  </section>
  
  <!-- similac-1-end-->
  <!-- ======================================== similac two start ======================================-->
  <div class="sml-container" *ngIf="subCatCode == '2019'">
    <div class="d-flex justify-content-center">
      <img
        class="fluid-img mw-100"
        src="../../../assets/images/similac-virgo/sml2-hero-banner.png"
      />
    </div>
  
    <div class="product-container mh-780">
      <div class="product-details-override">
        <div class="d-flex flex-column">
          <img
            class="fluid-img mw-100 pt-5"
            src="../../../assets/images/similac-virgo/sml2-hop-sua.png"
          />
          <p class="px-4 text-dark sml-fs-small text-center">
            Sản phẩm dinh dưỡng công thức cho trẻ 6-12 tháng tuổi <br />
            Similac Total Protection 2
          </p>
        </div>
  
        <div class="sml-br-radius sml-pad-10 p-3 sml-br-2 mx-4 mb-2">
          <p class="sml-mb-0 mb-3 sml-fs-16">
            <strong>CHÚ Ý:</strong> Sữa mẹ là thức ăn tốt nhất cho sức khỏe và sự
            phát triển toàn diện của trẻ nhỏ. Các yếu tố chống nhiễm khuẩn, đặc
            biệt là kháng thể chỉ có trong sữa mẹ có tác dụng giúp trẻ phòng,
            chống bệnh tiêu chảy, nhiễm khuẩn đường hô hấp và một số bệnh nhiễm
            khuẩn khác.
          </p>
          <p class="mb-0 sml-fs-16">
            Chỉ sử dụng sản phẩm này theo chỉ dẫn của bác sĩ. Pha chế theo đúng
            hướng dẫn. Cho trẻ ăn bằng cốc, thìa hợp vệ sinh.
          </p>
        </div>
        <p class="sml-m-30">
          *Thông tin dưới đây là thông tin trên nhãn đã được các cơ quan thẩm
          quyền phê duyệt
        </p>
        <br />
        <p class="sml-m-30 sml-fs-16">
          <strong>CÔNG DỤNG: Similac<sup>®</sup> Total Protection 2</strong> dùng bổ sung
          dinh dưỡng cho chế độ ăn hằng ngày của trẻ 6 - 12 tháng tuổi, cung cấp
          các dưỡng chất giúp phát triển tốt não bộ, tăng cường sức đề kháng và hệ
          tiêu hóa khỏe mạnh.
        </p>
      </div>
    </div>
  
    <div class="sml-total-pr-logo text-center">
      <h2>
        <img
          class="fluid-img mw-100"
          src="../../../assets/images/similac-virgo/sml2-logo.png"
        />
      </h2>
    </div>
  
    <div class="sml-5hmo-section">
      <img
        class="fluid-img mw-100"
        src="../../../assets/images/similac-virgo/sml2-loi-ich.png"
      />
    </div>
  
    <p class="sml-m-30 sml-txt-color">
      <strong>THÀNH PHẦN:</strong> Sữa không béo, lactose,
      <strong>DẦU THỰC VẬT</strong> (^chứa: dầu hướng dương giàu oleic, dầu đậu
      nành, dầu dừa), phức hợp oligosaccharid (<strong>5 loại HMO:</strong>
      2'-fucosyllactose, lacto-N-tetraose, 3-fucosyllactose, 6'-sialyllactose,
      3’-sialyllactose), <strong>KHOÁNG CHẤT</strong> (kali citrat, canxi
      carbonat, natri clorid, magiê clorid, sắt sulfat, kẽm sulfat, kali hydroxid,
      mangan sulfat, canxi clorid, tricanxi phosphat, đồng sulfat, natri selenat,
      kali iodid), acid arachidonic (AA), <strong>VITAMIN</strong> (acid ascorbic,
      ascorbyl palmitat, RRR- -tocopheryl acetat , niacinamid, canxi
      d-pantothenat, retinyl palmitat, thiamin hydroclorid, pyridoxin hydroclorid,
      riboflavin, acid folic, phylloquinon, d-biotin, cholecalciferol,
      cyanocobalamin), chất nhũ hóa lecithin đậu nành,
      <strong>acid docosahexaenoic (DHA),</strong> myo-Inositol, cholin bitartrat,
      NUCLEOTID (cytidin 5'-monophosphat, dinatri guanosin 5'-monophosphat,
      dinatri uridin 5'-monophosphat, adenosin 5’-monophosphat), cholin clorid,
      taurin, <strong>Bifidobacterium lactis</strong> (BB-12<sup>®</sup>) , hỗn hợp
      tocopherol, L-carnitin,
      <strong>CAROTENOID</strong> (<strong>lutein</strong>, ß-caroten).
      <br /><br />
      <strong>Vitamin E tự nhiên.</strong><br />
      BB-12: thương hiệu của Chr. Hansen
    </p><br />
  
    <p class="sml-m-30 sml-txt-color">
      <strong>BẢO QUẢN:</strong> Bảo quản hộp chưa mở nắp ở nhiệt độ phòng. Hộp đã
      mở nắp nên sử dụng trong vòng 3 tuần. Đậy nắp kín và bảo quản nơi khô mát
      (không để trong tủ lạnh).<br />
      <strong>CẨN TRỌNG:</strong> Không được dùng lò vi sóng để pha hay hâm nóng
      vì có thể gây bỏng.<br />
      <strong>LƯU Ý: Similac<sup>®</sup> Total Protection 2</strong> đã pha có thể uống bằng
      ly, hoặc trộn với ngũ cốc hay các thức ăn khác.<br />
      <strong>CÁCH PHA CHUẨN:</strong> Mỗi muỗng gạt ngang bột
      <strong>Similac<sup>®</sup> Total Protection 2</strong> pha với 60 ml (2 fl oz) nước
      hoặc 152,0 g bột pha với nước thành 1 lít. Đóng gói trong môi trường khí
      trơ.
    </p>
    <div class="d-flex justify-content-center sml-m-30 my-3">
      <img
        class="fluid-img mw-100 pe-2"
        src="../../../assets/images/similac-virgo/sml2-thanh-phan.png"
      />
    </div>
  
    <p class="sml-m-30 sml-txt-color">
      <strong>Sản xuất tại:</strong> Abbot Ireland, Cootehill, Co. Cavan, Ireland
      A subsidiary of Abbott Laboratories, North Chicago, IL 60064, USA<br />
      <strong> Xuất xứ:</strong> Ai-len<br />
      <strong> Đăng ký bởi:</strong> VPĐD Abbott Laboratories GmbH, Tầng 7-8, tháp
      A, tòa nhà Handi Resco, 521 Kim Mã, Ba Đình, Hà Nội.
    </p>
  
    <div class="d-flex justify-content-between sml-m-30 my-3 sml-txt-color">
      <img
        class="fluid-3a-img"
        src="../../../assets/images/similac-virgo/sml2-vector-smart-object.png"
      />
      <p>
        <strong>Nhập khẩu và phân phối bởi:</strong> Công ty TNHH Dinh Dưỡng 3A
        (Việt Nam), Centec Tower, 72-74 Nguyễn Thị Minh Khai, phường Võ Thị Sáu,
        quận 3, thành phố Hồ Chí Minh.
      </p>
    </div>
  
    <h2 class="sml-m-30 sml-txt-color sml-title-huong text-justify">
      HƯỚNG DẪN PHA CHẾ VÀ SỬ DỤNG:
    </h2>
    <p class="sml-m-30 sml-txt-color mb-4">
      Sử dụng theo hướng dẫn của nhân viên y tế. Khi pha, cần vệ sinh sạch sẽ, pha
      và bảo quản đúng cách. Không tuân thủ các hướng dẫn này có thể sẽ gây ảnh
      hưởng không tốt cho sức khoẻ của bé. Các loại sản phẩm dinh dưỡng công thức
      đều không tuyệt đối vô trùng, do đó khi dùng cho trẻ sinh non hoặc trẻ có
      vấn đề về miễn dịch cần phải theo sự hướng dẫn và theo dõi của bác sĩ. Nước
      dùng để pha phải được đun sôi 5 phút, để nguội và phạ cẩn thận theo bảng
      hướng dẫn kèm theo. Chỉ dùng muỗng có sẵn trong hộp để lường <strong>Similac<sup>®</sup> Total
      Protection 2.</strong> Nếu pha hơn một lần dùng thì lượng pha dư phải giữ lạnh ở
      nhiệt độ 2-4°C và dùng trong vòng 24 giờ. Chỉ cho bé dùng tối đa trong vòng
      1 giờ, sau đó phải đổ bỏ phần còn thừa. <br />Không dùng cho trẻ bị bệnh
      galactosemia.
    </p>
    <div class="sml-m-30 text-center">
      <img
        class="mw-100 fluid-img"
        src="../../../assets/images/similac-virgo/sml2-cac-buoc.png"
      />
      <img
        class="mw-100 fluid-img mt-4"
        src="../../../assets/images/similac-virgo/sml2-so-lan-de-nghi.png"
      />
    </div>
    <p class="sml-m-30 sml-txt-color my-4 sml-txt-seconday">
      Nếu bác sĩ có hướng dẫn về lượng dùng phù hợp cho trẻ, cần theo đúng hướng
      dẫn.<br /> + Bé ở độ tuổi này nên dùng bổ sung các loại thức ăn đặc khác.
    </p>
  </div>
  <!-- ======================================== similac two end ======================================-->
  
  <!-- ======================================== similac three start ======================================-->
  <div class="sml-container" *ngIf="subCatCode == '2020'">
    <div class="d-flex justify-content-center">
      <img
        class="fluid-img mw-100"
        src="../../../assets/images/similac-virgo/sml3-hero-banner.png"
      />
    </div>
  
    <div class="product-container">
      <div class="product-details">
        <div class="d-flex flex-column">
          <img
            class="fluid-img mw-100 pt-5"
            src="../../../assets/images/similac-virgo/sml3-similac-total-pr.png"
          />
        </div>
  
        <div class="sml-br-radius sml-pad-10 sml-br-2 mx-4 mb-2">
          <p class="mb-0 fw-500 mb-3 sml-fs-16">
            <strong>CHÚ Ý:</strong> Sữa mẹ là thức ăn tốt nhất cho sức khỏe và sự
            phát triển toàn diện của trẻ nhỏ. Các yếu tố chống nhiễm khuẩn, đặc
            biệt là kháng thể chỉ có trong sữa mẹ có tác dụng giúp trẻ phòng,
            chống bệnh tiêu chảy, nhiễm khuẩn đường hô hấp và một số bệnh nhiễm
            khuẩn khác.
          </p>
          <p class="sml-fs-16">
            Chỉ sử dụng sản phẩm này theo chỉ dẫn của bác sĩ. Pha chế theo đúng
            hướng dẫn. Cho trẻ ăn bằng cốc, thìa hợp vệ sinh.
          </p>
        </div>
        <p class="sml-m-30">
          *Thông tin dưới đây là thông tin trên nhãn đã được các cơ quan thẩm
          quyền phê duyệt
        </p>
        <br />
        <p class="sml-m-30 sml-fs-16">
          <strong>CÔNG DỤNG: Similac<sup>®</sup> Total Protection 3</strong> dùng bổ sung
          dinh dưỡng cho chế độ ăn hàng ngày của trẻ 1-2 tuổi, cung cấp các dưỡng
          chất giúp phát triển tốt não bộ, tăng cường sức đề kháng và hệ tiêu hóa
          khỏe mạnh.
        </p>
      </div>
    </div>
  
    <div class="sml-total-pr-logo text-center">
      <h2>
        <img
          class="fluid-img mw-100"
          src="../../../assets/images/similac-virgo/sml2-logo.png"
        />
      </h2>
    </div>
  
    <div class="sml-5hmo-section">
      <img
        class="fluid-img mw-100"
        src="../../../assets/images/similac-virgo/sml3-loi-ich.png"
      />
    </div>
  
    <p class="sml-m-30 sml-txt-color">
      <strong>THÀNH PHẦN:</strong> Sữa không béo, lactose,
      <strong>DẦU THỰC VẬT</strong> (^chứa: dầu hướng dương giàu oleic, dầu đậu
      nành, dầu dừa), phức hợp oligosaccharid (<strong>5 loại HMO:</strong>
      2'-fucosyllactose, lacto-N-tetraose, 3-fucosyllactose, 6'-sialyllactose,
      3'-sialyllactose), <strong>KHOÁNG CHẤT</strong> (tricanxi phosphat, canxi
      carbonat, kali citrat, natri clorid, kali hydroxid, sắt sulfat, kẽm sulfat,
      canxi clorid, magiê clorid, đồng sulfat, mangan sulfat, kali iodid, natri
      selenat), <strong>VITAMIN</strong> (acid ascorbic, ascorbyl palmitat,
      niacinamid, RRR- -tocopheryl acetat , canxi d-pantothenat, retinyl palmitat,
      thiamin hydroclorid, pyridoxin hydroclorid, riboflavin, acid folic,
      phylloquinon, d-biotin, cholecalciferol, cyanocobalamin), chất nhũ hóa
      lecithin đậu nành, myo-lnositol,
      <strong>acid docosahexaenoic (DHA),</strong> hương vani tổng hợp, cholin
      clorid, cholin bitartrat, taurin, <strong>NUCLEOTID</strong> (cytidin
      5'-monophosphat, dinatri uridin 5'-monophosphat, adenosin 5'-monophosphat,
      dinatri guanosin 5'-monophosphat), acid arachidonic (AA),
      <strong>Bifidobacterium lactis</strong> (BB-12<sup>®</sup>) , hỗn hợp tocopherol,
      L-carnitin, <strong>CAROTENOID</strong> (<strong>lutein</strong>,
      β-caroten).<br />
      <br />
      <strong>Vitamin E tự nhiên.</strong><br />
      BB-12: thương hiệu của Chr. Hansen
    </p><br />
  
    <p class="sml-m-30 sml-txt-color">
      <strong>BẢO QUẢN:</strong> Bảo quản hộp chưa mở nắp ở nhiệt độ phòng. Hộp đã
      mở nắp nên sử dụng trong vòng 3 tuần. Đậy nắp kín và bảo quản nơi khô mát
      (không để trong tủ lạnh).<br />
      <strong>CẨN TRỌNG:</strong> Không được dùng lò vi sóng để pha hay hâm nóng
      vì có thể gây bỏng.<br />
      <strong>LƯU Ý:</strong> Similac<sup>®</sup> Total Protection 3 đã pha có thể uống bằng
      ly, hoặc trộn với ngũ cốc hay các thức ăn khác.<br />
      <strong>CÁCH PHA CHUẨN:</strong> Mỗi muỗng gạt ngang bột Similac<sup>®</sup> Total
      Protection 3 pha với 60 ml (2 fl oz) nước hoặc 152,0 g bột pha với nước
      thành 1 lít. Đóng gói trong môi trường khí trơ.
    </p>
  
    <div class="d-flex justify-content-center sml-m-30 my-3">
      <img
        class="fluid-img mw-100 pe-2"
        src="../../../assets/images/similac-virgo/sml3-group-30.png"
      />
    </div>
  
    <p class="sml-m-30 sml-txt-color">
      <strong>Sản xuất tại:</strong> Abbot Ireland, Cootehill, Co. Cavan, Ireland
      A subsidiary of Abbott Laboratories, North Chicago, IL 60064, USA<br />
      <strong>Xuất xứ:</strong> Ai-len<br />
      <strong>Đăng ký bởi:</strong> VPĐD Abbott Laboratories GmbH, Tầng 7-8, tháp
      A, tòa nhà Handi Resco, 521 Kim Mã, Ba Đình, Hà Nội.
    </p>
  
    <div class="d-flex justify-content-between sml-m-30 my-3 sml-txt-color">
      <img
        class="fluid-3a-img"
        src="../../../assets/images/similac-virgo/sml2-vector-smart-object.png"
      />
      <p>
        <strong>Nhập khẩu và phân phối bởi:</strong> Công ty TNHH Dinh Dưỡng 3A
        (Việt Nam), Centec Tower, 72-74 Nguyễn Thị Minh Khai, phường Võ Thị Sáu,
        quận 3, thành phố Hồ Chí Minh.
      </p>
    </div>
  
    <h2 class="sml-m-30 sml-txt-color sml-title-huong text-justify">
      HƯỚNG DẪN PHA CHẾ VÀ SỬ DỤNG:
    </h2>
    <p class="sml-m-30 sml-txt-color mb-4">
      Sử dụng theo hướng dẫn của nhân viên y tế. Khi pha, cần vệ sinh sạch sẽ, pha
      và bảo quản đúng cách. Không tuân thủ các hướng dẫn này có thể sẽ gây ảnh
      hưởng không tốt cho sức khoẻ của bé. Các loại sản phẩm dinh dưỡng công thức
      đều không tuyệt đối vô trùng, do đó khi dùng cho trẻ sinh non hoặc trẻ có
      vấn đề về miễn dịch cần phải theo sự hướng dẫn và theo dõi của bác sĩ. Nước
      dùng để pha phải được đun sôi 5 phút, để nguội và phạ cẩn thận theo bảng
      hướng dẫn kèm theo. Chỉ dùng muỗng có sẵn trong hộp để lường <strong>Similac<sup>®</sup> Total
      Protection 3.</strong> Nếu pha hơn một lần dùng thì lượng pha dư phải giữ lạnh ở
      nhiệt độ 2-4°C và dùng trong vòng 24 giờ. Chỉ cho bé dùng tối đa trong vòng
      1 giờ, sau đó phải đổ bỏ phần còn thừa. <br />Không dùng cho trẻ bị bệnh
      galactosemia.
    </p>
    <div class="sml-m-30 text-center">
      <img
        class="mw-100 fluid-img"
        src="../../../assets/images/similac-virgo/sml3-cac-buoc.png"
      />
    </div>
    <p class="sml-m-30 sml-txt-color my-4 sml-txt-seconday">
      Nếu bác sĩ có hướng dẫn về lượng dùng phù hợp cho trẻ, cần theo đúng hướng
      dẫn.
    </p>
  </div>
  <!-- ======================================== similac three end ======================================-->
  
  <!--  similac-4-start-->
  <section class="similac-vigor similac-2" *ngIf="subCatCode == '2021'">
    <img
      class="img-fluid hero-banner w-100"
      src="../../../assets/images/similac-vigor/similac-4/hero-banner-similac-4.png"
    />
    <div class="similac-product">
      <div class="similac-inner-div pt20">
        <img
          class="img-fluid product-img w-100"
          src="../../../assets/images/similac-vigor/similac-4/product-img-similac-4.png"
        />
      </div>
    </div>
    <div class="similac-total-protection mt20">
      <img
        class="img-fluid tt-pro"
        src="../../../assets/images/similac-vigor/similac-4/similac-total-protection-4.png"
      />
  
      <img
        class="img-fluid w-100 mt20"
        src="../../../assets/images/similac-vigor/similac-4/Chng-ti-hiu-rng.png"
      />
  
      <div class="total-protection-text">
        <p class="mt20 pb20 text-center fn-20">
          Bởi giai đoạn ấy, con trẻ sẽ có những tiếp xúc, trải nghiệm đầu tiên với
          môi trường xung quanh.<br />
          Và những trải nghiệm ấy tiềm ẩn nhiều rủi ro khi tiếp xúc với các loại
          vi khuẩn, vi rút...<br />
          Mặc dù điều này có thể giúp hệ miễn dịch non nớt của con được củng cố vì
          đã làm quen và thích ứng với mầm bệnh, nhưng với trẻ có hệ miễn dịch
          kém, con sẽ bệnh thường xuyên hơn hoặc lâu hơn.
        </p>
        <h5>
          Vậy, nhóm trẻ nào cần một nguồn dinh dưỡng tăng cường để củng cố hệ miễn
          dịch?
        </h5>
        <img
          class="img-fluid w-100 mt10"
          src="../../../assets/images/similac-vigor/similac-4/layer-691.png"
        />
        <img
          class="img-fluid w-100 mt10"
          src="../../../assets/images/similac-vigor/similac-4/group-32.png"
        />
        <img
          class="img-fluid w-100 mt10"
          src="../../../assets/images/similac-vigor/similac-4/TNG-CNG-KHNG-VT.png"
        />
        <img
          class="img-fluid w-100 mt10"
          src="../../../assets/images/similac-vigor/similac-4/box-1.png"
        />
        <img
          class="img-fluid w-100 mt10"
          src="../../../assets/images/similac-vigor/similac-4/box-2.png"
        />
        <p class="sm-text mt20">
          1. Yu et al. (2013); 2. Thomson et al. (2018); 3. Mohan et al. (2006);
          4. Merolla et al. (2000); 5. Yau et at. (2003); 6. Pickering et al.
          (1998)<br />
          ^ Nucleotides nồng độ TPAN 72mg/L (công thức Similac Total Protection1 &
          2).<br />
          TPAN = total potentially available nucleotides<br />
          * Nghiên cứu ngẫu nhiên có đối chứng trên 69 trẻ sinh non sử dụng BB-12
          hoặc giả dược trong 21 ngày. 3,04 x 107 probiotics trên 200ml/ khẩu
          phần<br />
          # Báo cáo trong hội nghị N&G 2022
        </p>
        <h6 class="mt20 text-center text-uppercase">
          công thức có hàm lượng 5hmos cao nhất trên thế giới*<br />
          đồng hành cùng mẹ xây dựng hệ miễn dịch khỏe cho bé ngay từ bước khởi
          đầu.<br />
          <span>Phù hợp cho trẻ có miễn dịch kém</span>
        </h6>
        <img
          class="img-fluid w-100 mt10"
          src="../../../assets/images/similac-vigor/similac-4/group-29.png"
        />
  
        <p class="sm-text mt20">
          *So với các sản phẩm cho trẻ cùng độ tuổi, dựa trên khảo sát của Mintel
          từ 30/03/2022 đến 12/04/2022 trên cơ sở dữ liệu sản phẩm mới toàn cầu
          GPND, trong ngành hàng sữa công thức cho trẻ em và sữa cho trẻ đang tăng
          trưởng<br />
          ** Đề cập đến phản ứng của kháng thể huyết thanh đối với Haemophilus
          influenzae b. (Hib)<br />
          # So với các công thức Similac khác dành cho trẻ 2 tuổi trở lên^ Hơn 10
          dưỡng chất cho miễn dịch: vitamins A, B6, B12, C, D, E, đồng, acid
          folic, sắt, selen và kẽm.<br />
          1. Hill et al. (2021); 2. Male, Immunology. (2013); 3. Mohan et al.
          (2006) 4. Chouraqui et al. (2004); 5. Pickering et al. (1998); ++ 1.
          Probiotic BB-12<sup>®</sup> cung cấp lợi khuẩn (Chouraqui et al. 2004); HMO giúp 2.
          Tăng cường lợi khuẩn. 3. Giảm hại khuẩn. 4. Tăng cường hàng rào bảo vệ.
          5. Điều hòa miễn dịch. 6. Trung hòa mầm bệnh (Triantis et al. 2018, Yu
          et al. 2013, Thomson et al. 2018, Kong et al. 2019 and Cheng et al.
          2020, Goehring et al. 2016, Hill et al 2021); Nucleotides giúp 7. Hỗ trợ
          tế bào T trưởng thành. 8. Tăng sản xuất 1 số loại kháng thể (Buck et al.
          2004, Pickering et al. 1998); 9. Vitamin A, C, E giúp giảm ô xy hóa tế
          bào. 10. Vitamin D giúp tăng sản xuất tế bào miễn dịch (Maggini et al.
          2018).
        </p>
        <h6 class="mt20 text-justify">
          Tùy thể trạng của mỗi bé, mẹ có thể lựa chọn các sản phẩm dinh dưỡng bổ
          sung thích hợp cho con. Cùng tìm hiểu các dòng sản phẩm của Similac để
          đảm bảo cung cấp nguồn dinh dưỡng thích hợp nhất cho con mẹ nhé.
        </h6>
  
        <img
          class="img-fluid w-100 mt20"
          src="../../../assets/images/similac-vigor/similac-4/three-products-img.png"
        />
        <!-- <img
          class="img-fluid w-100 mt20"
          src="../../../assets/images/similac-vigor/similac-4/layer-696.png"
        /> -->

        <div class="embed-responsive embed-responsive-16by9 mt-4">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/CAquxXKCNs8" title="Similac Total Protection 4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
  
        <p class="mt20 sm-text-blue">
          *Các thông tin dưới dây là thông tin trên nhãn trong hồ sơ tự công bố
          sản phẩm
        </p>
        <p>
          <span>CÔNG DỤNG: Similac<sup>®</sup> Total Protection 4</span> dùng bổ sung dinh
          dưỡng cho chế độ ăn hàng ngày của trẻ 2–6 tuổi, cung cấp các dưỡng chất
          giúp phát triển tốt não bộ, tăng cường sức đề kháng và hệ tiêu hoá khoẻ
          mạnh.
        </p>
  
        <div class="total-protection-details mt10">
          <img
            class="img-fluid w-100"
            src="../../../assets/images/similac-vigor/similac-4/similac-total-protection-details-similac-4.png"
          />
        </div>
  
        <p class="mt20">
          <span>THÀNH PHẦN:</span> Sữa không béo, lactose,
          <span>DẦU THỰC VẬT</span> (^chứa: dầu hướng dương giàu oleic, dầu đậu
          nành, dầu dừa), phức hợp oligosaccharid (<span>5 loại HMO: 2</span
          >’-fucosyllactose, lacto-N-tetraose, 3-fucosyllactose, 6’-sialyllactose,
          3’-sialyllactose), <span>KHOÁNG CHẤT</span> (tricanxi phosphat, canxi
          carbonat, kali citrat, kali hydroxit, natri clorid, sắt sulfat, kẽm
          sulfat, mangan sulfat, canxi clorid, magiê clorid, đồng sulfat, natri
          selenat, kali iodid), <span>VITAMIN</span> (acid ascorbic, niacinamid,
          RRR- -tocopheryl acetat , canxi d-pantothenat, retinyl palmitat, thiamin
          hydroclorid, pyridoxin hydroclorid, riboflavin, acid folic,
          phylloquinon, d-biotin, cholecalciferol, cyanocobalamin), chất nhũ hóa
          lecithin đậu nành, <span>acid docosahexaenoic (DHA)</span>, hương vani
          tổng hợp, cholin clorid, cholin bitartrat, taurin,
          <span>NUCLEOTID</span> (cytidin, 5’-monophosphat, dinatri uridin
          5’-monophosphat, adenosin 5’-monophosphat, dinatri guanosin
          5’-monophosphat), acid arachidonic (AA),
          <span>Bifidobacterium lactis BB-12<sup>®</sup></span> , ascorbyl palmitat, hỗn hợp
          tocopherol, <span>CAROTENOID</span> (lutein, β-caroten).<br />
          <br />
          <strong>Vitamin E tự nhiên.</strong><br />
          BB-12: thương hiệu của Chr. Hansen
        </p><br />
        <img
          class="img-fluid w-100"
          src="../../../assets/images/similac-vigor/similac-4/similac-4-contents-chart.png"
        />
  
        <p class="mt20">
          <span>CÁCH PHA CHUẨN:</span> Mỗi muỗng gạt ngang bột Similac<sup>®</sup> Total
          Protection 4 pha với 60 ml (2 fl oz) nước hoặc 152,0 g bột pha với nước
          thành 1 lít.<br />
          <span>BẢO QUẢN:</span> Bảo quản hộp chưa mở nắp ở nhiệt độ phòng. Hộp đã
          mở nắp nên sử dụng trong vòng 3 tuần. Đậy nắp kín và bảo quản nơi khô
          mát (không để trong tủ lạnh).<br />
          <span>CẨN TRỌNG:</span> Không được dùng lò vi sóng để pha hay hâm nóng
          vì có thể gây bỏng.<br />
          <span>LƯU Ý:</span> Similac<sup>®</sup> Total Protection 4 đã pha có thể uống bằng
          ly, hoặc trộn với ngũ cốc hay các thức ăn khác.
        </p>
  
        <h4 class="mt20 text-justify">HƯỚNG DẪN PHA CHẾ VÀ SỬ DỤNG:</h4>
        <p>
          <span>HƯỚNG DẪN PHA CHẾ VÀ SỬ DỤNG: Similac<sup>®</sup> Total Protection 4</span>
          khi pha với nước sẽ được một thức uống dinh dưỡng để hoàn thiện chế độ
          ăn hàng ngày của trẻ. Rửa tay và dụng cụ thật sạch trước khi pha. Vệ
          sinh tốt, sử dụng và bảo quản đúng cách rất quan trọng khi bạn chuẩn bị
          pha <span>Similac<sup>®</sup> Total Protection 4</span> cho trẻ. Đun kỹ nước cho
          sôi, sau đó để nguội. Lường bằng muỗng đính kèm trong hộp sau đó dùng
          dao sạch gạt ngang. 1 muỗng
          <span>Similac<sup>®</sup> Total Protection 4</span> được pha với 60 ml (2 fl oz)
          nước đun sôi để nguội. Khuấy đều cho hòa tan hoàn toàn. Nếu pha nhiều
          hơn một lần dùng thì lượng pha dư phải được bảo quản trong tủ lạnh ở
          2-4°C và chỉ dùng trong vòng 24 giờ. Khi cho trẻ uống, phải dùng trong
          vòng 1 giờ, đố bỏ phần còn thừa. <br />Không dùng cho trẻ bị bệnh galactosemia.
        </p>
        <img
          class="img-fluid w-100 mt20"
          src="../../../assets/images/similac-vigor/similac-4/similac4-so-lan-de-nghi.png"
        />
  
        <p class="mt20">
          <strong>Sản xuất tại:</strong> Abbott Ireland, Cootehill, Co, Cavan, Ireland A subsidiary
          of Abbott Laboratories, North Chicago, IL 60064, USA<br /> <strong>Xuất xứ:</strong> Ai-len<br />
          <strong>Công bố bởi:</strong> VPĐD Abbott Laboratories GrbH, Tầng 7-8, tháp A, tòa nhà
          Handi Resco, 521 Kim Mã, Ba Đình, Hà Nội.
        </p>
      </div>
    </div>
  </section>
  <!--  similac-4-end-->
  


    <div class="manufacturerShareAndCareContainer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="mb-0 careShareText text-uppercase">{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.CARE_AND_SHARE'
                        | translate }}</h3>
                    <h3 class="homeText text-uppercase">{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.HOME' | translate }}</h3>
                    <p>{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.MAIN_TITLE1' | translate }}
                        <br><strong>{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.MAIN_TITLE_STRONG' | translate }}</strong>
                    </p>
                    <button class="orangeBtn" (click)="gotoHome()" routerLink="/sign-up/0">{{
                        'MANUFACTURING_SHARE_AND_CARE_INFO.JOIN_BUTTON' | translate
                        }}</button>
                    <p>
                        {{ 'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE1' | translate }} <strong>{{
                            'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE_STRONG' | translate }} </strong><br>
                        {{ 'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE2' | translate }}

                        <small
                            *ngIf="(subCatCode == '2013' && milkCode == '1') || (subCatCode == '2006' && milkCode == '1') || (subCatCode == '2004')">STC-C-01-21</small>

                        <!-- non-mil product code -->
                        <small *ngIf="(subCatCode == '2002' && milkCode == '0')">SIM-C-419-20</small>

                        <small
                            *ngIf="(subCatCode == '2010' && milkCode == '0') || (subCatCode == '2011' && milkCode == '0')">MOM-C-02-21</small>


                        <small *ngIf="(subCatCode == '2012' && milkCode == '1')">NEO-C-01-21 </small>

                        <!-- similac newborn code -->
                        <small
                            *ngIf="(subCatCode == '2007' && milkCode == '1') || (subCatCode == '2005' && milkCode == '1') || (subCatCode == '2003' && milkCode == '1')">SIM-C-31-21</small>

                        <small *ngIf="subCatCode == '2014'">SIM-C-411-21 </small>
                        <small *ngIf="subCatCode == '2015'">SIM-C-412-21 </small>
                        <small *ngIf="subCatCode == '2016'">SIM-C-413-21 </small>
                        <small *ngIf="subCatCode == '2017'">SIM-C-414-21 </small>
                        <small *ngIf="subCatCode == '2018' || subCatCode == '2019' || subCatCode == '2020'">SIM-C-102-22 </small>
                        <small *ngIf="subCatCode == '2021'">SIM-C-131-22 </small>
                    </p>
                </div>
            </div>
        </div>
    </div>

</section>
<app-footer></app-footer>
<app-nav-menu></app-nav-menu>