import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import {environment as env} from '../../../environments/environment';
import {v4 as uuidv4} from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-salesforce-signup',
  templateUrl: './salesforce-signup.component.html',
  styleUrls: ['./salesforce-signup.component.scss']
})
export class SalesforceSignupComponent implements OnInit {
  salesForceSignUpForm: FormGroup;
  submitted = false;
  salesForceData: any;
  mobile_no: any;
  prodCategory: any;
  scancodeData: any;
  registerForm: any = {}
  milkCode:any;
  content:any;
  subCatCode:any;
  emailInValid:any;
  salesforceApiResponse: any;
  sessionIdValue: any;
  iSprintData: any;
  constructor(private formBuilder: FormBuilder,private apiService: ApiService,
    private toastr: ToastrService, private spinner: NgxSpinnerService, private router: Router, private bsService: BugsnagService) { }

  ngOnInit(): void {
    this.salesForceSignUpForm = this.formBuilder.group({
      phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+63-?)|0)?[0-9]{9}$")]],
      name: ['', Validators.required],
      email:['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*[0-9]).{6,}$')
      ]],
      confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*[0-9]).{6,}$')]],
      mandatoryCheck: [true, Validators.requiredTrue],
    },
    {
      validator: MustMatch('password', 'confirmPassword')
    });
    this.patchData();
    this.getProductDescCode();
  }

  patchData(){
    this.mobile_no = sessionStorage.getItem('mobileNo');
    this.salesforceApiResponse = JSON.parse(sessionStorage.getItem('salesforceApiResponse'));
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    this.prodCategory = this.iSprintData?.category_code;

    this.sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
    let mobWitZero = sessionStorage.getItem('addMobNum');
    this.salesForceSignUpForm.patchValue({
      phoneNumber: mobWitZero,
      name: this.salesforceApiResponse ? this.salesforceApiResponse?.Name : '',
      email: this.salesforceApiResponse ? this.salesforceApiResponse?.Email: ''
    })
    this.salesForceSignUpForm.controls["phoneNumber"].disable();
    if(this.salesforceApiResponse?.Email){
      this.salesForceSignUpForm.controls["email"].disable();
    }
  }

  get salesFormVal() {
    return this.salesForceSignUpForm.controls;
 }
  onSalesForceSignUpSubmit() {
    this.submitted = true;
    if (this.salesForceSignUpForm.invalid) {
      return;
    }
    else{
      let userData = this.salesForceSignUpForm.value;
      this.apiService.getPosition().then(pos=>{
      let lat = pos.lat
      let lang = pos.lng
      let latitude = lat.toString()
      let longitude = lang.toString()
      let authToken = sessionStorage.getItem('authToken');
      let authKey = sessionStorage.getItem('authKey');

      // Put the object into storage
      let filledFormValues = this.getuserForm(userData, latitude, longitude);

        this.apiService.checkExistingEmail(encodeURIComponent(userData.email)).pipe(first())
        .subscribe((res:any)=>{
          this.spinner.hide();
          if(res.result.statusCode == 200){
            this.spinner.show();

          let otpGenerateData = {
            "identifierType":"MOBILE",
              "identifierValue":this.mobile_no,
              "deviceId": env.DEVICE_ID,
              "brand" : env.BRAND,
              "sessionId": this.sessionIdValue
          }
          let myuuid = uuidv4();
            let loggerFormData = {
              "unique_id": myuuid,
              "message": JSON.stringify(otpGenerateData)
            }

          this.spinner.show();
          let otpgendataresp;
          this.apiService.otpGenerate(otpGenerateData).pipe(first())  // otp generate api
          .subscribe(res=>{
            otpgendataresp = res;
            let myuuid = uuidv4();
            let loggerFormData = {
              "unique_id": myuuid,
              "message": JSON.stringify(filledFormValues)
            }

            this.spinner.hide();
              if(res['status'].code == 200){

                sessionStorage.setItem('SalesForceFormValue', JSON.stringify(userData));
                sessionStorage.setItem('SalesForceData', 'true');
                this.router.navigateByUrl('/otp')


              }
              else{
                //this.toastr.error(res['status'].message)
                console.log(res['status'].message)
              }
            },err=> {
              this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
              this.bsService.notifyError(err, otpGenerateData);
              this.spinner.hide();
              console.log(err, '=== OTP gererate error list =======')
          });


          }
        },err=>{

            this.emailInValid = true;
            console.log(this.emailInValid, 'emailInValid')
            //this.toastr.info('Email is already exists. Please enter new email')
            this.spinner.hide();
        })


    })//poistion api ending

    }
  }


  getuserForm(userData, latitude, longitude){
    let Signupmode = sessionStorage.getItem('SignupMode')
    return this.registerForm = {
    "root": {
      "customer": [
        {
              "mobile":this.mobile_no,
              "email": userData.email,
              "firstname": this.salesforceApiResponse?.FirstName,
              "lastname": this.salesforceApiResponse?.LastName,
              "custom_fields":{
                "field":[
                    {
                      "name":"categorycode",
                      "value":this.prodCategory? this.prodCategory : null
                    },
                    {
                      "name": "reglat",
                      "value": latitude
                    },
                    {
                      "name": "reglong",
                      "value": longitude
                   },
                   {
                    "name": "milk_code",
                    "value": this.milkCode == undefined ? this.milkCode = '0' : this.milkCode
                 },
                 {
                  "name":"Signupmode",
                  "value":Signupmode
                },
              ]
            }
          }
        ]
      }
  }


  }



  getProductDescCode() {
    this.milkCode =this.iSprintData?.is_milk_code;
    this.subCatCode = this.iSprintData?.sub_category_code;
  //   this.content = this.scancodeData?.product?.txt;
  //   if(this.content){
  //   this.content.forEach((item: any) => {
  //     if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
  //       let parsedPrimaryValue = this.parseFunction(item.value);
  //       this.subCatCode = parsedPrimaryValue.value;
  //     }
  //     if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
  //       let parsedPrimaryValue = this.parseFunction(item.value);
  //       this.milkCode = parsedPrimaryValue.value;

  //     }
  //   });
  // }
  }

  parseFunction(value) {
    return JSON.parse(value);
  }











}
