import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { environment as env } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signUpFormOne: FormGroup;
  submitted = false;
  scanCode: any;
  sessionId: any;
  checkQRCode2: any;
  salesforceData: any;
  salesforceApiResponse: any;

  constructor(private formBuilder: FormBuilder, private apiService: ApiService,
    private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService, private route: ActivatedRoute, private bsService: BugsnagService) {

    sessionStorage.removeItem('grow_nonmilk_remaining_count');
    sessionStorage.removeItem('similac_nonmilk_remaining_count');

    sessionStorage.removeItem('similacMilCount');
    sessionStorage.removeItem('growMilkCount');

    sessionStorage.removeItem('non_redeemed_grow');
    sessionStorage.removeItem('non_redeemed_similac');

    sessionStorage.removeItem('activeSimilac');
    sessionStorage.removeItem('activeGrow');

    sessionStorage.removeItem('SalesForceData');
    sessionStorage.removeItem('SalesForceFormValue');
    sessionStorage.removeItem('RegisterData');
    sessionStorage.removeItem('salesforceApiResponse');

    let signUpMode = this.route.snapshot.params.id;
    if (signUpMode == '0') {
      signUpMode = '0';
      sessionStorage.setItem('SignupMode', signUpMode)
    } else if (signUpMode == '1') {
      signUpMode = '1';
      sessionStorage.setItem('SignupMode', signUpMode)
    } else {
      signUpMode = '2';
      sessionStorage.setItem('SignupMode', signUpMode)
    }
    this.scanCode = sessionStorage.getItem('scanCode');
    window.scrollTo({ top: 0 });
    if (this.scanCode != "undefined") {
      let qrCodeData = JSON.parse(sessionStorage.getItem('productdata'));
      this.checkQRCode2 = qrCodeData?.uaidInfo?.type;
    } else {
      console.log('cleared local storage---')
      // sessionStorage.clear();
    }
  }

  ngOnInit(): void {
    this.signUpFormOne = this.formBuilder.group({
      phoneNumber: ['', [Validators.required, Validators.pattern(`^((\\+?84-?)|0)?[0-9]{${env.MOBLIMIT}}$`)]]
    });
    // this.apiService.sttarterGetGameplayReward("P-005678").subscribe(res=>{
    //   console.log(res, 'Sttarter game list========');
    //   let sttrData = res['data'][0];
    //   console.log(sttrData);

    //   //this.openRewardPopup();
    // })
  }
  get SignUpFormVal() {
    return this.signUpFormOne.controls;
  }

  onlyNumbers(ev) {
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  onSignUpSubmit() {
    this.submitted = true;
    if (this.signUpFormOne.invalid) {
      return;
    }
    else {
      let mob = this.signUpFormOne.value ? this.signUpFormOne.value.phoneNumber.toString() : '';
      mob = mob.replace(/\b0+/g, '');
      sessionStorage.setItem('mobileNo', mob);
      let formData = {
        "identifierType": "MOBILE",
        "identifierValue": mob,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "password": 'test1234',
        "confirmPassword": 'test1234'
      }
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(formData)
      }
      this.spinner.show();
      let resp;
      this.apiService.tokenGenerate(formData).pipe(first())  //token generate api
        .subscribe(res => {
          resp = res;
          let myuuid = uuidv4();
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(res)
          }
          this.spinner.hide();
          if (res['status'].code == 200) {
            let sessionId = res['user'].sessionId;
            sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
            if (res['user'].userRegisteredForPassword == true) {  // if user is present in CRM DB
              this.router.navigate(['/signin']);
            }
            else {                               // user is not present in CRM DB
              // let otpGenerateData = {
              //   "identifierType":"MOBILE",
              //     "identifierValue":mob,
              //     "deviceId": env.DEVICE_ID,
              //     "brand" : env.BRAND,
              //     "sessionId": sessionId
              // }
              // this.spinner.show();
              // this.apiService.otpGenerate(otpGenerateData).pipe(first())  // otp generate api
              // .subscribe(res=>{
              //   this.spinner.hide();
              //     if(res['status'].code == 200){
              //       this.router.navigate(['/otp']);
              //     }
              //     else{
              //       //this.toastr.error(res['status'].message)
              //       console.log(res['status'].message)
              //     }
              //   },err=> {
              //     this.spinner.hide();
              //     console.log(err, '=== OTP gererate error list =======')
              // });

              let getMobile = sessionStorage.getItem('mobileNo');
              getMobile = '0' + getMobile;
              this.spinner.show();

              this.apiService.getSalesForceData(getMobile).pipe(first())
                .subscribe((res: any) => {
                  this.spinner.hide();
                  // this.salesforceData = res['result'];
                  //   // Put the object into storage
                  // sessionStorage.setItem('salesForceData', JSON.stringify(this.salesforceData));

                  this.salesforceApiResponse = res['result'];
                  // Put the object into storage
                  sessionStorage.setItem('salesforceApiResponse', JSON.stringify(this.salesforceApiResponse));
                  this.router.navigateByUrl('/salesforce-signup')
                  sessionStorage.removeItem('isGeneric');
                },
                  err => {
                    this.spinner.hide();
                    sessionStorage.removeItem('isGeneric');
                    let data = {
                      id : 'removeGeneric'
                    }
                    this.router.navigate(['/crm-signup',data])
                    console.log(err, 'err--')
                   // this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')

                  })


            }

          }
          else {
            //this.toastr.error(res['status'].message)
            console.log(res['status'].message)
          }
        }, err => {
          this.spinner.hide();
          this.bsService.notifyError(err, formData);
          console.log(err, 'login error')
          this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
        })

    }

  }
  getMobNum($event) {
    sessionStorage.setItem('addMobNum', $event.target.value);
  }


}




