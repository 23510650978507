import { Component, OnInit, TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import jsQR from 'jsqr';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import * as ScanditSDK from 'scandit-sdk';
import {environment as env} from '../../../environments/environment';
@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit, AfterViewInit {
  scanScode: any;
  scanCode: any;
  authToken:any;
  qrScanCode:any=null;
  seriesCode1: any;
  seriesCode2: any;
  showScanner: Boolean = false;
  showProfile: Boolean = true;
  activeTab: any;
  visibleVoucher = false;
  customerData: any;
  signupVoucher: any;
  marvalRewardList: any;
  myDate = new Date();
  categoryCode: any;
  checkQRCode2: any;
  scancodeData: any;
  prodCategory: any;
  termsContent:any;
  showActiveTabGrow = false;
  showActiveTabSimilac = false;
  latitude:any;
  langitude:any;
  myDateValue: Date;
  is_grow:boolean=false;
  custom_isGrow: boolean = false;
  eligible_nonmilk_grow: boolean = false;
  eligible_nonmilk_similac: boolean = false;
  nonmilk_similac_array = [];
  nonmilk_grow_array = [];
  all_nonmilk_array = [];
  isGrow = false;
  eligible = false;
  growRewardList:any;
  similacRewardList:any;
  FinalMilkcode:any;
  growtermsContent:any;
  similac_array_length: any;
  grow_array_length: any;
  prdName:any = "Abbott Grow Gold"

  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('invalidQrCodeModal', { static: true }) invalidQrCodeModal: ModalDirective;
  @ViewChild('qrCode1Modal', { static: false}) qrCode1Modal: ModalDirective;
  @ViewChild('successQrCodeModalOne', { static: true }) successQrCodeModalOne: ModalDirective;
  @ViewChild('successQrCodeModal', { static: true }) successQrCodeModal: ModalDirective;
  @ViewChild('milkCodeModal', { static: true }) milkCodeModal: ModalDirective;
  @ViewChild('termsSimilac', { static: true }) termsSimilac: ModalDirective;
  modalRef: BsModalRef;
  config = {
    ignoreBackdropClick: true
  };
  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false
  };
  is_eligilbe_grow: boolean;
  remaining_grow_chances: any;
  remaining_similac_chances: any;
  remaining_ensure_chances: any;
  remaining_glucerna_chances: any;
  milkCodeFinal:any;
  scannedDate: any;
  expiryDate: any;
  ensure_array_length:any;
  glucerna_array_length:any;
  iSprintData: any;


  constructor(private modalService: BsModalService, private route: ActivatedRoute,
     private apiService: ApiService, private router: Router, private spinner: NgxSpinnerService,) { 
      ScanditSDK.configure(env.LICENSEKEY, {
        engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",
      })
    window.scrollTo({top: 0});
    this.myDate.setDate(this.myDate.getDate() + 30);
    this.authToken = sessionStorage.getItem('authToken')
    this.scanScode = this.route.snapshot.params.data;
    if (this.scanScode){
      this.showScanner = false
    }
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    this.prodCategory = this.iSprintData?.category_code;

    this.activeTab = this.route.snapshot.paramMap.get('product');

    setTimeout(()=>{
      this.remaining_grow_chances = JSON.parse(sessionStorage.getItem('grow_nonmilk_remaining_count'));
      this.remaining_similac_chances = JSON.parse(sessionStorage.getItem('similac_nonmilk_remaining_count'));
      this.remaining_ensure_chances = JSON.parse(sessionStorage.getItem('ensure_nonmilk_remaining_count'));
      this.remaining_glucerna_chances = JSON.parse(sessionStorage.getItem('glucerna_nonmilk_remaining_count'));

      this.similac_array_length = JSON.parse(sessionStorage.getItem('similacMilCount'));
      this.grow_array_length = JSON.parse(sessionStorage.getItem('growMilkCount'));
      this.ensure_array_length = JSON.parse(sessionStorage.getItem('ensureMilCount'));
      this.glucerna_array_length = JSON.parse(sessionStorage.getItem('glucernaMilkCount'));

      if(this.similac_array_length == 0 || this.similac_array_length == null){
        this.remaining_similac_chances = 5;
      }
      if(this.grow_array_length == 0 || this.similac_array_length == null){
        this.remaining_grow_chances = 5;
      }
      if(this.ensure_array_length == 0 || this.glucerna_array_length == null){
        this.remaining_ensure_chances = 5;
      }
      if(this.glucerna_array_length == 0 || this.glucerna_array_length == null){
        this.remaining_glucerna_chances = 5;
      }
     
      this.remaining_grow_chances  = this.remaining_grow_chances < 0 ? this.remaining_grow_chances = 0 : 0 + this.remaining_grow_chances
      this.remaining_similac_chances  = this.remaining_similac_chances < 0 ? this.remaining_similac_chances = 0 : 0 + this.remaining_similac_chances

      this.remaining_ensure_chances  = this.remaining_ensure_chances < 0 ? this.remaining_ensure_chances = 0 : 0 + this.remaining_ensure_chances
      this.remaining_glucerna_chances  = this.remaining_glucerna_chances < 0 ? this.remaining_glucerna_chances = 0 : 0 + this.remaining_glucerna_chances
    
      // if(!this.authToken){
      //   this.remaining_grow_chances = 5;
      //   this.remaining_similac_chances = 5;
  
      // }
    }, 2000)
    
    window.scrollTo({top: 0});


    this.scanCode = sessionStorage.getItem('scanCode');
    if(this.scanCode != "undefined"){
      // let qrCodeData = JSON.parse(sessionStorage.getItem('productdata'));
      // this.categoryCode = qrCodeData?.product?.categoryCode;
      // this.checkQRCode2 = qrCodeData?.uaidInfo?.type;
      this.categoryCode = this.iSprintData?.category_code;
      this.checkQRCode2 = this.iSprintData?.type;
    }else{
      console.log('cleared local storage---')
      // sessionStorage.clear();
    }
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    this.categoryCode == "Similac" ? this.is_grow = false : this.is_grow = true;

    let today = new Date();
    this.scannedDate = moment(today).format('YYYY-MM-DD');

    let expiry_date = moment(today).add(30, 'days');
    this.expiryDate = moment(expiry_date).format('YYYY-MM-DD');
  }
  ngAfterViewInit(): void {
    
  }

  ngOnInit(): void {
    this.getCustomer();

    this.similacTerms();

    if (this.activeTab === 'similac') {
      this.showActiveTabSimilac = true;
    }
    else {
      this.showActiveTabGrow = true;
    }

    this.myDateValue = new Date();
    this.getRewardList();
    this.getProductDescCode();

    // this.getNonMilkCount();
  }
  openQrcodeOnePopup() {
    this.successQrCodeModalOne.show();
  }
  closeQrcodeOnePopup() {
    this.successQrCodeModalOne.hide();
  }
  showSignupVoucher() {
    this.visibleVoucher = true;
  }
  getNonMilkCount(){
    let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    let endOfMonth = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');
    let mobile_no = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken')

    this.apiService.getTransactionfull(mobile_no, getAuthKey).pipe(first())
    .subscribe((res:any)=>{
      let custom_fields : any;
      let result = res?.customer?.transactions;
      let transcation = result?.transaction;
      if(transcation){
        custom_fields = transcation.map(val =>{
          return val.custom_fields['field']
        });
      }
      if(custom_fields){
        this.getTransactionCount(custom_fields);
      }
    })
  }
  getTransactionCount(custom_fields){
    let a = custom_fields.findIndex(val=>val.name=='is_grow');
    let b = custom_fields.findIndex(val=>val.name=='is_milk');
    if(a > b){
      let temp = custom_fields[a];
      custom_fields[a] = custom_fields[b];
      custom_fields[b] = temp;
    }

    custom_fields.filter(val =>{

      if(val.name == 'is_grow'){
        val.value == "true" ? this.custom_isGrow = true : this.custom_isGrow = false;
      }
      if(val.name == 'is_milk' && val.value == "false"){
        this.all_nonmilk_array.push(val);
        if(this.custom_isGrow){
          this.nonmilk_grow_array.push(val);
        }
        else{
          this.nonmilk_similac_array.push(val);
        }
      }
    })

    let all_nonmilk_count = this.all_nonmilk_array.length; //given final count of non-milk(both grow and similac)
    let grow_nonmilk_count = this.nonmilk_grow_array.length; // gives final count non-milk grow
    let similac_nonmilk_count = this.nonmilk_similac_array.length // gives final count non-milk similac

    sessionStorage.setItem('similacMilCount', JSON.stringify(similac_nonmilk_count));
    sessionStorage.setItem('growMilkCount', JSON.stringify(grow_nonmilk_count));
 

    let remain_nonmilk_grow_count = grow_nonmilk_count;
    let remain_nonmilk_similac_count = similac_nonmilk_count;

    sessionStorage.setItem('grow_nonmilk_remaining_count', remain_nonmilk_grow_count.toString());
    sessionStorage.setItem('similac_nonmilk_remaining_count', remain_nonmilk_similac_count.toString());


    // all_nonmilk_count <= 5 ? this.eligible = true : this.eligible= false;
    // grow_nonmilk_count <= 5 ? this.eligible_nonmilk_grow = true : this.eligible_nonmilk_grow = false;
    // similac_nonmilk_count <= 5 ? this.eligible_nonmilk_similac = true : this.eligible_nonmilk_similac = false;

  }
  growOrSimilac(){
    if(this.is_grow && this.eligible_nonmilk_grow){
      this.modalService.hide();
      this.router.navigate(['/grow-spinwheel']);
    }
    if(this.is_grow && this.is_eligilbe_grow == false){
      this.router.navigate(['/lucky-draw-quota-exceed']);
    }
    if(this.is_grow == false && this.eligible_nonmilk_similac){
      this.modalService.hide();
      this.router.navigate(['/similac-spinwheel']);
    }
  }

  
  onClickScanner() {
    this.modalService.hide();
    this.showScanner = true;
    this.showProfile = false;
    this.successQrCodeModal.hide()

    if (this.showScanner) {
      ScanditSDK.BarcodePicker.create(document.getElementById("scandit-barcode-picker"), {
        scanSettings: new ScanditSDK.ScanSettings({ enabledSymbologies: [ScanditSDK.Barcode.Symbology.QR], codeDuplicateFilter:-1 }),
      })
    .then((barcodePicker) => {
      if(this.showScanner && this.qrScanCode==null)
      {
      barcodePicker.on("scan", (scanResult) => {
        window.location.href = scanResult.barcodes[0].data;
        this.qrScanCode=scanResult.barcodes[0].data;
        this.showScanner=false;
      });
    }
    });
    }
  }
  closeScanner(){
    location.reload();
    this.showScanner = false;
    this.showProfile = true;
  }
  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo()
    }
  }

  startVideo() {
    this.medias.video = { facingMode: 'environment', width: 100, height: 150 }
    navigator.mediaDevices.getUserMedia(this.medias).then(
      (localStream: MediaStream) => {
        this.videoElm.nativeElement.srcObject = localStream;
        this.videoStart = true;
        this.checkImage();
      }
    ).catch(
      error => {
        console.error(error);
        this.videoStart = false;
      }
    );
  }
  stopVideo() {
    this.medias.video = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    this.videoStart = false;
  }

  checkImage() {
    const WIDTH = this.videoElm.nativeElement.clientWidth;
    const HEIGHT = this.videoElm.nativeElement.clientHeight;
    this.canvasElm.nativeElement.width = WIDTH;
    this.canvasElm.nativeElement.height = HEIGHT;

    const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;

    ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT)
    const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT)
    const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })
    if (code) {
      let scannedCode = code.data
      let getAuthKey = sessionStorage.getItem('authToken');
      window.location.href = scannedCode;
    //   this.apiService.getTraceability(scannedCode).subscribe((res: any) => {
    //     sessionStorage.setItem('productdata', JSON.stringify(res.result.data));
    //     this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    //     console.log(this.scancodeData, 'productdata')
    //     if(res.result.code == '0'){
    //         if(res.result.data.uaidInfo.type == 1 || res.result.data.uaidInfo.type == 7){
    //         this.router.navigate(['/home']);
    //         }else if(res.result.data.uaidInfo.type == 2){
    //         if(getAuthKey){
    //             console.log('available Auth kery', getAuthKey)
    //       this.apiService.getPosition().then(pos => {
    //         let lat = pos.lat
    //         let lang = pos.lng
    //         let latitude = lat.toString()
    //         let langitude = lang.toString()
    //         let getMobile = sessionStorage.getItem('mobileNo')
    //         let getAuthKey = sessionStorage.getItem('authToken')
    //         let productName = sessionStorage.getItem('productName');
    //         let formData = {
    //             "scan_code": scannedCode,
    //             "mobile": getMobile,
    //             "cap_device_id": env.DEVICE_ID,
    //             "custom_fields": [
    //               {
    //                 "name": "TransLat",
    //                 "value": latitude
    //               },
    //               {
    //                 "name": "TransLong",
    //                 "value": langitude
    //              },
    //              {
    //                 "name": "productName",
    //                 "value": productName
    //              },
    //              { 
    //               "name":"categorycode",
    //               "value":this.categoryCode ? this.categoryCode : ''
    //             },
    //             {
    //               "name":"is_milk",
    //               "value": this.is_milk
                
    //             },
    //             {
    //               "name": "is_grow",
    //               "value": this.is_grow
                  
    //             },
    //             {
    //               "name": "itemcode",
    //               "value": this.subCatCode
    //             },
    //             {
    //               "name": "scanned_date",
    //               "value": this.scannedDate
    //             },
    //             {
    //               "name": "expiration_date",
    //               "value": this.expiryDate
    //             },
    //             {
    //               "name": "is_eligible_for_game",
    //               "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
    //             },
    //             {
    //               "name": "is_redeemed",
    //               "value": false
    //             },
    //             {
    //               "name": "gift_id",
    //               "value": 'null'
    //             },
    //             {
    //               "name": "gift_delivery_date",
    //               "value": 'null'
    //             }
    
    //           ]
    //         }
    //         this.spinner.show();
    //         this.apiService.addTransaction(formData, getAuthKey).pipe(first())
    //         .subscribe((res:any) => {
    //           console.log("res", res?.result?.data?.product?.txt);
    //           let milkcodeFind = res?.result?.data?.product?.txt;
    //           if(milkcodeFind){
    //             milkcodeFind.forEach((item: any) => {
    //               if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //                 let parsedPrimaryValue = this.parseFunction(item.value);
    //                 this.FinalMilkcode = parsedPrimaryValue.value;
    //                 console.log(this.FinalMilkcode, 'FinalMilkcode')
    //               }
    //             });
    //           }
    
    //           this.spinner.hide();
    //           this.getNonMilkCount();
    //           this.showScanner = false;
    //           this.toggleVideoMedia();
    
    //           if(this.FinalMilkcode == '1'){ 
    //             this.openMilkCodeModal();
    //           }
    
    //           if(this.FinalMilkcode == '0'){
    //           let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
    //           let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))
    //           console.log(SimilacCount, 'GrowCount')
    //           console.log(GrowCount, 'GrowCount')
             
              
    //           if(this.is_grow){
    //             if(this.remaining_grow_chances < 5){
    //               this.successQrCodeModal.show();
    //             }
    //             else{
    //               this.router.navigate(['/lucky-draw-quota-exceed']);
    //             }
    //           }
    //           else{
    //             if(this.remaining_similac_chances < 5){
    //               console.log(this.remaining_similac_chances, 'this.remaining_similac_chances')
    //               this.successQrCodeModal.show();
    //             }
    //             else{
    //               console.log(this.remaining_similac_chances, 'this.remaining_similac_chances2')
    //               this.router.navigate(['/lucky-draw-quota-exceed']);
    //             }
    //           }  
    //         } 
    //           // this.getCustomer();
    //         }, err => {
    //           this.showScanner = false;
    //           this.spinner.hide();
    //           // this.apiService.showToaster(err);
    //           if(err?.error?.message == "Given code already verified"){
    //             this.openinvalidQrCodeModal()
    //           }else if('Đã xảy ra lỗi. Vui lòng thử lại sau' == "Invalid scanned code" || err == "Wrong scanned code"){
    //             // this.qrCode1Modal.show();
    //           }
              
    //           this.toggleVideoMedia();
    //         })
    //       })
    //         }else{
    //             this.router.navigate(['/sign-up']);
    //         }
            
    //         }
    //     }
    // },err=>{
    //         console.log(err, 'err========')
    // })


    }
    else {
      setTimeout(() => { this.checkImage(); }, 100)
    }
  }
  ensureRewardList:any;
  glucernaRewardList:any;
  pediasureRewardList:any;
  getRewardList(){
    //this.spinner.show()
    this.apiService.voucherList().subscribe((res:any)=>{
     // this.spinner.hide()
      this.growRewardList = res?.result[0]?.Grow?.available_vouchers;
      this.similacRewardList = res?.result[0]?.Similac?.available_vouchers
      this.ensureRewardList = res?.result[0]?.Ensure?.available_vouchers;
      this.glucernaRewardList = res?.result[0]?.Glucerna?.available_vouchers
      this.pediasureRewardList = res?.result[0]?.Pediasure?.available_vouchers
    })
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }
  qrScan(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);

  }

  openinvalidQrCodeModal() {
    this.invalidQrCodeModal.show()
  }
  invalidQrCodeHide() {
    this.invalidQrCodeModal.hide()
  }
  
  onDateChange(newDate: Date) {
    console.log(newDate);
  }
  getCustomer() {
    this.spinner.show()
    let getMobile = sessionStorage.getItem('mobileNo')
    let getAuthKey = sessionStorage.getItem('authToken')
    this.apiService.getCapCustomer(getMobile, getAuthKey).pipe(first())
      .subscribe(res => {
        this.spinner.hide()
        if (res['status'].code == 200) {
          this.customerData = res['customers']['customer'][0];
        }

        else if (res['status'].code == 401) {
          // this.apiService.logout()
        } 
        else {
          //this.apiService.showToaster(res['status'].message)
          console.log(res['status'].message);
        }
      }, err => {
        this.spinner.hide()
        console.log(err, '=== get customer error =======')
      })
  }

  content: any;
  subCatCode: any;
  milkCode: any;
  is_milk: any;
  getProductDescCode() {
    this.milkCode =this.iSprintData?.is_milk_code;
    this.subCatCode = this.iSprintData?.sub_category_code;
  //   this.content = this.scancodeData?.product?.txt;
  // if(this.content){
  //   this.content.forEach((item: any) => {
    
  //     if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
  //       let parsedPrimaryValue = this.parseFunction(item.value);
  //       this.subCatCode = parsedPrimaryValue.value;
  //     }
     
  //     if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
  //       let parsedPrimaryValue = this.parseFunction(item.value);
  //       this.milkCode = parsedPrimaryValue.value;
  //       this.milkCodeFinal = parsedPrimaryValue.value;
  //       this.milkCode == 0 ? this.is_milk = false : this.is_milk = true;
  //     }
  //   });
  // }
  }

  parseFunction(value) {
    return JSON.parse(value);
  }


  closeSuccessModal(){
    this.successQrCodeModal.hide()
    this.router.navigate(['/user-profile']);
  }



  openMilkCodeModal() {
    this.milkCodeModal.show()
  }
  milkCodeHide() {
    this.milkCodeModal.hide();
  }
  opensimilacTerms(){
    this.termsSimilac.show()
  }
  termsSimilacHide(){
    this.termsSimilac.hide()
  }
  openSimilacTermsandConditions(){
    this.opensimilacTerms();
  }
  ensuretermsContent:any;
  glucernatermsContent:any;
  pediasureltermsContent:any;
  similacTerms() {
    this.apiService.similacTermsandConditions().subscribe((res: any) => {
      res.data.forEach(element => {
        if (element.contentkey == 'Terms_and_conditions_Similac') {
          this.termsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Grow') {
          this.growtermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Gold') {
          this.ensuretermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Glucerna') {
          this.glucernatermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_PediaSure') {
          this.pediasureltermsContent = element.content;
        }

      })
    })
  }


  growClicked(){
    this.prdName = "Abbott Grow Gold"
  }
  similacClicked(){
    this.prdName = "Similac"
  }
  pediasureClicked(){
    this.prdName = "Pediasure"
  }
  ensureClicked(){
    this.prdName = "Ensure"
  }
  glucernaClicked(){
    this.prdName = "Glucerna"
  }

  adultTab(){
    this.prdName = "Ensure"
  }
  childTab(){
    this.prdName = "Grow"
  }

}
