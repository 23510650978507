import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import jsQR from 'jsqr';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import * as ScanditSDK from 'scandit-sdk';
import {environment as env} from '../../../environments/environment';
@Component({
  selector: 'app-search-scanner',
  templateUrl: './search-scanner.component.html',
  styleUrls: ['./search-scanner.component.scss']
})
export class SearchScannerComponent implements OnInit {
  showScanner: Boolean = false;
  scancodeData: any;
  qrScanCode:any=null;
  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('successQrCodeModalOne', { static: true }) successQrCodeModalOne: ModalDirective;
  config = {
    backdrop: false,
    ignoreBackdropClick: true
  };
  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false
  };
 
  // config.backdrop = !config.backdrop
  constructor(private modalService: BsModalService, private router: Router, private location: Location, private spinner: NgxSpinnerService, private apiService: ApiService) { 

    ScanditSDK.configure(env.LICENSEKEY, {
      engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",
    })
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    // this.modalService.removeBackdrop();
    this.successQrCodeModalOne.show(); 
  }
  onClickScanner() {
    // console.log('', this.modalService.removeBackdrop());
    this.showScanner = true;
    // this.showProfile = false;

    if (this.showScanner) {
      ScanditSDK.BarcodePicker.create(document.getElementById("scandit-barcode-picker"), {
        scanSettings: new ScanditSDK.ScanSettings({ enabledSymbologies: [ScanditSDK.Barcode.Symbology.QR], codeDuplicateFilter:-1 }),
      })
    .then((barcodePicker) => {
      if(this.showScanner && this.qrScanCode==null)
      {
      barcodePicker.on("scan", (scanResult) => {
        window.location.href = scanResult.barcodes[0].data;
        this.qrScanCode=scanResult.barcodes[0].data;
        this.showScanner=false;
      });
    }
    });
    }

  }
  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo()
    }
  }

  startVideo() {
    this.medias.video = { facingMode: 'environment', width: 100, height: 150 }
    navigator.mediaDevices.getUserMedia(this.medias).then(
      (localStream: MediaStream) => {
        this.videoElm.nativeElement.srcObject = localStream;
        this.videoStart = true;
        this.checkImage();
      }
    ).catch(
      error => {
        console.error(error);
        this.videoStart = false;
      }
    );
  }

  stopVideo() {
    this.medias.video = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    this.videoStart = false;
  }

  checkImage() {
    const WIDTH = this.videoElm.nativeElement.clientWidth;
    const HEIGHT = this.videoElm.nativeElement.clientHeight;
    this.canvasElm.nativeElement.width = WIDTH;
    this.canvasElm.nativeElement.height = HEIGHT;

    const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;

    ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT)
    const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT)
    const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })
    if (code) {
      // this.router.navigate['/library'];
      let scannedCode = code.data
      let getAuthKey = sessionStorage.getItem('authToken');
      window.location.href = scannedCode;
      this.spinner.show();
      this.apiService.getTraceability(scannedCode).subscribe((res: any) => {
        this.spinner.hide();
        sessionStorage.setItem('productdata', JSON.stringify(res.result.data));
        this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
        console.log(this.scancodeData, 'productdata')
        if (res.result.code == '0') {
          if (res.result.data.uaidInfo.type == 1 || res.result.data.uaidInfo.type == 7) {
            this.router.navigate(['/home']);
          } else if (res.result.data.uaidInfo.type == 2) {
            this.router.navigate['/login'];
          }
        }
        // else {
        //   this.apiService.showErrorMsg('Please scan a valid QR code.')
        // }
      });

    }
    else {
      setTimeout(() => { this.checkImage(); }, 100)
    }
  }
  closeScanner() {
    location.reload();
    this.showScanner = false;
    // this.showProfile = true;
  }
  closeModalOne() {
    this.successQrCodeModalOne.hide();
    this.location.back();
  }
}
