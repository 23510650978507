export const environment = {
  production: true,
  //API_URl: "https://abbott-ph-api-dev.spurtreetech.com/api"
  API_URl: 'https://abbott-api-live.capillarytech.com/api',
  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL: ' https://abbott-api-live.capillarytech.com/api/v1',
  CMS_URL:
    'https://api.sttarter.com/contentsystem/d3eaf7a8067373ea08c47134748d7abf',
  API_URl_TRACEBILITY: 'https://abbott-api-live.capillarytech.com/api',
  IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
  EMAIL_CHECK_API: 'https://abbott-api-live.capillarytech.com/api/v1',
  BRAND: 'ABBOTTVN',
  DEVICE_ID: '1234551231',
  IDENTIFIER_TYPE: 'MOBILE',
  TILL_ID: 'abbottvnrewards',
  STTARTER_GAMEPLAY_REWARD:
    'https://api.sttarter.com/contentsystem/f148dba495e5391a7790087cbb5fc3e2',
  STTARTER_GAMEPLAY_FIRSTTIME_SCAN:
    'https://api.sttarter.com/contentsystem/f148dba495e5391a7790087cbb5fc3e2/firsttimescanlive',
  STTARTER_GAMEPLAY_LUCKY_DRAW:
    'https://api.sttarter.com/contentsystem/f148dba495e5391a7790087cbb5fc3e2/luckydrawlive',
  // GAME_MAP_API: "https://cap-intouch-in.sttarter.com",
  GAME_MAP_API: 'https://cap-apac-api-com.sttarter.com/v1.1',
  HOLDING_URL: 'https://cap-intouch-in.sttarter.com',
  WRAPPER_MIDDLEWARE_API: 'https://abbott-api-live.capillarytech.com/api/v1',
  LICENSEKEY: "Aflj89OBR0ToHBjdjBsIjLk82euRM2AgpTBizRpn7IKDYyTc63MHakxhmapZfWJBs0friwtOYvVyQZ75AUuIB0hsybd3bk3B6EZDJrFNcN11bdB84lO4NwwqE3DQSpP/zHTFtqN5WjhRVxyV5TXin5lYWmKDNj9bLmbQJmV/q193dFd9CQHZ6CEX8TD0P0dxcQ2xCgI74V7Tcgo4UO7AosUy1u1r3bbCEh3xY01hvvq6AM+pR3AGDRVHiaDqU42u9N0mKL6345hzyg4ZXhtsYGQdGcZEX4iH8eJeueqAxbYnoSX2ytjarBpbtb3e5oyayksr3miLVOEuoZocJ/w+G6eF6D46KWrIiNbQmPYE9hQ+xuf1W9Q01jJin9BO3m24wcmIjaAa/WnHkc9k1efj/lcKRQU7N7kQ39IAmQBWago+JnTX7CYbriIZ3BiY7cFy9FxwOwNXNYaTKV1NQWczm5LTZL9oXv3kCx8db7DAvp245QzwZo7dUqJu49NhiuTNSk5qEpAaPC+s0A0WdiihyRoYNUOdasvH/+VSzuY9aqxRgtuhpPwpMF2mKSYUDkN5OZH9ybRIGoOGGEaHG+DsuQpa4YlUphmi9+MX3l1SbMp/b2UK997SLF/hnSVSny4JRaSSE3OqU4tLuDA2kac1zQ1WPxB/BCc2H+I+HMF1znkUNc8t02uzfexqNuCjPxzxPPkPdy/wVpF22J6rImPWxnuBiKOHPZHll7Wh2M/6xlwwHwPAsWb/IhxA1bAD3zVH+osbtVHKqsx2OMgo98MwQJXL6UWd8fDyWqSKJ4Auv9X2aAkSkj4h5dGxnhfZT2eMkTh2msmdPISJzvMomXj+xwPoLxzSg0xj7cKNox6d1NKLscew",
  gameBrandId: 43,
  gameIdGrowNonMilk: 244,
  gameIdSimilacMom: 247,
  gameIdSimilacS4: 245,
  COUNTRY_CODE: '84',
  MOBLIMIT: 9,
  seriescode1: '8461',
  seriescode2: '8462',
  seriescode3: '8463',
  bugsnag_version: '23092022',
};
